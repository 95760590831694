import xmlbuilder from "xmlbuilder";
/**
 * @author __Shivshankar Mishra__
 * @param {JSON} jsonData
 * @param {Number} isReferenceNumber
 * @returns {XMLDocument}
 */
const generateOdooTreeViewXML = (jsonData, isReferenceNumber, odooVersion) => {
  if (!Array.isArray(jsonData)) {
    console.error("Invalid JSON data provided:", jsonData);
    return "";
  }
  // Root element: tree
  const listAttributes = {
    multi_edit: "1",
    delete: "false",
    // default_order: "name asc",
  };
  let xml;
  switch (odooVersion) {
    case "v18":
      xml = xmlbuilder.create("list", listAttributes);
      break;
    case "v16":
    case "v17":
    default:
      xml = xmlbuilder.create("tree", listAttributes);
  }
  // Add attributes to the tree element
  Object.entries(listAttributes).forEach(([key, value]) => {
    xml.att(key, value);
  });

  // if (isReferenceNumber) {
  xml.ele("field", {
    name: "x_reference_no",
    string: "Reference No",
    readonly: "1",
  });
  // }

  // Loop through the JSON data to add fields
  jsonData.forEach((field) => {
    const fieldAttrs = {
      // name: field.technicalName || "",
      string: field.title || "",
      // widget: field.widget || "",
      optional: field.optional || "",
    };
    if (field.widget === "password") fieldAttrs.password = "true";
    if (field.isRequired) fieldAttrs.required = "1";
    if (field.isReadOnly) fieldAttrs.readonly = "1";
    if (field.isInvisible) fieldAttrs.invisible = "1";
    if (["button", "object", "action"].includes(field.type))
      fieldAttrs.type = field.type || "button";

    switch (field.type) {
      case "create_uid":
        fieldAttrs.name = "create_uid";
        break;
      case "employee_id":
        fieldAttrs.name = "employee_id";
        break;
      case "employee_name":
        fieldAttrs.name = "employee_name";
        break;
      case "department":
        fieldAttrs.name = "department";
        break;
      case "create_date":
        fieldAttrs.name = "create_date";
        break;
      case "write_uid":
        fieldAttrs.name = "write_uid";
        break;
      case "write_date":
        fieldAttrs.name = "write_date";
        break;
      case "logged_in_user":
        fieldAttrs.name = "logged_in_user";
        break;

      default:
        fieldAttrs.name = field.technicalName;
        fieldAttrs.widget = field.widget;
        break;
    }

    // Remove undefined attributes
    Object.keys(fieldAttrs).forEach((key) => {
      if (fieldAttrs[key] === undefined) {
        delete fieldAttrs[key];
      }
    });

    // Add field to the tree element
    xml.ele("field", fieldAttrs);
  });

  // Convert the XML object to string
  return xml.end({ pretty: true });
};

export default generateOdooTreeViewXML;
