import React, { useEffect, useState } from "react";
import "./ProcessModelerPage.css";
import { CiMenuFries } from "react-icons/ci";
import { useNodeContext } from "../../context/NodeContext";
import ControlPanel from "./process_modeler_content/ControlPanel";
import Board from "./process_modeler_content/Board";
import ShapeUpdateForm from "./process_modeler_content/ShapeUpdateForm";
import ProcessModelerTopBar from "../../template/process_modeler_top/ProcessModelerTopBar";
import { useProcessContext } from "../../context/ProcessContext";
import Swal from "sweetalert2";
import BASE_URL, { API_KEY, PAYLOAD_SECRET, SECRE_KEY } from "../../../config";
import generateOdooFormViewXml from "../../utility_files/generateOdooFormViewXml";
import { useFormContext } from "../../context/FormContext";
import { IoRocketSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import generateOdooTreeViewXML from "../../utility_files/generateOdooListViewXml";

const ProcessModelerPage = () => {
  const {
    nodes,
    edges,
    showShapeUpdateForm,
    shapeId,
    isStartAndEndNode,
    handleArrowCheck,
    handlePrevNextCheck,
    handleAssignedFormCheck,
    handleEmailTemplateValidation,
    sequenceNodes,
    handleEmailVerifyValidation,
    handleReferenceNumberValidation,
  } = useNodeContext();
  const {
    newProcessId,
    processDetail,
    setProcessDetail,
    userData,
    centerAlert,
    centerAlertWithTitle,
    encryptData,
    fetchConfigurationValue,
    restartServer,
    restartServerStatusCheck,
    handleLoaderScreen,
    checkinLoading,
    setCheckInLoading,
    setCheckinStep,
    setCheckinLoadingTitle,
    handleProcessDeployLockRelease,
    checkLoginStatus,
    processDetailLoading,
  } = useProcessContext();
  const {
    forms,
    setForms,
    fetchForms,
    parseFormData,
    getMaxTitleLength,
    handleUpdateRelatedModelStatus,
  } = useFormContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isControlPanelOpen, setControlPanelOpen] = useState(true);
  const [restartTimeWait, setRestartTimeWait] = useState(5000);
  const [configurationDetail, setConfigurationDetail] = useState(null);

  if (newProcessId === "null") {
    navigate("/dashboard/processes");
  }
  // useEffect for token validation
  useEffect(() => {
    checkLoginStatus();
  }, [location.pathname, processDetail]);
  // state for process detail object
  const [processObj, setProcessObj] = useState(null);
  // const [processObj, setProcessObj] = useState({
  //   process_id: newProcessId,
  //   process_group: processDetail ? processDetail.process_group : null,
  //   process_name: processDetail ? processDetail.process_name : "no name",
  //   process_detail: processDetail ? processDetail.process_detail : "no details",
  //   user_id: processDetail ? processDetail.user_id : "123",
  //   secret_key: SECRE_KEY,
  // });

  useEffect(() => {
    if (processDetail) {
      setProcessObj({
        process_id: newProcessId,
        process_group: processDetail ? processDetail.process_group : null,
        process_name: processDetail ? processDetail.process_name : "no name",
        process_detail: processDetail
          ? processDetail.process_detail
          : "no details",
        user_id: processDetail ? processDetail.user_id : "123",
        secret_key: SECRE_KEY,
      });
    }
  }, [processDetail]);

  //   useEffect for fetching configuration status
  useEffect(() => {
    const fetchConfigStatus = async () => {
      try {
        const payload = { userid: userData.userid, key: API_KEY };
        const encryptedData = encryptData(payload, PAYLOAD_SECRET);
        const response = await fetch(`${BASE_URL}/config/fetch`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: encryptedData }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.configured === "YES") {
            setConfigurationDetail({
              configured: data.configured,
              configDetail: { ...data.config },
            });
          }
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Network error: ", error);
      }
    };
    const fetchRestartTimeWait = async () => {
      const rstTime = await fetchConfigurationValue("api_restart_wait_time");
      // console.log("rstTime:::::: ", rstTime);

      if (!rstTime.isError) {
        setRestartTimeWait(Number(rstTime.value));
      }
    };

    fetchConfigStatus();
    fetchRestartTimeWait();
  }, [newProcessId, userData]);

  // state for activities detail object
  const [activitiesObj, setActivitiesObj] = useState([]);

  // useEffect for setting latest value in activity object
  useEffect(() => {
    if (nodes.length > 0) {
      const activitiesTemp = [];
      nodes.forEach((node) => {
        // console.log("formData: ", node.data.form.formbuilder_data);
        // const fields = parseFieldData(node.data.form.formbuilder_data);
        const fields = node.data.form.formbuilder_data;
        const isReferenceNumber = node.data.isCustomReferenceNumber || false;
        const list_data = node.data.list_data;
        const activityName = node.data.value;
        const activityPrevName = node.data.prev_name;
        const processName = processDetail?.process_name;
        const allFields = parseFormData(fields);
        const maxTitleLength = getMaxTitleLength(allFields);
        const odooVersion = processDetail?.odoo_version;
        const formView = fields
          ? generateOdooFormViewXml(
              fields,
              activityName,
              activityPrevName,
              processName,
              maxTitleLength,
              isReferenceNumber,
              odooVersion
            )
          : null;
        const listView = list_data
          ? generateOdooTreeViewXML(list_data, isReferenceNumber, odooVersion)
          : null;
        const kanbanData = node.data.kanbanData;
        const activity = {
          activity_id: node.id,
          activity_name: activityName,
          activity_type: node.type,
          activity_group: node.data.group,
          activity_prev: node.data.prev,
          activity_prev_name: node.data.prev_name,
          activity_next: node.data.next,
          activity_next_name: node.data.next_name,
          activity_isApiEnable: node.data.isApiEnable,
          activity_isDefaultValue: node.data.isDefaultValue,
          activity_defaultValue: node.data.defaultValue,
          activity_doctype: node.data.doctype || null,
          activity_todos: node.data.todos || null,
          assigned_form: fields,
          assigned_list: list_data,
          form_view: formView,
          list_view: listView,
          kanban_view: kanbanData,
        };
        if (node.type === "webform") {
          activity.thankyouPage = node.data.thankyouPage;
        }
        if (["start", "webform"].includes(node.type)) {
          activity.isAutoNextStep = node.data.isAutoNextStep;
          activity.isCustomReferenceNumber = node.data.isCustomReferenceNumber;
          activity.customReferenceNumber = node.data.customReferenceNumber;
          activity.defaultReferenceNumber = node.data.defaultReferenceNumber;
        }
        if (node.type === "decision") {
          activity.elseNextStep = node.data.elseNextStep;
        }
        if (node.type === "email_verify") {
          activity.email_verify_fields = node.data.email_verify_fields;
        }
        if (node.type === "email") {
          activity.template = node?.data?.template;
          activity.emailTo = node?.data?.emailTo;
        }
        if (["end", "discard", "exception", "reject"].includes(node.type)) {
          activity.activity_isFormReadOnly = node?.data?.isFormReadOnly;
        }
        activitiesTemp.push(activity);
      });
      setActivitiesObj([...activitiesTemp]);
    }
  }, [nodes]);

  // state for odooFitData
  const [odooFitData, setOdooFitData] = useState();

  // useEffect for setting latest value in odooFitData
  useEffect(() => {
    setOdooFitData({
      process: processObj,
      activities: sequenceNodes(activitiesObj),
      // activities: activitiesObj,
    });
  }, [processObj, activitiesObj]);
  console.log("odooFitData: ", JSON.stringify(odooFitData));

  useEffect(() => {
    setForms([]); // Clear forms before fetching new ones
    if (newProcessId) {
      fetchForms();
    }
  }, [newProcessId, setForms]);

  // method for saving the latest process Data into database
  const handleSaveData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/process/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...processDetail }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("response save process data: ", data);
      } else {
        const data = await response.json();
        console.log("error in saving: ", data);
      }
    } catch (error) {
      console.log("error in updating: ", error);
    }
  };

  // Function to update the process status
  const updateProcessStatus = async (status) => {
    const request = {
      process_id: newProcessId,
      user_id: userData.userid,
      status: status,
    };
    // console.log("request::::: ", JSON.stringify(request));
    try {
      const response = await fetch(`${BASE_URL}/process/update/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const data = await response.json();
        setProcessDetail({ ...data.updateProcess });
        console.log(
          "response::: process-status::: process status updated:::::::::::: ",
          data
        );
      } else {
        const data = await response.json();
        console.log(
          "response::: process-status::: Error updating process status:",
          data
        );
      }
    } catch (error) {
      console.log(
        "response::: process-status::: Error in updating process status:",
        error
      );
    }
  };

  // check in odoo funtion start here
  const handleCheckInOdoo = async () => {
    const edgeCheck = handleArrowCheck();
    if (edgeCheck.status) {
      if (isStartAndEndNode()) {
        const stepCheck = handlePrevNextCheck();
        if (stepCheck.status) {
          const formCheck = handleAssignedFormCheck();
          if (formCheck.status) {
            const emailTemplateCheck = handleEmailTemplateValidation();
            if (emailTemplateCheck.status) {
              const emailVerifyFieldCheck = handleEmailVerifyValidation();
              if (emailVerifyFieldCheck.status) {
                const referenceNumberCheck = handleReferenceNumberValidation();
                if (referenceNumberCheck.status) {
                  // start code for giving alert
                  await Swal.fire({
                    title: "Confirm for Check in Odoo",
                    text: "Please confirm if you are ready to check in odoo",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, Confirm!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      const odooCheck = async () => {
                        setCheckinStep("write");
                        setCheckInLoading(true);
                        // First Api Call =========================
                        // /process/initialise
                        try {
                          const response = await fetch(
                            `${BASE_URL}/process/initialise`,
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                process_id: newProcessId,
                                is_process_desing_completed: "true",
                                odoo_checking_data: odooFitData,
                              }),
                            }
                          );
                          const data = await response.json();
                          if (response.ok && data.message.code === 199) {
                            await handleProcessDeployLockRelease();
                            setCheckInLoading(false);
                            setCheckinStep("");
                            centerAlert("warning", data.message.message);
                            return;
                          } else if (response.ok && data.message.code === 198) {
                            await handleProcessDeployLockRelease();
                            setCheckInLoading(false);
                            setCheckinStep("");
                            centerAlert("warning", data.message.message);
                            return;
                          } else if (
                            response.ok &&
                            data.message.code === "200"
                          ) {
                            console.log(
                              "response::: First api response: ",
                              data
                            );

                            // Second Api Call ======== restart server
                            setCheckinStep("restart");
                            setCheckInLoading(true);
                            setTimeout(
                              () => restartServer(processDetail.database_url),
                              2000
                            );
                            setCheckinStep("delay");
                            const afterDelayApiCall = async () => {
                              try {
                                // Third Api Call ========= check restart status
                                setCheckinStep("process_status");
                                const payload = {
                                  database_url: processDetail.database_url,
                                  // process_id: newProcessId,
                                  key: API_KEY,
                                };
                                const encryptedData = encryptData(
                                  payload,
                                  PAYLOAD_SECRET
                                );
                                const response = await fetch(
                                  `${BASE_URL}/process/status`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                      data: encryptedData,
                                    }),
                                  }
                                );
                                const data = await response.json();
                                if (response.ok) {
                                  console.log(
                                    "response::: Third api response: ",
                                    data
                                  );
                                  try {
                                    // fourth api call =============== confirm api
                                    setCheckinStep("upgrade");
                                    const response = await fetch(
                                      `${BASE_URL}/process/confirm`,
                                      {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                          process_id: newProcessId,
                                          is_process_desing_completed: "true",
                                          odoo_checking_data: odooFitData,
                                        }),
                                      }
                                    );
                                    const data = await response.json();
                                    if (
                                      response.ok &&
                                      data.message.code === "200"
                                    ) {
                                      console.log(
                                        "response::: Fourth api response: ",
                                        data
                                      );
                                      // ===============================================================

                                      // Call the function to update the status to true
                                      setCheckinStep("process_status");
                                      // Fifth api call =========== publish/unpublish api
                                      await updateProcessStatus(true);
                                      processNodesAndUpdate();

                                      console.log(
                                        "response data from react data",
                                        data
                                      );
                                      // ===============================================================
                                      // for server restart code
                                      try {
                                        // Sixth api call ================ restart server
                                        setCheckinStep("restart");
                                        const payload = {
                                          database_url:
                                            processDetail.database_url,
                                          // process_id: newProcessId,
                                          key: API_KEY,
                                        };
                                        const encryptedData = encryptData(
                                          payload,
                                          PAYLOAD_SECRET
                                        );
                                        const response = await fetch(
                                          `${BASE_URL}/process/restart`,
                                          {
                                            method: "POST",
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                            },
                                            body: JSON.stringify({
                                              data: encryptedData,
                                            }),
                                          }
                                        );
                                        const data = await response.json();
                                        if (response.ok) {
                                          console.log(
                                            "response::: restart server data: ",
                                            data
                                          );
                                        } else {
                                          console.log(
                                            "response::: restart server data: ",
                                            data
                                          );
                                        }
                                      } catch (error) {
                                        console.log("error: ", error);
                                      }
                                      // ===============================================================
                                      // api for again upgrade module==================================
                                      setTimeout(async () => {
                                        try {
                                          // Seventh api call ================ upgrade module api
                                          setCheckinStep("upgrade");
                                          setCheckinLoadingTitle(
                                            "Upgrading Module Data..."
                                          );
                                          const payload = {
                                            database_url:
                                              processDetail.database_url,
                                            process_name:
                                              processDetail.process_name,
                                            key: API_KEY,
                                          };
                                          const encryptedData = encryptData(
                                            payload,
                                            PAYLOAD_SECRET
                                          );
                                          const response = await fetch(
                                            `${BASE_URL}/process/upgrade`,
                                            {
                                              method: "POST",
                                              headers: {
                                                "Content-Type":
                                                  "application/json",
                                              },
                                              body: JSON.stringify({
                                                data: encryptedData,
                                              }),
                                            }
                                          );
                                          const data = await response.json();
                                          if (response.ok) {
                                            console.log(
                                              "response::: restart server data: ",
                                              data
                                            );
                                          } else {
                                            console.log(
                                              "response::: restart server data: ",
                                              data
                                            );
                                          }
                                        } catch (error) {
                                          console.log("error: ", error);
                                        }
                                      }, 5000);
                                      // ===============================================================
                                      await handleProcessDeployLockRelease();
                                      setCheckInLoading(false);
                                      setCheckinLoadingTitle("");
                                      setCheckinStep("");
                                      centerAlertWithTitle(
                                        "Successfull!",
                                        data.message.message,
                                        "success"
                                      );
                                    } else {
                                      // Call the function to update the status to false
                                      await handleProcessDeployLockRelease();
                                      setCheckInLoading(false);
                                      setCheckinStep("");
                                      console.log("error in deploy", data);
                                      centerAlertWithTitle(
                                        "Internal Server Error!",
                                        data.message.message,
                                        "error"
                                      );
                                    }
                                  } catch (error) {
                                    // Call the function to update the status to false
                                    await handleProcessDeployLockRelease();
                                    setCheckInLoading(false);
                                    setCheckinStep("");
                                    console.log(
                                      "error in updating react: ",
                                      error
                                    );
                                    centerAlertWithTitle(
                                      "Internal Server Error!",
                                      error,
                                      "error"
                                    );
                                  }
                                } else {
                                  await handleProcessDeployLockRelease();
                                  console.log("response data: ", data);
                                }
                              } catch (error) {
                                await handleProcessDeployLockRelease();
                                console.log("error: ", error);
                              }
                            };
                            setTimeout(
                              afterDelayApiCall,
                              restartTimeWait || 5000
                            );
                          } else {
                            await handleProcessDeployLockRelease();
                            setCheckInLoading(false);
                            setCheckinStep("");
                            console.log("error in deploy", data);
                            centerAlertWithTitle(
                              "Internal Server Error!",
                              data.message.message,
                              "warning"
                            );
                          }
                        } catch (error) {
                          await handleProcessDeployLockRelease();
                          setCheckInLoading(false);
                          setCheckinStep("");
                          console.log("error in updating react: ", error);
                          centerAlertWithTitle(
                            "Internal Server Error!",
                            error,
                            "error"
                          );
                        }
                        // ========================================
                        // try {
                        //   const response = await fetch(
                        //     `${BASE_URL}/process/confirm`,
                        //     {
                        //       method: "POST",
                        //       headers: {
                        //         "Content-Type": "application/json",
                        //       },
                        //       body: JSON.stringify({
                        //         process_id: newProcessId,
                        //         is_process_desing_completed: "true",
                        //         odoo_checking_data: odooFitData,
                        //       }),
                        //     }
                        //   );
                        //   const data = await response.json();
                        //   if (response.ok && data.message.code === "200") {
                        //     // ===============================================================

                        //     // Call the function to update the status to true
                        //     setCheckinStep("process_status");
                        //     await updateProcessStatus(true);
                        //     processNodesAndUpdate();

                        //     console.log("response data from react data", data);
                        //     // ===============================================================
                        //     // for server restart code
                        //     try {
                        //       setCheckinStep("restart");
                        //       const payload = {
                        //         process_id: newProcessId,
                        //         key: API_KEY,
                        //       };
                        //       const encryptedData = encryptData(
                        //         payload,
                        //         PAYLOAD_SECRET
                        //       );
                        //       const response = await fetch(
                        //         `${BASE_URL}/process/restart`,
                        //         {
                        //           method: "POST",
                        //           headers: {
                        //             "Content-Type": "application/json",
                        //           },
                        //           body: JSON.stringify({ data: encryptedData }),
                        //         }
                        //       );
                        //       const data = await response.json();
                        //       if (response.ok) {
                        //         console.log("response data: ", data);
                        //       } else {
                        //         console.log("response data: ", data);
                        //       }
                        //     } catch (error) {
                        //       console.log("error: ", error);
                        //     }
                        //     // ===============================================================
                        //     setCheckInLoading(false);
                        //     setCheckinStep("");
                        //     centerAlertWithTitle(
                        //       "Successfull!",
                        //       data.message.message,
                        //       "success"
                        //     );
                        //   } else {
                        //     // Call the function to update the status to false

                        //     setCheckInLoading(false);
                        //     setCheckinStep("");
                        //     console.log("error in deploy", data);
                        //     centerAlertWithTitle(
                        //       "Internal Server Error!",
                        //       data.message.message,
                        //       "error"
                        //     );
                        //   }
                        // } catch (error) {
                        //   // Call the function to update the status to false

                        //   setCheckInLoading(false);
                        //   setCheckinStep("");
                        //   console.log("error in updating react: ", error);
                        //   centerAlertWithTitle(
                        //     "Internal Server Error!",
                        //     error,
                        //     "error"
                        //   );
                        // }
                      };
                      odooCheck();
                    }
                  });
                } else {
                  Swal.fire({
                    title: referenceNumberCheck.title,
                    text: referenceNumberCheck.report,
                    icon: "error",
                  });
                }
              } else {
                Swal.fire({
                  title: emailVerifyFieldCheck.title,
                  text: emailVerifyFieldCheck.report,
                  icon: "error",
                });
              }
            } else {
              Swal.fire({
                title: emailTemplateCheck.title,
                text: emailTemplateCheck.report,
                icon: "error",
              });
            }
          } else {
            Swal.fire({
              title: "Form not Assigned",
              text: `Please assign form to ${formCheck.nodeName} activity`,
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "Previous or Next Step is Not Set",
            text: stepCheck.report,
            icon: "error",
          });
        }
      } else {
        Swal.fire({
          title: "Missing Start or End Node",
          text: "It is compulsory to have start and end node",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Missing edges between activity",
        text: edgeCheck.report,
        icon: "error",
      });
    }
  };

  // method for checkin button click
  const handleCheckinClick = async (e) => {
    e.preventDefault();
    setCheckinStep("save");
    setCheckInLoading(true);
    await handleSaveData();
    setCheckInLoading(false);
    setCheckinStep("");
    handleCheckInOdoo();
  };

  // Function to update the module creation status
  const updateModuleCreateStatus = async (status) => {
    const request = {
      process_id: newProcessId,
      user_id: userData.userid,
      status,
    };
    console.log("request::::: ", request);
    try {
      const response = await fetch(`${BASE_URL}/process/update/module/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const data = await response.json();
        setProcessDetail(data.updateProcess);
        console.log("Module Creation status updated successfully:", data);
      } else {
        const data = await response.json();
        centerAlert("error", data.message);
        // topRightAlert("error", data.message);
        console.log("Error updating process status:", data);
      }
    } catch (error) {
      centerAlert("error", error);
      // topRightAlert("error", error);
      console.log("Error in updating process status:", error);
    }
  };

  // Function to create the module
  const createModule = async () => {
    try {
      const response = await fetch(`${BASE_URL}/process/create/module`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(processObj),
      });

      if (response.ok) {
        const data = await response.json();
        // for server restart code
        try {
          const payload = {
            database_url: processDetail.database_url,
            // process_id: newProcessId,
            key: API_KEY,
          };
          const encryptedData = encryptData(payload, PAYLOAD_SECRET);
          const response = await fetch(`${BASE_URL}/process/restart`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: encryptedData }),
          });
          const data = await response.json();
          if (response.ok) {
            console.log("response data: ", data);
          } else {
            console.log("response data: ", data);
          }
        } catch (error) {
          console.log("error: ", error);
        }
        // ===============================================================
        setCheckInLoading(false);
        centerAlert("success", data.message);

        // console.log("Module Created successfully:", data);
        updateModuleCreateStatus(true);
      } else {
        const data = await response.json();
        updateModuleCreateStatus(false);
        setCheckInLoading(false);
        console.log("Error creating module:", data);
        centerAlert("error", data.message);
        // topRightAlert("error", data.message);
      }
    } catch (error) {
      updateModuleCreateStatus(false);
      setCheckInLoading(false);
      centerAlert("error", error);
      // topRightAlert("error", error);
      console.log("Error in creating process module:", error);
    }
  };

  // method for module create button click
  const handleModuleCreateClick = (e) => {
    e.preventDefault();
    handleSaveData();
    const edgeCheck = handleArrowCheck();
    if (edgeCheck.status) {
      if (isStartAndEndNode()) {
        const stepCheck = handlePrevNextCheck();
        if (stepCheck.status) {
          const formCheck = handleAssignedFormCheck();
          if (formCheck.status) {
            const emailTemplateCheck = handleEmailTemplateValidation();
            if (emailTemplateCheck.status) {
              const emailVerifyFieldCheck = handleEmailVerifyValidation();
              if (emailVerifyFieldCheck.status) {
                const referenceNumberCheck = handleReferenceNumberValidation();
                if (referenceNumberCheck.status) {
                  setCheckInLoading(true);
                  createModule();
                } else {
                  Swal.fire({
                    title: referenceNumberCheck.title,
                    text: referenceNumberCheck.report,
                    icon: "error",
                  });
                }
              } else {
                Swal.fire({
                  title: emailVerifyFieldCheck.title,
                  text: emailVerifyFieldCheck.report,
                  icon: "error",
                });
              }
            } else {
              Swal.fire({
                title: emailTemplateCheck.title,
                text: emailTemplateCheck.report,
                icon: "error",
              });
            }
          } else {
            Swal.fire({
              title: "Form not Assigned",
              text: `Please assign form to ${formCheck.nodeName} activity`,
              icon: "error",
            });
          }
        } else {
          Swal.fire({
            title: "Previous or Next Step is Not Set",
            text: stepCheck.report,
            icon: "error",
          });
        }
      } else {
        Swal.fire({
          title: "Missing Start or End Node",
          text: "It is compulsory to have start and end node",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Missing edges between activity",
        text: edgeCheck.report,
        icon: "error",
      });
    }
  };

  // method for restart server button click
  const handleRestartClick = async () => {
    setCheckinStep("restart");
    setCheckInLoading(true);
    const response = await restartServer();
    if (response.status) {
      setCheckinStep("delay");
      setTimeout(async () => {
        setCheckinLoadingTitle("Checking Server Status...");
        setCheckinStep("process_status");
        const response = await restartServerStatusCheck();
        if (response.status) {
          setCheckInLoading(false);
          setCheckinStep("");
          setCheckinLoadingTitle("");
          centerAlert("success", response.data.message);
        } else {
          setCheckInLoading(false);
          setCheckinStep("");
          setCheckinLoadingTitle("");
          centerAlert("error", response.data.message);
        }
      }, 5000);
    } else {
      setCheckInLoading(false);
      setCheckinStep("");
      setCheckinLoadingTitle("");
      centerAlert("error", response.data.message);
    }
  };

  useEffect(() => {
    setProcessDetail({
      ...processDetail,
      process_data_flow: {
        nodesData: JSON.stringify(nodes),
        edgesData: JSON.stringify(edges),
      },
      process_data_form: JSON.stringify(forms),
    });
    handleSaveData();
  }, [nodes, edges, newProcessId, forms]);

  if (newProcessId === null || newProcessId === "") {
    navigate("/dashboard/processes");
  }

  // api method for related model field update status
  const handleModelFieldStatusUpdate = async (formId) => {
    try {
      const payload = {
        process_id: newProcessId,
        form_builder_id: formId,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/form/update/inner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("response data:**:: ", data);
      } else {
        console.log("response data:**:: ", data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  // method for iterating over nodes and updating over form data
  function processNodesAndUpdate() {
    const processedIds = new Set();

    nodes.forEach((node) => {
      if (node.data.isFormSelected && node.data.form) {
        const { form_builder_id, formbuilder_data } = node.data.form;

        if (form_builder_id && !processedIds.has(form_builder_id)) {
          handleModelFieldStatusUpdate(form_builder_id);
          processedIds.add(form_builder_id);
        }
        node.data.form.formbuilder_data =
          handleUpdateRelatedModelStatus(formbuilder_data);
      }
    });
  }

  // // method for rendering loading screen at deploy click
  // const handleLoaderScreen = () => {
  //   if (checkinLoading) {
  //     switch (checkinStep) {
  //       case "save":
  //         return (
  //           <div className="progress_bar_checkin">
  //             {/* Loading */}
  //             <h3>Saving Process Data...</h3>
  //             <PacmanLoader
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //       case "write":
  //         return (
  //           <div className="progress_bar_checkin">
  //             <h3>Updating Process Data...</h3>
  //             <GridLoader
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //       case "restart":
  //         return (
  //           <div className="progress_bar_checkin">
  //             <h3>Restarting Server...</h3>
  //             <MoonLoader
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //       case "upgrade":
  //         return (
  //           <div className="progress_bar_checkin">
  //             <h3>Upgrading Process Module...</h3>
  //             <HashLoader
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //       case "process_status":
  //         return (
  //           <div className="progress_bar_checkin">
  //             <h3>{checkinLoadingTitle || "Updating Process Status..."}</h3>
  //             <PropagateLoader
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //       case "delay":
  //         return (
  //           <div className="progress_bar_checkin">
  //             <h3>Waiting for Server Restart...</h3>
  //             <PuffLoader
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //       default:
  //         return (
  //           <div className="progress_bar_checkin">
  //             <h3>Processing...</h3>
  //             <BallTriangle
  //               height={100}
  //               width={100}
  //               radius={5}
  //               color="#1a7e8f"
  //               // ariaLabel="ball-triangle-loading"
  //               wrapperStyle={{}}
  //               wrapperClass=""
  //               visible={true}
  //             />
  //           </div>
  //         );
  //     }
  //   }
  // };

  // console.log("processDetail::::: ", processDetail);
  // console.log("nodes:::: ", nodes);
  // console.log("newProcessId::::: ", newProcessId);
  // console.log("restartTimeWait::::: ", restartTimeWait);
  // console.log("edges:::: all edge:: ", edges);
  // console.log("edges:::: all edge json:: ", JSON.stringify(edges));

  return processDetailLoading ? (
    <div className="process-moeler-page">{handleLoaderScreen()}</div>
  ) : (
    <div className="process-moeler-page">
      {checkinLoading && handleLoaderScreen()}

      {processDetail?.is_module_created ? (
        <button
          className="btn pm_process_save_btn"
          onClick={handleCheckinClick}
        >
          <IoRocketSharp style={{ fontSize: "20px" }} />
          Deploy
        </button>
      ) : (
        <button
          className="btn pm_process_save_btn"
          onClick={handleModuleCreateClick}
        >
          Check In
        </button>
      )}
      {/* <button
        className="btn pm_process_server_rst_btn"
        onClick={handleRestartClick}
      >
        <FaServer style={{ fontSize: "20px" }} />
        Restart Server
      </button> */}
      {newProcessId && <ProcessModelerTopBar />}
      {isControlPanelOpen ? (
        <ControlPanel setControlPanelOpen={setControlPanelOpen} />
      ) : (
        <div className="menu_cp">
          <CiMenuFries
            style={{ fontSize: "40px" }}
            onClick={() => setControlPanelOpen(true)}
          />
        </div>
      )}
      <Board />
      {shapeId !== null
        ? showShapeUpdateForm && (
            <ShapeUpdateForm setCheckInLoading={setCheckInLoading} />
          )
        : ""}
    </div>
  );
};

export default ProcessModelerPage;
