import React, { useEffect, useState } from "react";
import "./ExistingHutchForm.css";
import { useProcessContext } from "../../../context/ProcessContext";
import { Link, useNavigate } from "react-router-dom";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../../config";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useFormContext } from "../../../context/FormContext";
import { BallTriangle } from "react-loader-spinner";

const HutchUpdateForm = () => {
  const { centerAlert, userData, encryptData } = useProcessContext();
  const { configid } = useFormContext();
  const navigate = useNavigate();
  const [showKey, setShowKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [configureData, setConfigureData] = useState({
    userId: userData.userid,
    configName: userData.username,
    configType: "baseUrl",
    parameter: "",
    database: "",
    databasetype: "existing",
    securitykey: "",
    license_key: "",
    client_rule_engine: false,
    odoo_version: "",
  });

  const fetchConfigData = async () => {
    const requestBody = {
      configid: configid,
      userid: configureData.userId,
      key: API_KEY,
    };
    try {
      const encryptedPayload = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/fetch/one`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: requestBody,
        // body: JSON.stringify({ data: encryptedPayload }),
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("fetch one config:", data.config);
        setConfigureData({
          ...configureData,
          configName: data.config[0].configname,
          configType: data.config[0].configtype,
          parameter: data.config[0].parameter,
          database: data.config[0].database,
          databasetype: "existing",
          securitykey: data.config[0].securitykey,
          license_key: data.config[0].license_key,
          client_rule_engine: data.config[0].client_rule_engine,
          odoo_version: data.config[0].odoo_version,
        });
      } else {
        console.error("Error config fetch data:", data, response.statusText);
        // Handle the error here, like showing an error message
      }
    } catch (error) {
      console.error("Network error:", error);
      // Handle network error here
    }
  };
  useEffect(() => {
    fetchConfigData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setConfigureData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value?.trim(),
    }));
  };

  const handleResetForm = () =>
    setConfigureData({
      userId: userData.userid,
      configName: userData.username,
      configType: "baseUrl",
      parameter: "",
      database: "",
      databasetype: "existing",
      securitykey: "",
      license_key: "",
      client_rule_engine: false,
      odoo_version: "",
    });

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setLoading(true);

    const requestBody = {
      configid: configid,
      userid: configureData.userId,
      configname: configureData.configName,
      configtype: configureData.configType,
      parameter: configureData.parameter,
      database: configureData.database,
      databasetype: configureData.databasetype,
      securitykey: configureData.securitykey,
      license_key: configureData.license_key,
      client_rule_engine: configureData.client_rule_engine,
      odoo_version: configureData.odoo_version,
      key: API_KEY,
    };
    try {
      const encryptedPayload = encryptData(requestBody, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/config/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: requestBody,
        body: JSON.stringify({ data: encryptedPayload }),
        // body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        setLoading(false);
        centerAlert("success", data.message);
        console.log("Data updated successfully:", data);
        handleResetForm();
        navigate("/dashboard/configurations");
      } else {
        const data = await response.json();
        setLoading(false);
        centerAlert("error", data.message);
        console.error("Error creating data:", data);
        // Handle the error here, like showing an error message
      }
    } catch (error) {
      setLoading(false);
      centerAlert("error", error);
      console.error("Network error:", error);
      // Handle network error here
    }
  };
  if (configid === null) {
    navigate("/dashboard/configurations");
  }

  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ width: "100%", height: "100%", backgroundColor: "white" }}
    >
      {loading && (
        <div
          className="progress_bar_checkin"
          style={{ width: "350px", height: "250px", top: "40%" }}
        >
          {/* Loading */}
          <h3>Processing...</h3>
          {/* <ProgressBar progress={progress} /> */}
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#1a7e8f"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className="configure_page_container">
        <form className="configure_page_form" onSubmit={handleSubmit}>
          <h2>Configuration Form</h2>
          <div
            className="mb-3 d-none justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputUserId1" className="form-label">
              User Id
            </label>
            <input
              type="text"
              name="userId"
              value={configureData.userId}
              placeholder="enter user id"
              className="form-control"
              id="exampleInputUserId1"
              onChange={handleInputChange}
              readOnly
            />
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputConfigName1" className="form-label">
              Username
            </label>
            <input
              type="text"
              name="configName"
              className="form-control"
              value={configureData.configName}
              placeholder="enter user name"
              id="exampleInputConfigName1"
              onChange={handleInputChange}
            />
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputConfigName1" className="form-label">
              Hutch (database) Name*
            </label>
            <input
              type="text"
              name="database"
              className="form-control"
              value={configureData.database}
              placeholder="enter hutch name"
              id="exampleInputConfigName1"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="d-none mb-3 configure_selection_container">
            <label htmlFor="exampleInputConfigtype1" className="form-label">
              Config Type
            </label>
            <select
              className="form-select configure_page_select"
              name="configType"
              aria-label="Default select example"
              onChange={handleInputChange}
            >
              <option value="">Select type</option>
              <option value="baseUrl">Base Url</option>
            </select>
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleodoo_version" className="form-label">
              Odoo Version*
            </label>
            <select
              id="exampleodoo_version"
              className="form-select configure_page_select"
              name="odoo_version"
              value={configureData.odoo_version}
              aria-label="Default select example"
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select odoo version
              </option>
              <option value="v16">Odoo v.16.0</option>
              <option value="v17">Odoo v.17.0</option>
              <option value="v18">Odoo v.18.0</option>
            </select>
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputParameter1" className="form-label">
              Url*
            </label>
            <input
              type="text"
              name="parameter"
              value={configureData.parameter}
              placeholder="enter your url"
              className="form-control"
              id="exampleInputParameter1"
              onChange={handleInputChange}
              required
            />
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%", position: "relative" }}
          >
            <label htmlFor="exampleInputSecurity1" className="form-label">
              Security Key*
            </label>
            <input
              type={`${showKey ? "text" : "password"}`}
              name="securitykey"
              value={configureData.securitykey}
              placeholder="enter security key"
              className="form-control"
              id="exampleInputSecurity1"
              onChange={handleInputChange}
              required
            />
            <button
              className="password_eye_button"
              onClick={(e) => {
                e.preventDefault();
                setShowKey(!showKey);
              }}
            >
              {showKey ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label htmlFor="exampleInputLicenseId" className="form-label">
              License Key*
            </label>
            <input
              type="text"
              name="license_key"
              value={configureData.license_key}
              placeholder="enter your license id"
              className="form-control"
              id="exampleInputLicenseId"
              onChange={handleInputChange}
              required
            />
          </div>
          <div
            className="mb-3 d-flex justify-content-start align-items-center gap-3"
            style={{ width: "100%" }}
          >
            <label
              htmlFor="exampleInputClientRuleEngine"
              className="form-label"
            >
              Client Rule Engine
            </label>
            <input
              type="checkbox"
              name="client_rule_engine"
              checked={configureData.client_rule_engine}
              // value={configureData.client_rule_engine}
              // placeholder="enter your license id"
              // className="form-control"
              id="exampleInputClientRuleEngine"
              onChange={handleInputChange}
              style={{ width: "20px", position: "relative", left: "-5%" }}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Link to={"/dashboard/configurations/hutch"}>
              <button className="btn btn-secondary">Discard</button>
            </Link>
            <button
              type="submit"
              className="btn"
              style={{ backgroundColor: "#1a7e8f", color: "white" }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HutchUpdateForm;
