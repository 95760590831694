import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./EmailEditor.css";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  Autosave,
  BalloonToolbar,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { ImAttachment } from "react-icons/im";
import { useProcessContext } from "../context/ProcessContext";

export default function EmailEditor({
  onContentChange,
  attachments,
  setAttachments,
  attachmentNames,
  dynamicVariable,
  initialVal,
  models,
  handleModelChange,
  showAttachment,
}) {
  const { newProcessId } = useProcessContext();
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "showBlocks",
        "findAndReplace",
        "|",
        "heading",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "subscript",
        "superscript",
        "|",
        "specialCharacters",
        "link",
        "insertImage",
        "mediaEmbed",
        "insertTable",
        "blockQuote",
        "htmlEmbed",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      Autosave,
      BalloonToolbar,
      Base64UploadAdapter,
      BlockQuote,
      Bold,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      HtmlEmbed,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      ShowBlocks,
      SourceEditing,
      SpecialCharacters,
      SpecialCharactersArrows,
      SpecialCharactersCurrency,
      SpecialCharactersEssentials,
      SpecialCharactersLatin,
      SpecialCharactersMathematical,
      SpecialCharactersText,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo,
    ],
    balloonToolbar: [
      "bold",
      "italic",
      "|",
      "link",
      "insertImage",
      "|",
      "bulletedList",
      "numberedList",
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    initialData: initialVal || "",
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    placeholder: "Type or paste your content here!",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };

  // useEffect(() => {
  //   if (editorInstance && initialVal) {
  //     editorInstance.setData(initialVal);
  //   }
  // }, [editorInstance, initialVal]);
  useEffect(() => {
    if (
      editorInstance &&
      initialVal !== undefined &&
      !editorInstance.isReadOnly
    ) {
      // Set the data only once when the editor is first initialized
      if (editorInstance.getData() === "") {
        editorInstance.setData(initialVal);
      }
    }
  }, [editorInstance, initialVal]);

  const handleEditorChange = (_, editor) => {
    const data = editor.getData();
    onContentChange(data); // Trigger callback to send data to parent component
  };

  // Handle inserting variable at the current cursor position
  const insertVariable = (e) => {
    if (editorInstance) {
      editorInstance.model.change((writer) => {
        const insertPosition =
          editorInstance.model.document.selection.getFirstPosition();
        writer.insertText(e.target.value, insertPosition);
      });
    }
    e.target.value = "";
  };

  // Handle file upload      //old working code
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files); // Get uploaded files
    const newAttachments = [...attachments, ...files];
    setAttachments([...newAttachments]); // Add to state
  };

  // Remove an attachment    // old working code
  const removeAttachment = (e, index) => {
    e.preventDefault();
    let newAttachments = [...attachments];
    newAttachments = newAttachments.filter((_, i) => i !== index);
    setAttachments(newAttachments);
  };
  // =========================================================
  // const fileToBuffer = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = () => resolve(new Uint8Array(reader.result)); // Convert to Uint8Array
  //     reader.onerror = (err) => reject(err);
  //     reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
  //   });
  // };

  // // Convert files to base64 and return the result
  // const convertToBase64 = async (filesArray) => {
  //   const promises = filesArray.map((file) => {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   });

  //   // Wait for all promises to resolve
  //   try {
  //     const base64Data = await Promise.all(promises);
  //     return base64Data; // Return the base64 data
  //   } catch (error) {
  //     console.error("Error converting to base64: ", error);
  //     return [];
  //   }
  // };

  // const handleFileUpload = async (event) => {
  //   const files = Array.from(event.target.files);

  //   // const fileBuffers = [];
  //   const fileBuffers = await convertToBase64(files);
  //   const fileNames = [];

  //   // Convert each file to buffer
  //   for (const file of files) {
  //     // const buffer = await fileToBuffer(file);
  //     // fileBuffers.push(buffer);
  //     // fileBuffers.push(Array.from(buffer));
  //     fileNames.push(file.name);
  //     // fileBuffers.push({
  //     //   name: file.name,
  //     //   type: file.type,
  //     //   buffer,
  //     // });
  //   }

  //   // const newFiles = files.map((file) => ({
  //   //   file,
  //   //   name: file.name,
  //   // }));
  //   // console.log("newfiles:::::: ", newFiles);
  //   // console.log("file:::::::: buffer: ", fileBuffers);
  //   // console.log("file:::::::: names: ", fileNames);

  //   const newAttachments = [...attachments, ...fileBuffers];
  //   const newFileNames = [...attachmentNames, ...fileNames];
  //   // const newFileNames = newAttachments.map((attachment) => attachment.name);
  //   setAttachments(newAttachments, newFileNames);

  //   // Extract and update file names separately
  //   // const fileNames = newAttachments.map((attachment) => attachment.name);
  //   // setAttachmentNames(fileNames);
  // };

  // // Handle removing an attachment
  // const removeAttachment = (e, index) => {
  //   e.preventDefault();
  //   const newAttachments = attachments.filter((_, i) => i !== index);
  //   // const newFileNames = newAttachments.map((attachment) => attachment.name);
  //   const newFileNames = attachmentNames.filter((_, i) => i !== index);
  //   setAttachments(newAttachments, newFileNames);

  //   // Update file names
  //   // const fileNames = newAttachments.map((attachment) => attachment.name);
  //   // setAttachmentNames(fileNames);
  // };
  // ========================================================

  return (
    <div className="main-container">
      <div
        className="editor-container editor-container_classic-editor"
        ref={editorContainerRef}
      >
        <div className="editor-container__editor">
          <div ref={editorRef}>
            {isLayoutReady && (
              <>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfig}
                  onReady={(editor) => setEditorInstance(editor)}
                  onChange={handleEditorChange}
                />
                <div
                  style={{
                    width: "100%",
                    marginTop: "3px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {/* Dropdown for selecting dynamic variables */}
                  <div className="variable-selector">
                    <label style={{ fontSize: "14px" }}>Dynamic Value: </label>
                    {newProcessId !== null ? (
                      <select
                        onChange={(e) => insertVariable(e)}
                        selected=""
                        className="email_template_variable_select"
                      >
                        <option value="">Select variable</option>
                        {/* {DYNAMIC_VARIABLES.map((variable) => ( */}
                        {dynamicVariable &&
                          dynamicVariable?.map((variable) => (
                            <option
                              key={variable}
                              value={
                                ["many2many", "many2one", "one2many"].includes(
                                  variable.type
                                )
                                  ? `{{object.${variable.technicalName}.name}}`
                                  : `{{object.${variable.technicalName}}}`
                              }
                            >
                              {variable.title}
                            </option>
                          ))}
                      </select>
                    ) : (
                      <div className="d-flex justify-content-between gap-2">
                        <select
                          onChange={(e) => handleModelChange(e)}
                          className="email_template_variable_select"
                        >
                          <option value="">Select model</option>
                          {/* {DYNAMIC_VARIABLES.map((variable) => ( */}
                          {models &&
                            models?.map((model) => (
                              <option key={model.id} value={model.model}>
                                {model.model}
                              </option>
                            ))}
                        </select>
                        <select
                          onChange={(e) => insertVariable(e)}
                          selected=""
                          className="email_template_variable_select"
                        >
                          <option value="">Select variable</option>
                          {/* {DYNAMIC_VARIABLES.map((variable) => ( */}
                          {dynamicVariable &&
                            dynamicVariable?.map((variable) => (
                              <option
                                key={variable}
                                value={
                                  variable.technicalName !== undefined
                                    ? [
                                        "many2many",
                                        "many2one",
                                        "one2many",
                                      ].includes(variable.type)
                                      ? `{{object.${variable.technicalName}.name}}`
                                      : `{{object.${variable.technicalName}}}`
                                    : `{{object.${variable}}}`
                                }
                              >
                                {variable.technicalName !== undefined
                                  ? variable.title
                                  : variable}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>
                  {/* Attachment Upload Field */}
                  {showAttachment && (
                    <div className="attachment-upload" style={{ width: "45%" }}>
                      <label
                        htmlFor="attachmentInput"
                        style={{ fontSize: "14px" }}
                      >
                        Upload Attachments:{" "}
                      </label>
                      <input
                        type="file"
                        id="attachmentInput"
                        multiple
                        onChange={handleFileUpload}
                        title="attachment"
                        style={{
                          width: "30px",
                          opacity: "0",
                          zIndex: "10",
                          cursor: "pointer",
                          position: "relative",
                          right: "35%",
                        }}
                      />
                      <ImAttachment className="upload_attachment_icon" />
                    </div>
                  )}

                  {/* Display Uploaded Attachments */}
                  {showAttachment && attachments && attachments.length > 0 && (
                    <div className="attachments-list">
                      <h6 style={{ fontWeight: "500" }}>Attachments:</h6>
                      {attachments.length > 0 ? (
                        <ul>
                          {/* {attachmentNames.map((name, index) => ( */}
                          {attachments.map((file, index) => (
                            <li key={index}>
                              {/* <span>{name}</span>{" "} */}
                              <span>{file.name}</span>{" "}
                              <button
                                onClick={(e) => removeAttachment(e, index)}
                                className="email_attachment_remove_btn"
                              >
                                Remove
                              </button>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No attachments uploaded.</p>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
