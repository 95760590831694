import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import EmailEditor from "../../utility_files/EmailEditor";
import { useFormContext } from "../../context/FormContext";
import { useProcessContext } from "../../context/ProcessContext";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { useNodeContext } from "../../context/NodeContext";

const SendEmailButtonOverlay = ({
  updateFieldData,
  setUpdateFieldData,
  handleBlurData,
  selectedFieldData,
  templateData,
}) => {
  const { centerAlert } = useProcessContext();
  const { nodes, shapeId } = useNodeContext();
  const { formData, setFormData, parseFormDataWithoutTableO2m } =
    useFormContext();
  const [emailTemplateData, setEmailTemplateData] = useState({
    template_name: "",
    mail_to: "",
    mail_subject: "",
    mail_body: "",
  });
  // method for input data change
  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setEmailTemplateData((prevTemplateData) => ({
      ...prevTemplateData,
      [name]: value,
    }));
  };

  // method for mail body data change
  const handleContentChange = (content) => {
    setEmailTemplateData((prevTemplateData) => ({
      ...prevTemplateData,
      mail_body: content,
    }));
  };
  useEffect(() => {
    const currentNode = nodes.find((node) => node.id === shapeId);
    if (templateData) {
      setEmailTemplateData({
        template_name:
          templateData.template_name ||
          `${currentNode.data.value} Email Template`,
        mail_to: templateData.mail_to || "",
        mail_subject: templateData.mail_subject || "",
        mail_body: templateData.mail_body || "",
      });
    } else {
      resetTempData();
      setEmailTemplateData((prevEmailTempData) => ({
        ...prevEmailTempData,
        template_name: `${currentNode.data.value} Email Template`,
      }));
    }
  }, [templateData]);

  // useEffect(() => {
  //   if (updateFieldData && updateFieldData.template !== null && updateFieldData.template !== undefined) {
  //     const temp = updateFieldData.template;
  //     setEmailTemplateData({
  //       mail_to: temp.mail_to,
  //       mail_subject: temp.mail_subject,
  //       mail_body: temp.mail_body,
  //     });
  //   }
  //   // return resetTempData;
  // }, [updateFieldData, updateFieldData.template]);

  // method for reset field data
  const resetTempData = () =>
    setEmailTemplateData({
      template_name: "",
      mail_to: "",
      mail_subject: "",
      mail_body: "",
    });

  // method for saving template data
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!emailTemplateData.mail_to) {
  //     centerAlert("error", "To* field is mandatory to set.");
  //     return;
  //   }
  //   if (!emailTemplateData.mail_subject) {
  //     centerAlert("error", "Subject* field is mandatory to set.");
  //     return;
  //   }
  //   setUpdateFieldData((prevFieldData) => ({
  //     ...prevFieldData,
  //     template: { ...emailTemplateData },
  //   }));
  //   const newFormData = JSON.parse(JSON.stringify(formData));
  //   const { rowIndex, columnIndex } = selectedFieldData;
  //   newFormData[rowIndex][columnIndex] = { ...updateFieldData };
  //   setFormData(newFormData);
  //   resetTempData();
  //   const modalElement = document.getElementById("sendEmailButtonModal");
  //   const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
  //   modalInstance.hide();
  // };
  // method for setting template name
  const handleCurrentNodeName = () => {
    const currentNode = nodes.find((node) => node.id === shapeId);
    if (currentNode) {
      setEmailTemplateData((prevEmailTempData) => ({
        ...prevEmailTempData,
        template_name: `${currentNode.data.value} Email Template`,
      }));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !emailTemplateData.template_name ||
      emailTemplateData.template_name === ""
    ) {
      handleCurrentNodeName();
    }

    if (!emailTemplateData.mail_to) {
      centerAlert("error", "To* field is mandatory to set.");
      return;
    }

    if (!emailTemplateData.mail_subject) {
      centerAlert("error", "Subject* field is mandatory to set.");
      return;
    }

    // Update field data
    const updatedFieldData = {
      ...updateFieldData,
      template: { ...emailTemplateData },
    };
    setUpdateFieldData(updatedFieldData);

    // Update form data
    const newFormData = [...formData];
    const { rowIndex, columnIndex } = selectedFieldData;
    newFormData[rowIndex][columnIndex] = updatedFieldData;

    setFormData(newFormData);
    resetTempData();

    // Close modal
    const modalElement = document.getElementById("sendEmailButtonModal");
    const modalInstance = bootstrapBundleMin.Modal.getInstance(modalElement);
    if (modalInstance) modalInstance.hide();
  };
  console.log("emailTemplateData:::", emailTemplateData);
  console.log("templateData:::", templateData);
  console.log("nodes:::", nodes);

  return (
    <div
      className="modal fade"
      id="sendEmailButtonModal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-labelledby="sendEmailButtonModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Email Template Form
            </h1>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                e.preventDefault();
                resetTempData();
              }}
              style={{
                position: "absolute",
                right: "20px",
                fontSize: "25px",
                color: "white",
              }}
            >
              <IoMdClose />
            </button>
          </div>
          <form method="post" onSubmit={handleSubmit}>
            <div className="modal-body email_template_modal_body">
              <div className="emailTemplateModalForm">
                <div className="emailTemplateModalForm_inputField">
                  <label htmlFor="mail_to">To*</label>
                  <input
                    type="email"
                    id="mail_to"
                    placeholder="Enter recipient email"
                    name="mail_to"
                    value={emailTemplateData?.mail_to}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                <div className="emailTemplateModalForm_inputField">
                  <label htmlFor="mail_subject">Subject*</label>
                  <input
                    type="text"
                    id="mail_subject"
                    placeholder="Enter email subject"
                    name="mail_subject"
                    value={emailTemplateData?.mail_subject}
                    onChange={handleDataChange}
                    required
                  />
                </div>
                <div className="emailTemplateModalForm_inputField">
                  <label htmlFor="mail_from">Content</label>
                  <EmailEditor
                    onContentChange={handleContentChange}
                    dynamicVariable={
                      formData && Array.isArray(formData)
                        ? parseFormDataWithoutTableO2m(formData)
                        : null
                    }
                    initialVal={emailTemplateData.mail_body || ""}
                    showAttachment={false}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  resetTempData();
                }}
              >
                Close
              </button>
              <button type="submit" className="btn modal_save_button">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SendEmailButtonOverlay;
