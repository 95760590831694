import React, { memo, useEffect, useState } from "react";
import "./FormBoard.css";
import { useFormContext } from "../../../context/FormContext";
import BASE_URL, { API_KEY, PAYLOAD_SECRET } from "../../../../config";
import { useProcessContext } from "../../../context/ProcessContext";
import { Link, useNavigate } from "react-router-dom";
import { useNodeContext } from "../../../context/NodeContext";
import { MdDeleteOutline } from "react-icons/md";
import { CiCamera } from "react-icons/ci";
import { IoGlobeOutline } from "react-icons/io5";
import ChatterScreen from "./chatter-screen.png";
import { FiDelete } from "react-icons/fi";
import { FaPlus, FaRegImage } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import CustomDropdown from "../../../template/custom_dropdown/CustomDropdown";
import { TbPointFilled } from "react-icons/tb";
import { GiCancel } from "react-icons/gi";
import DomainComponent from "../../../template/domain_component/DomainComponent";

const FormBoard = ({ selectedListField, setSelectedListField }) => {
  const navigate = useNavigate();
  const {
    formData,
    setFormData,
    setShowFieldDetail,
    selectedFieldData,
    setSelectedFieldData,
    newFormId,
    setForms,
    forms,
    parseFormData,
    kanbanDetail,
    setKanbanDetail,
    listDetail,
    setListDetail,
    isKanbanBuilder,
    builderType,
    setIsKanbanBuilder,
    setBuilderType,
    isCustomModelForm,
    setIsCustomModelForm,
    customModelDetail,
    setCustomModelDetail,
    parseTableFields,
  } = useFormContext();
  const { setNodes, shapeId } = useNodeContext();
  const {
    newProcessId,
    processDetail,
    topRightAlert,
    centerAlert,
    encryptData,
    userData,
  } = useProcessContext();
  const [formDesc, setFormDesc] = useState(null);
  const [draggedOverIndex, setDraggedOverIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [colNumber, setColNumber] = useState(2);
  const [columns, setColumns] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [options, setOptions] = useState("");
  const [selectionOptions, setSelectionOptions] = useState({
    name: "",
    options: "",
  });
  const [allFields, setAllFields] = useState(null);
  const [listFieldDelBtn, setListFieldDelBtn] = useState(false);
  const [kanbanFieldDelBtn, setKanbanFieldDelBtn] = useState(false);
  const [selectedKanbanField, setSelectedKanbanField] = useState(null);
  // const [selectedListField, setSelectedListField] = useState(null);
  const [allLocalRelationalField, setAllLocalRelatedField] = useState(null);
  // const processNameRef = useRef(null);
  // const formNameRef = useRef(null);
  // const [isProcessNameOverflowing, setIsProcessNameOverflowing] =
  //   useState(false);
  // const [isFormNameOverflowing, setIsFormNameOverflowing] =
  //   useState(false);

  if (
    (newProcessId === "null" || shapeId === null) &&
    isCustomModelForm === false
  ) {
    navigate("/dashboard/processes");
  }
  // useEffect for all fields
  useEffect(() => {
    if (forms) {
      const oprs = [];
      forms.forEach((form) => {
        if (form.formbuilder_data) {
          const opr = parseFormData(form.formbuilder_data);
          opr.forEach((op) => {
            oprs.push(op);
          });
        }
      });
      setAllFields(oprs);
    }
  }, [forms]);

  // useEFfect for getting all relational field
  useEffect(() => {
    if (formData) {
      const allFormField = parseFormData(formData);
      const relationFields = allFormField?.filter((field) =>
        ["many2many", "many2one", "one2many"].includes(field.type)
      );
      setAllLocalRelatedField([...relationFields]);
    }
  }, [formData]);

  // useEffect for check process and form name overflow
  // useEffect(() => {
  //   const checkOverflow = () => {
  //     if (processNameRef.current) {
  //       setIsProcessNameOverflowing(
  //         processNameRef.current.scrollWidth >
  //           processNameRef.current.clientWidth
  //       );
  //     }
  //     if (formNameRef.current) {
  //       setIsFormNameOverflowing(
  //         formNameRef.current.scrollWidth > formNameRef.current.clientWidth
  //       );
  //     }
  //   };
  //   checkOverflow();
  //   window.addEventListener("resize", checkOverflow);

  //   return () => {
  //     window.removeEventListener("resize", checkOverflow);
  //   };
  // }, [processDetail, formDesc?.form_builder_name, shapeId]);

  // Handling Drag and Drop for list
  // method for list drop over
  const handleListDragOver = (e, rowIndex = null) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDraggedOverIndex({ rowIndex });
  };

  // method for list drop over
  const handleListDrop = (e, rowIndex = null) => {
    e.preventDefault();
    e.stopPropagation();

    let fieldData;

    try {
      fieldData = JSON.parse(e.dataTransfer.getData("text/plain"));
    } catch (error) {
      console.error("Invalid drop data:", error);
      return;
    }

    const {
      title,
      technicalName,
      type,
      widget,
      tooltip,
      isRequired,
      isReadOnly,
      isInvisible,
    } = fieldData;

    const newField = {
      title,
      technicalName,
      type,
      widget,
      tooltip,
      isRequired,
      isReadOnly,
      isInvisible,
    };
    const updateListData = [...listDetail];
    if (rowIndex === null) {
      updateListData.push(newField);
    } else {
      updateListData.splice(rowIndex + 1, 0, newField);
    }
    setDraggedOverIndex(null);
    setListDetail([...updateListData]);
  };

  // Handling Drag and Drop for Kanban
  // method for kanban drop over
  const handleKanbanDragOver = (e, rowIndex = null, columnIndex = null) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDraggedOverIndex({ rowIndex, columnIndex });
  };

  // method for kanban drop over
  const handleKanbanDrop = (e, rowIndex = null, columnIndex = null) => {
    e.preventDefault();
    e.stopPropagation();

    let fieldData;

    try {
      fieldData = JSON.parse(e.dataTransfer.getData("text/plain"));
    } catch (error) {
      console.error("Invalid drop data:", error);
      return;
    }

    const { type, title, tooltip } = fieldData;

    const newField = { type, title, tooltip };
    const updateKanbanData = [...kanbanDetail];
    if (rowIndex === null) {
      updateKanbanData.push([newField]);
    } else {
      if (columnIndex === null) {
        updateKanbanData.splice(rowIndex, 0, [newField]);
      } else {
        updateKanbanData[rowIndex].splice(columnIndex + 1, 0, newField);
      }
    }
    setDraggedOverIndex(null);
    setKanbanDetail([...updateKanbanData]);
  };

  // method for changing the tab
  // const handleTabChange = (index) => setActiveTab(index);
  const handleTabChange = (e, rowIndex, columnIndex, tabIndex, tabName) => {
    e.stopPropagation();
    const newFormData = [...formData];
    newFormData[rowIndex][columnIndex].activeTab = tabIndex;
    setFormData(newFormData);
    setSelectedFieldData({ rowIndex, columnIndex, tabIndex, tabName });
    setShowFieldDetail(true);
  };

  // method for adding new tabpage to tab field
  const addNewTab = (rowIndex, columnIndex) => {
    let newFormData = [...formData];
    const tabField = newFormData[rowIndex][columnIndex].tabs;
    newFormData[rowIndex][columnIndex].tabs.push({
      tabName: `Tab ${tabField.length + 1}`,
      content: [],
    });
    setFormData(newFormData);
  };

  // method for finding send email button exist or not
  const findHeaderButtonIndex = (arrays) => {
    for (let outerIndex = 0; outerIndex < arrays.length; outerIndex++) {
      const innerArray = arrays[outerIndex];
      const found = innerArray.some((obj) => obj.type === "header-button");
      if (found) {
        return outerIndex;
      }
    }
    return -1;
  };

  // Handling Drag and Drop for Form
  // method for handling drop over
  const handleDragOver = (
    e,
    rowIndex = null,
    columnIndex = null,
    groupIndex = null,
    subGroupIndex = null,
    tabIndex = null,
    tabRowIndex = null,
    tabFieldIndex = null,
    tabGroupRowIndex = null,
    tabGroupFieldIndex = null
  ) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    setDraggedOverIndex({
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });
  };

  // method for dropping field
  // const handleDrop = (
  //   e,
  //   rowIndex = null,
  //   columnIndex = null,
  //   groupIndex = null,
  //   subGroupIndex = null,
  //   tabIndex = null,
  //   tabRowIndex = null,
  //   tabFieldIndex = null,
  //   tabGroupRowIndex = null,
  //   tabGroupFieldIndex = null,
  //   sourceIndex = null
  // ) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   let fieldData;
  //   let sourceIndices;

  //   try {
  //     const droppedData = JSON.parse(e.dataTransfer.getData("text/plain"));
  //     fieldData = droppedData.field ? droppedData.field : droppedData;
  //     sourceIndices = droppedData.sourceIndex;
  //   } catch (error) {
  //     console.error("Invalid drop data:", error);
  //     return;
  //   }

  //   let newField;
  //   if (fieldData?.widget === "res_partner_many2one") {
  //     newField = { ...fieldData, relatedModel: "res.partner" };
  //   } else {
  //     newField = { ...fieldData };
  //   }

  //   if (sourceIndices !== null && sourceIndices !== undefined) {
  //     setFormData((prevFormData) => {
  //       // First, remove the source field
  //       const newFormData = handleDeleteSourceField(
  //         sourceIndices,
  //         prevFormData
  //       );
  //       return newFormData;
  //     });
  //   }

  //   if (sourceIndices === null || sourceIndices === undefined) {
  //     if (newField.type === "selection") {
  //       setModalData({
  //         rowIndex,
  //         columnIndex,
  //         groupIndex,
  //         subGroupIndex,
  //         newField,
  //         tabIndex,
  //         tabRowIndex,
  //         tabFieldIndex,
  //         tabGroupRowIndex,
  //         tabGroupFieldIndex,
  //       });
  //       setShowModal(true);
  //       return;
  //     } else if (newField.type === "table") {
  //       setModalData({
  //         rowIndex,
  //         columnIndex,
  //         groupIndex,
  //         subGroupIndex,
  //         newField,
  //         tabIndex,
  //         tabRowIndex,
  //         tabFieldIndex,
  //         tabGroupRowIndex,
  //         tabGroupFieldIndex,
  //       });
  //       setShowModal2(true);
  //       return;
  //     }
  //   }

  //   addField(
  //     newField,
  //     rowIndex,
  //     columnIndex,
  //     groupIndex,
  //     subGroupIndex,
  //     tabIndex,
  //     tabRowIndex,
  //     tabFieldIndex,
  //     tabGroupRowIndex,
  //     tabGroupFieldIndex
  //   );
  // };

  // const addField = (
  //   newField,
  //   rowIndex,
  //   columnIndex,
  //   groupIndex,
  //   subGroupIndex,
  //   tabIndex,
  //   tabRowIndex,
  //   tabFieldIndex,
  //   tabGroupRowIndex,
  //   tabGroupFieldIndex
  // ) => {
  //   // console.log("form data: ", prevFormData);
  //   if (!Array.isArray(formData)) {
  //     setFormData([]);
  //   }
  //   setFormData((prevFormData) => {
  //     let newFormData =
  //       prevFormData?.length > 0
  //         ? JSON.parse(JSON.stringify(prevFormData))
  //         : [];
  //     // let newFormData = prevFormData?.length > 0 ? [...prevFormData] : [];
  //     const addFieldToGroup = (groupFields, subGroupIndex) => {
  //       if (groupIndex === null) {
  //         if (groupFields) {
  //           groupFields.push([newField]);
  //         }
  //       } else if (subGroupIndex === null) {
  //         groupFields.splice(groupIndex, 0, [newField]);
  //       } else {
  //         newField.type !== "separator"
  //           ? groupFields[groupIndex].splice(subGroupIndex + 1, 0, newField)
  //           : groupFields.splice(groupIndex, 0, [newField]);
  //       }
  //     };
  //     const addFieldToTabGroup = (tabGroupFields, tabGroupFieldIndex) => {
  //       if (tabGroupRowIndex === null) {
  //         if (tabGroupFields) {
  //           tabGroupFields.push([newField]);
  //         }
  //       } else if (tabGroupFieldIndex === null) {
  //         tabGroupFields.splice(tabGroupRowIndex, 0, [newField]);
  //       } else {
  //         newField.type !== "separator"
  //           ? tabGroupFields[tabGroupRowIndex]?.splice(
  //               tabGroupFieldIndex + 1,
  //               0,
  //               newField
  //             )
  //           : tabGroupFields?.splice(tabGroupRowIndex, 0, [newField]);
  //       }
  //     };

  //     const addFieldToTab = (tabFields, tabFieldIndex) => {
  //       if (newField.type === "group") {
  //         const newGroupField =
  //           newField.fields && newField.fields?.length > 0
  //             ? { ...newField }
  //             : {
  //                 ...newField,
  //                 fields: [],
  //               };
  //         if (tabRowIndex === null) {
  //           tabFields.push([newGroupField]);
  //         } else {
  //           tabFields.splice(tabRowIndex, 0, [newGroupField]);
  //         }
  //       } else {
  //         if (tabRowIndex === null) {
  //           tabFields.push([newField]);
  //         } else {
  //           if (
  //             tabFieldIndex !== null &&
  //             tabFields[tabRowIndex][tabFieldIndex].type === "group"
  //           ) {
  //             addFieldToTabGroup(
  //               tabFields[tabRowIndex][tabFieldIndex].fields,
  //               tabGroupFieldIndex
  //             );
  //           } else if (tabFieldIndex === null) {
  //             tabFields.splice(tabRowIndex, 0, [newField]);
  //           } else {
  //             newField.type !== "separator"
  //               ? tabFields[tabRowIndex].splice(tabFieldIndex + 1, 0, newField)
  //               : tabFields.splice(tabRowIndex, 0, [newField]);
  //           }
  //         }
  //       }
  //     };

  //     if (
  //       newField.type === "group" &&
  //       columnIndex !== null &&
  //       newFormData[rowIndex][columnIndex].type === "tab"
  //     ) {
  //       const activeTab = newFormData[rowIndex][columnIndex].activeTab;
  //       addFieldToTab(
  //         newFormData[rowIndex][columnIndex].tabs[activeTab].content,
  //         tabFieldIndex
  //       );
  //     } else if (newField.type === "group") {
  //       const newGroupField =
  //         newField.fields && newField.fields?.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               fields: [],
  //             };

  //       if (rowIndex === null) {
  //         newFormData.push([newGroupField]);
  //       } else {
  //         let isChatter = null;
  //         if (prevFormData?.length > 0) {
  //           isChatter =
  //             prevFormData[prevFormData.length - 1][0].type === "chatter";
  //         }
  //         if (isChatter && rowIndex === prevFormData.length) {
  //           newFormData.splice(rowIndex - 1, 0, [newGroupField]);
  //         } else {
  //           newFormData.splice(rowIndex, 0, [newGroupField]);
  //         }
  //       }
  //     } else if (newField.type === "tab") {
  //       const newTabField =
  //         newField.tabs && newField.tabs.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               tabs: [{ tabName: "Tab 1", content: [] }],
  //               activeTab: 0,
  //             };
  //       if (rowIndex === null) {
  //         newFormData.push([newTabField]);
  //       } else {
  //         const isChatter =
  //           prevFormData[prevFormData.length - 1][0].type === "chatter";
  //         if (isChatter && rowIndex === prevFormData.length) {
  //           newFormData.splice(rowIndex - 1, 0, [newTabField]);
  //         } else {
  //           newFormData.splice(rowIndex, 0, [newTabField]);
  //         }
  //       }
  //     } else if (newField.type === "remark_history") {
  //       // const oldRemark = newField.tabs[0].content[0].find()
  //       const remarkObj = {
  //         tabName: "Remark History",
  //         content: [
  //           [
  //             {
  //               title: "Decision",
  //               technicalName: "x_decision",
  //               type: "selection",
  //               widget: "remark_field",
  //               options: [],
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: null,
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //             },
  //           ],
  //           [
  //             {
  //               title: "Remark",
  //               technicalName: "x_remark",
  //               type: "text",
  //               widget: "remark_field",
  //               options: null,
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: null,
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //             },
  //           ],
  //           [
  //             {
  //               type: "separator",
  //               title: "Separator",
  //               widget: "separator",
  //               defaultValue: "",
  //               technicalName: "x_o2b_",
  //               placeholder: "",
  //               tooltip: "",
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //             },
  //           ],
  //           [
  //             {
  //               title: "Remark Table",
  //               technicalName: "x_o2b_remark_table",
  //               type: "table",
  //               widget: "table",
  //               options: null,
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: [
  //                 {
  //                   title: "Activity",
  //                   technicalName: "current_stage",
  //                   type: "char",
  //                 },
  //                 {
  //                   title: "Decision",
  //                   technicalName: "decision",
  //                   type: "char",
  //                 },
  //                 {
  //                   title: "User",
  //                   technicalName: "remark_uid",
  //                   type: "many2one",
  //                   relatedModel: "res.users",
  //                 },
  //                 {
  //                   title: "Created Date",
  //                   technicalName: "create_date",
  //                   type: "date",
  //                 },
  //                 {
  //                   title: "Remark",
  //                   technicalName: "remark",
  //                   type: "char",
  //                 },
  //               ],
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //               tableRelatedField: `x_o2b_${processDetail.process_name
  //                 .trim()
  //                 .toLowerCase()
  //                 .replace(/ /g, "_")}_remark_history`,
  //               tableType: "new",
  //             },
  //           ],
  //         ],
  //       };
  //       const newTabField =
  //         newField.tabs && newField.tabs.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               tabs: [{ ...remarkObj }],
  //               activeTab: 0,
  //             };
  //       if (rowIndex === null) {
  //         newFormData.push([newTabField]);
  //       } else {
  //         const isChatter =
  //           prevFormData[prevFormData.length - 1][0].type === "chatter";
  //         if (isChatter && rowIndex === prevFormData.length) {
  //           newFormData.splice(rowIndex - 1, 0, [newTabField]);
  //         } else {
  //           newFormData.splice(rowIndex, 0, [newTabField]);
  //         }
  //       }
  //     } else if (newField.type === "chatter") {
  //       let isChatterAlready = null;
  //       isChatterAlready = prevFormData.find(
  //         (field) => field[0].type === "chatter"
  //       );
  //       if (isChatterAlready === null || isChatterAlready === undefined) {
  //         newFormData.push([newField]);
  //       } else {
  //         centerAlert("warning", "Chatter already exist.");
  //         // return;
  //       }
  //     } else if (newField.type === "ribbon") {
  //       let isRibbonAlready = null;
  //       isRibbonAlready = prevFormData.find(
  //         (field) => field[0].type === "ribbon"
  //       );
  //       if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //         newFormData.unshift([newField]);
  //       } else {
  //         centerAlert("warning", "Ribbon already exist.");
  //       }
  //     } else if (newField.type === "header-button") {
  //       const index = findHeaderButtonIndex(prevFormData);
  //       if (
  //         index > -1 &&
  //         prevFormData[index]?.some((btn) => btn.widget === "email-button")
  //       ) {
  //         centerAlert("warning", "Send Email button already exist.");
  //       } else {
  //         const isRibbonAlready = prevFormData.find(
  //           (field) => field[0].type === "ribbon"
  //         );
  //         if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //           if (index !== -1) {
  //             newFormData[index]?.push(newField);
  //           } else {
  //             newFormData.unshift([newField]);
  //           }
  //         } else {
  //           if (index !== -1) {
  //             newFormData[index]?.push(newField);
  //           } else {
  //             newFormData.splice(1, 0, [newField]);
  //           }
  //         }
  //       }
  //     } else if (
  //       newField.type === "object" &&
  //       ["default-statinfo", "statinfo"].includes(newField.widget)
  //     ) {
  //       const findSmartButtonIndex = (arrays) => {
  //         for (let outerIndex = 0; outerIndex < arrays.length; outerIndex++) {
  //           const innerArray = arrays[outerIndex];
  //           const found = innerArray.some(
  //             (obj) =>
  //               obj.type === "object" &&
  //               ["default-statinfo", "statinfo"].includes(obj.widget)
  //           );
  //           if (found) {
  //             return outerIndex;
  //           }
  //         }
  //         return -1;
  //       };
  //       const index = findSmartButtonIndex(prevFormData);
  //       // console.log("smart button index:::: ", index);
  //       let isRibbonAlready = null;
  //       isRibbonAlready = prevFormData.find(
  //         (field) => field[0].type === "ribbon"
  //       );
  //       const isHeaderButtonAlready = prevFormData.find(
  //         (field) => field[0].type === "header-button"
  //       );
  //       if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //         if (
  //           isHeaderButtonAlready === null ||
  //           isHeaderButtonAlready === undefined
  //         ) {
  //           if (index !== -1) {
  //             newFormData[index]?.push(newField);
  //           } else {
  //             newFormData.unshift([newField]);
  //           }
  //         } else {
  //           if (index !== -1) {
  //             newFormData[index]?.push(newField);
  //           } else {
  //             newFormData.splice(1, 0, [newField]);
  //           }
  //         }
  //       } else {
  //         if (
  //           isHeaderButtonAlready === null ||
  //           isHeaderButtonAlready === undefined
  //         ) {
  //           if (index !== -1) {
  //             newFormData[index]?.push(newField);
  //           } else {
  //             newFormData.splice(1, 0, [newField]);
  //           }
  //         } else {
  //           if (index !== -1) {
  //             newFormData[index]?.push(newField);
  //           } else {
  //             newFormData.splice(2, 0, [newField]);
  //           }
  //         }
  //       }
  //     } else {
  //       if (rowIndex === null) {
  //         let isChatter = false;
  //         if (prevFormData?.length > 0) {
  //           isChatter =
  //             prevFormData[prevFormData?.length - 1][0].type === "chatter";
  //         }
  //         if (isChatter) {
  //           newFormData.splice(prevFormData.length - 1, 0, [newField]);
  //         } else {
  //           newFormData.push([newField]);
  //         }
  //       } else {
  //         // if (newFormData[rowIndex] === undefined) {
  //         //   newFormData?.push([newField]);
  //         // } else
  //         if (
  //           columnIndex !== null &&
  //           newFormData[rowIndex][columnIndex]?.type === "group"
  //         ) {
  //           addFieldToGroup(
  //             newFormData[rowIndex][columnIndex].fields,
  //             subGroupIndex
  //           );
  //         } else if (
  //           columnIndex !== null &&
  //           newFormData[rowIndex][columnIndex]?.type === "tab"
  //         ) {
  //           const activeTab = newFormData[rowIndex][columnIndex].activeTab;
  //           addFieldToTab(
  //             newFormData[rowIndex][columnIndex].tabs[activeTab].content,
  //             tabFieldIndex
  //           );
  //         } else if (
  //           columnIndex !== null &&
  //           [
  //             "separator",
  //             "remark_history",
  //             "chatter",
  //             "ribbon",
  //             "chatter",
  //             "object",
  //             "statinfo",
  //             "default-statinfo",
  //           ].includes(newFormData[rowIndex][columnIndex]?.type)
  //         ) {
  //           return newFormData;
  //         } else if (columnIndex === null) {
  //           const isRibbon = newFormData[rowIndex]?.some(
  //             (col) => col.type === "ribbon"
  //           );
  //           const isSmartButton = newFormData[rowIndex]?.some(
  //             (col) =>
  //               col.type === "object" &&
  //               ["default-statinfo", "statinfo"].includes(col.widget)
  //           );

  //           if (isRibbon || isSmartButton) {
  //             return newFormData;
  //           } else {
  //             let isChatter;
  //             if (prevFormData?.length > 0) {
  //               isChatter =
  //                 prevFormData[prevFormData?.length - 1][0].type === "chatter";
  //             }
  //             if (isChatter && rowIndex === prevFormData.length) {
  //               newFormData.splice(rowIndex - 1, 0, [newField]);
  //             } else {
  //               newFormData.splice(rowIndex, 0, [newField]);
  //             }
  //           }
  //         } else {
  //           newField.type !== "separator"
  //             ? newFormData[rowIndex].splice(columnIndex + 1, 0, newField)
  //             : newFormData.splice(rowIndex, 0, [newField]);
  //         }
  //       }
  //     }

  //     setDraggedOverIndex(null);
  //     // setFormData(newFormData);
  //     return newFormData;
  //   });
  // };
  // new method for drop field ==================================================================================
  const FIELD_TYPES = {
    GROUP: "group",
    TAB: "tab",
    CHATTER: "chatter",
    RIBBON: "ribbon",
    SEPARATOR: "separator",
    REMARK_HISTORY: "remark_history",
    HEADER_BUTTON: "header-button",
    OBJECT: "object",
    STATINFO: "statinfo",
    DEFAULT_STATINFO: "default-statinfo",
  };

  const WIDGET_TYPES = {
    EMAIL_BUTTON: "email-button",
    DEFAULT_STATINFO: "default-statinfo",
    STATINFO: "statinfo",
  };

  const handleDrop = (
    e,
    rowIndex = null,
    columnIndex = null,
    groupIndex = null,
    subGroupIndex = null,
    tabIndex = null,
    tabRowIndex = null,
    tabFieldIndex = null,
    tabGroupRowIndex = null,
    tabGroupFieldIndex = null,
    sourceIndex = null
  ) => {
    e.preventDefault();
    e.stopPropagation();

    let fieldData;
    let sourceIndices;

    try {
      const droppedData = JSON.parse(e.dataTransfer.getData("text/plain"));
      fieldData = droppedData.field ? droppedData.field : droppedData;
      sourceIndices = droppedData.sourceIndex;
    } catch (error) {
      console.error("Invalid drop data:", error);
      return;
    }

    const newField =
      fieldData?.widget === "res_partner_many2one"
        ? { ...fieldData, relatedModel: "res.partner" }
        : { ...fieldData };

    if (sourceIndices !== null && sourceIndices !== undefined) {
      setFormData((prevFormData) =>
        handleDeleteSourceField(sourceIndices, prevFormData)
      );
    }

    if (sourceIndices === null || sourceIndices === undefined) {
      if (newField.type === "selection" || newField.type === "table") {
        setModalData({
          rowIndex,
          columnIndex,
          groupIndex,
          subGroupIndex,
          newField,
          tabIndex,
          tabRowIndex,
          tabFieldIndex,
          tabGroupRowIndex,
          tabGroupFieldIndex,
        });
        setShowModal(newField.type === "selection");
        setShowModal2(newField.type === "table");
        return;
      }
      // if (newField.type === "selection") {
      //   setModalData({
      //     rowIndex,
      //     columnIndex,
      //     groupIndex,
      //     subGroupIndex,
      //     newField,
      //     tabIndex,
      //     tabRowIndex,
      //     tabFieldIndex,
      //     tabGroupRowIndex,
      //     tabGroupFieldIndex,
      //   });
      //   setShowModal(true);
      //   return;
      // } else if (newField.type === "table") {
      //   setModalData({
      //     rowIndex,
      //     columnIndex,
      //     groupIndex,
      //     subGroupIndex,
      //     newField,
      //     tabIndex,
      //     tabRowIndex,
      //     tabFieldIndex,
      //     tabGroupRowIndex,
      //     tabGroupFieldIndex,
      //   });
      //   setShowModal2(true);
      //   return;
      // }
    }

    addField(newField, {
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });
  };

  const addField = (newField, indices) => {
    const {
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    } = indices;

    setFormData((prevFormData) => {
      let newFormData = Array.isArray(prevFormData)
        ? JSON.parse(JSON.stringify(prevFormData))
        : [];

      const addFieldToGroup = (groupFields, subGroupIndex) => {
        if (groupIndex === null) {
          groupFields?.push([newField]);
        } else if (subGroupIndex === null) {
          groupFields.splice(groupIndex, 0, [newField]);
        } else {
          newField.type !== FIELD_TYPES.SEPARATOR
            ? groupFields[groupIndex].splice(subGroupIndex + 1, 0, newField)
            : groupFields.splice(groupIndex, 0, [newField]);
        }
      };

      const addFieldToTabGroup = (tabGroupFields, tabGroupFieldIndex) => {
        if (tabGroupRowIndex === null) {
          tabGroupFields?.push([newField]);
        } else if (tabGroupFieldIndex === null) {
          tabGroupFields.splice(tabGroupRowIndex, 0, [newField]);
        } else {
          newField.type !== FIELD_TYPES.SEPARATOR
            ? tabGroupFields[tabGroupRowIndex]?.splice(
                tabGroupFieldIndex + 1,
                0,
                newField
              )
            : tabGroupFields?.splice(tabGroupRowIndex, 0, [newField]);
        }
      };

      const addFieldToTab = (tabFields, tabFieldIndex) => {
        if (newField.type === FIELD_TYPES.GROUP) {
          const newGroupField =
            newField.fields?.length > 0
              ? { ...newField }
              : { ...newField, fields: [] };
          tabRowIndex === null
            ? tabFields.push([newGroupField])
            : tabFields.splice(tabRowIndex, 0, [newGroupField]);
        } else {
          if (tabRowIndex === null) {
            tabFields.push([newField]);
          } else {
            if (
              tabFieldIndex !== null &&
              tabFields[tabRowIndex][tabFieldIndex].type === FIELD_TYPES.GROUP
            ) {
              addFieldToTabGroup(
                tabFields[tabRowIndex][tabFieldIndex].fields,
                tabGroupFieldIndex
              );
            } else if (tabFieldIndex === null) {
              tabFields.splice(tabRowIndex, 0, [newField]);
            } else {
              newField.type !== FIELD_TYPES.SEPARATOR
                ? tabFields[tabRowIndex].splice(tabFieldIndex + 1, 0, newField)
                : tabFields.splice(tabRowIndex, 0, [newField]);
            }
          }
        }
      };

      // Handle different field types
      switch (newField.type) {
        case FIELD_TYPES.GROUP:
          handleGroupField(newField, newFormData, indices, addFieldToTab);
          break;
        case FIELD_TYPES.TAB:
          handleTabField(newField, newFormData, indices);
          break;
        case FIELD_TYPES.REMARK_HISTORY:
          handleRemarkHistoryField(newField, newFormData, indices);
          break;
        case FIELD_TYPES.CHATTER:
          handleChatterField(newField, newFormData);
          break;
        case FIELD_TYPES.RIBBON:
          handleRibbonField(newField, newFormData);
          break;
        case FIELD_TYPES.HEADER_BUTTON:
          handleHeaderButtonField(newField, newFormData);
          break;
        case FIELD_TYPES.OBJECT:
          handleObjectField(newField, newFormData);
          break;
        default:
          handleDefaultField(
            newField,
            newFormData,
            indices,
            addFieldToGroup,
            addFieldToTab
          );
          break;
      }

      setDraggedOverIndex(null);
      return newFormData;
    });
  };

  // Helper functions for handling specific field types
  const handleGroupField = (newField, newFormData, indices, addFieldToTab) => {
    const { rowIndex, columnIndex, tabFieldIndex } = indices;
    if (
      columnIndex !== null &&
      newFormData[rowIndex][columnIndex].type === FIELD_TYPES.TAB
    ) {
      const activeTab = newFormData[rowIndex][columnIndex].activeTab;
      addFieldToTab(
        newFormData[rowIndex][columnIndex].tabs[activeTab].content,
        tabFieldIndex
      );
    } else {
      const newGroupField =
        newField.fields?.length > 0
          ? { ...newField }
          : { ...newField, fields: [] };
      insertField(newFormData, newGroupField, rowIndex);
    }
  };

  const handleTabField = (newField, newFormData, indices) => {
    const { rowIndex } = indices;
    const newTabField =
      newField.tabs?.length > 0
        ? { ...newField }
        : {
            ...newField,
            tabs: [{ tabName: "Tab 1", content: [] }],
            activeTab: 0,
          };
    insertField(newFormData, newTabField, rowIndex);
  };

  const handleRemarkHistoryField = (newField, newFormData, indices) => {
    const { rowIndex } = indices;
    const remarkObj = {
      tabName: "Remark History",
      content: [
        [
          {
            title: "Decision",
            technicalName: "x_decision",
            type: "selection",
            widget: "remark_field",
            options: [],
            odooButtonColor: "btn-outline-dark",
            placeholder: "",
            tooltip: "",
            pythonCode: null,
            isRequired: false,
            isReadOnly: false,
            isTracking: false,
            isCreatedBy: false,
            isOnChange: false,
            isInvisible: false,
            defaultValue: "",
            columns: null,
            groupVisible: [],
            relatedModel: null,
            relationTable: null,
            relationField: null,
          },
        ],
        [
          {
            title: "Remark",
            technicalName: "x_remark",
            type: "text",
            widget: "remark_field",
            options: null,
            odooButtonColor: "btn-outline-dark",
            placeholder: "",
            tooltip: "",
            pythonCode: null,
            isRequired: false,
            isReadOnly: false,
            isTracking: false,
            isCreatedBy: false,
            isOnChange: false,
            isInvisible: false,
            defaultValue: "",
            columns: null,
            groupVisible: [],
            relatedModel: null,
            relationTable: null,
            relationField: null,
          },
        ],
        [
          {
            type: "separator",
            title: "Separator",
            widget: "separator",
            defaultValue: "",
            technicalName: "x_o2b_",
            placeholder: "",
            tooltip: "",
            isRequired: false,
            isReadOnly: false,
            isTracking: false,
            isOnChange: false,
            isInvisible: false,
          },
        ],
        [
          {
            title: "Remark Table",
            technicalName: "x_o2b_remark_table",
            type: "table",
            widget: "table",
            options: null,
            odooButtonColor: "btn-outline-dark",
            placeholder: "",
            tooltip: "",
            pythonCode: null,
            isRequired: false,
            isReadOnly: false,
            isTracking: false,
            isCreatedBy: false,
            isOnChange: false,
            isInvisible: false,
            defaultValue: "",
            columns: [
              {
                title: "Activity",
                technicalName: "current_stage",
                type: "char",
              },
              {
                title: "Decision",
                technicalName: "decision",
                type: "char",
              },
              {
                title: "User",
                technicalName: "remark_uid",
                type: "many2one",
                relatedModel: "res.users",
              },
              {
                title: "Created Date",
                technicalName: "create_date",
                type: "date",
              },
              {
                title: "Remark",
                technicalName: "remark",
                type: "char",
              },
            ],
            groupVisible: [],
            relatedModel: null,
            relationTable: null,
            relationField: null,
            tableRelatedField: `x_o2b_${processDetail.process_name
              .trim()
              .toLowerCase()
              .replace(/ /g, "_")}_remark_history`,
            tableType: "new",
          },
        ],
      ],
    };
    const newTabField =
      newField.tabs?.length > 0
        ? { ...newField }
        : { ...newField, tabs: [{ ...remarkObj }], activeTab: 0 };
    insertField(newFormData, newTabField, rowIndex);
  };

  const handleChatterField = (newField, newFormData) => {
    const isChatterAlready = newFormData.some(
      (field) => field[0].type === FIELD_TYPES.CHATTER
    );
    if (!isChatterAlready) {
      newFormData.push([newField]);
    } else {
      centerAlert("warning", "Chatter already exist.");
    }
  };

  const handleRibbonField = (newField, newFormData) => {
    const isRibbonAlready = newFormData.some(
      (field) => field[0].type === FIELD_TYPES.RIBBON
    );
    if (!isRibbonAlready) {
      newFormData.unshift([newField]);
    } else {
      centerAlert("warning", "Ribbon already exist.");
    }
  };

  const handleHeaderButtonField = (newField, newFormData) => {
    const index = findHeaderButtonIndex(newFormData);
    if (
      index > -1 &&
      newFormData[index]?.some(
        (btn) => btn.widget === WIDGET_TYPES.EMAIL_BUTTON
      )
    ) {
      centerAlert("warning", "Send Email button already exist.");
    } else {
      const isRibbonAlready = newFormData.some(
        (field) => field[0].type === FIELD_TYPES.RIBBON
      );
      if (isRibbonAlready) {
        if (index !== -1) {
          newFormData[index]?.push(newField);
        } else {
          newFormData.splice(1, 0, [newField]);
        }
      } else {
        if (index !== -1) {
          newFormData[index]?.push(newField);
        } else {
          newFormData.unshift([newField]);
        }
      }
    }
  };

  const handleObjectField = (newField, newFormData) => {
    const index = findSmartButtonIndex(newFormData);
    const isRibbonAlready = newFormData.some(
      (field) => field[0].type === FIELD_TYPES.RIBBON
    );
    const isHeaderButtonAlready = newFormData.some(
      (field) => field[0].type === FIELD_TYPES.HEADER_BUTTON
    );
    if (isRibbonAlready) {
      if (isHeaderButtonAlready) {
        if (index !== -1) {
          newFormData[index]?.push(newField);
        } else {
          newFormData.splice(2, 0, [newField]);
        }
      } else {
        if (index !== -1) {
          newFormData[index]?.push(newField);
        } else {
          newFormData.splice(1, 0, [newField]);
        }
      }
    } else {
      if (isHeaderButtonAlready) {
        if (index !== -1) {
          newFormData[index]?.push(newField);
        } else {
          newFormData.splice(1, 0, [newField]);
        }
      } else {
        if (index !== -1) {
          newFormData[index]?.push(newField);
        } else {
          newFormData.unshift([newField]);
        }
      }
    }
  };

  const handleDefaultField = (
    newField,
    newFormData,
    indices,
    addFieldToGroup,
    addFieldToTab
  ) => {
    const { rowIndex, columnIndex, subGroupIndex, tabFieldIndex } = indices;
    if (rowIndex === null) {
      const isChatter =
        newFormData[newFormData.length - 1]?.[0].type === FIELD_TYPES.CHATTER;
      if (isChatter) {
        newFormData.splice(newFormData.length - 1, 0, [newField]);
      } else {
        newFormData.push([newField]);
      }
    } else {
      if (
        columnIndex !== null &&
        newFormData[rowIndex][columnIndex]?.type === FIELD_TYPES.GROUP
      ) {
        addFieldToGroup(
          newFormData[rowIndex][columnIndex].fields,
          subGroupIndex
        );
      } else if (
        columnIndex !== null &&
        newFormData[rowIndex][columnIndex]?.type === FIELD_TYPES.TAB
      ) {
        const activeTab = newFormData[rowIndex][columnIndex].activeTab;
        addFieldToTab(
          newFormData[rowIndex][columnIndex].tabs[activeTab].content,
          tabFieldIndex
        );
      } else if (columnIndex === null) {
        const isRibbon = newFormData[rowIndex]?.some(
          (col) => col.type === FIELD_TYPES.RIBBON
        );
        const isSmartButton = newFormData[rowIndex]?.some(
          (col) =>
            col.type === FIELD_TYPES.OBJECT &&
            [WIDGET_TYPES.DEFAULT_STATINFO, WIDGET_TYPES.STATINFO].includes(
              col.widget
            )
        );
        if (!isRibbon && !isSmartButton) {
          const isChatter =
            newFormData[newFormData.length - 1]?.[0].type ===
            FIELD_TYPES.CHATTER;
          if (isChatter && rowIndex === newFormData.length) {
            newFormData.splice(rowIndex - 1, 0, [newField]);
          } else {
            newFormData.splice(rowIndex, 0, [newField]);
          }
        }
      } else {
        newField.type !== FIELD_TYPES.SEPARATOR
          ? newFormData[rowIndex].splice(columnIndex + 1, 0, newField)
          : newFormData.splice(rowIndex, 0, [newField]);
      }
    }
  };

  const insertField = (newFormData, newField, rowIndex) => {
    if (rowIndex === null) {
      newFormData.push([newField]);
    } else {
      const isChatter =
        newFormData[newFormData.length - 1]?.[0].type === FIELD_TYPES.CHATTER;
      if (isChatter && rowIndex === newFormData.length) {
        newFormData.splice(rowIndex - 1, 0, [newField]);
      } else {
        newFormData.splice(rowIndex, 0, [newField]);
      }
    }
  };

  // const findHeaderButtonIndex = (arrays) => {
  //   return arrays.findIndex(innerArray => innerArray.some(obj => obj.type === FIELD_TYPES.HEADER_BUTTON));
  // };

  const findSmartButtonIndex = (arrays) => {
    return arrays.findIndex((innerArray) =>
      innerArray.some(
        (obj) =>
          obj.type === FIELD_TYPES.OBJECT &&
          [WIDGET_TYPES.DEFAULT_STATINFO, WIDGET_TYPES.STATINFO].includes(
            obj.widget
          )
      )
    );
  };
  // new method code over for drop field ==================================================================================

  // drop method for todos form builder
  // const handleTodosDrop = (
  //   e,
  //   rowIndex = null,
  //   columnIndex = null,
  //   groupIndex = null,
  //   subGroupIndex = null,
  //   tabIndex = null,
  //   tabRowIndex = null,
  //   tabFieldIndex = null,
  //   tabGroupRowIndex = null,
  //   tabGroupFieldIndex = null,
  //   sourceIndex = null
  // ) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   let fieldData;
  //   let sourceIndices;

  //   try {
  //     const droppedData = JSON.parse(e.dataTransfer.getData("text/plain"));
  //     fieldData = droppedData.field ? droppedData.field : droppedData;
  //     sourceIndices = droppedData.sourceIndex;
  //   } catch (error) {
  //     console.error("Invalid drop data:", error);
  //     return;
  //   }

  //   let newField;
  //   if (fieldData?.widget === "res_partner_many2one") {
  //     newField = { ...fieldData, relatedModel: "res.partner" };
  //   } else {
  //     newField = { ...fieldData };
  //   }

  //   if (sourceIndices !== null && sourceIndices !== undefined) {
  //     setTodosData((prevTodosData) => {
  //       // First, remove the source field
  //       const newFormData = handleDeleteSourceField(
  //         sourceIndices,
  //         prevTodosData
  //       );
  //       return newFormData;
  //     });
  //   }

  //   if (sourceIndices === null || sourceIndices === undefined) {
  //     if (newField.type === "selection") {
  //       setModalData({
  //         rowIndex,
  //         columnIndex,
  //         groupIndex,
  //         subGroupIndex,
  //         newField,
  //         tabIndex,
  //         tabRowIndex,
  //         tabFieldIndex,
  //         tabGroupRowIndex,
  //         tabGroupFieldIndex,
  //       });
  //       setShowModal(true);
  //       return;
  //     } else if (newField.type === "table") {
  //       setModalData({
  //         rowIndex,
  //         columnIndex,
  //         groupIndex,
  //         subGroupIndex,
  //         newField,
  //         tabIndex,
  //         tabRowIndex,
  //         tabFieldIndex,
  //         tabGroupRowIndex,
  //         tabGroupFieldIndex,
  //       });
  //       setShowModal2(true);
  //       return;
  //     }
  //   }

  //   addTodosField(
  //     newField,
  //     rowIndex,
  //     columnIndex,
  //     groupIndex,
  //     subGroupIndex,
  //     tabIndex,
  //     tabRowIndex,
  //     tabFieldIndex,
  //     tabGroupRowIndex,
  //     tabGroupFieldIndex
  //   );
  // };
  // add todos field method to add in todosData
  // const addTodosField = (
  //   newField,
  //   rowIndex,
  //   columnIndex,
  //   groupIndex,
  //   subGroupIndex,
  //   tabIndex,
  //   tabRowIndex,
  //   tabFieldIndex,
  //   tabGroupRowIndex,
  //   tabGroupFieldIndex
  // ) => {
  //   // console.log("todos data: ", prevTodosData);
  //   if (!Array.isArray(todosData)) {
  //     setTodosData([]);
  //   }
  //   setTodosData((prevTodosData) => {
  //     let newTodosData =
  //       prevTodosData?.length > 0
  //         ? JSON.parse(JSON.stringify(prevTodosData))
  //         : [];
  //     // let newTodosData = prevTodosData?.length > 0 ? [...prevTodosData] : [];
  //     const addFieldToGroup = (groupFields, subGroupIndex) => {
  //       if (groupIndex === null) {
  //         if (groupFields) {
  //           groupFields.push([newField]);
  //         }
  //       } else if (subGroupIndex === null) {
  //         groupFields.splice(groupIndex, 0, [newField]);
  //       } else {
  //         newField.type !== "separator"
  //           ? groupFields[groupIndex].splice(subGroupIndex + 1, 0, newField)
  //           : groupFields.splice(groupIndex, 0, [newField]);
  //       }
  //     };
  //     const addFieldToTabGroup = (tabGroupFields, tabGroupFieldIndex) => {
  //       if (tabGroupRowIndex === null) {
  //         if (tabGroupFields) {
  //           tabGroupFields.push([newField]);
  //         }
  //       } else if (tabGroupFieldIndex === null) {
  //         tabGroupFields.splice(tabGroupRowIndex, 0, [newField]);
  //       } else {
  //         newField.type !== "separator"
  //           ? tabGroupFields[tabGroupRowIndex]?.splice(
  //               tabGroupFieldIndex + 1,
  //               0,
  //               newField
  //             )
  //           : tabGroupFields?.splice(tabGroupRowIndex, 0, [newField]);
  //       }
  //     };

  //     const addFieldToTab = (tabFields, tabFieldIndex) => {
  //       if (newField.type === "group") {
  //         const newGroupField =
  //           newField.fields && newField.fields?.length > 0
  //             ? { ...newField }
  //             : {
  //                 ...newField,
  //                 fields: [],
  //               };
  //         if (tabRowIndex === null) {
  //           tabFields.push([newGroupField]);
  //         } else {
  //           tabFields.splice(tabRowIndex, 0, [newGroupField]);
  //         }
  //       } else {
  //         if (tabRowIndex === null) {
  //           tabFields.push([newField]);
  //         } else {
  //           if (
  //             tabFieldIndex !== null &&
  //             tabFields[tabRowIndex][tabFieldIndex].type === "group"
  //           ) {
  //             addFieldToTabGroup(
  //               tabFields[tabRowIndex][tabFieldIndex].fields,
  //               tabGroupFieldIndex
  //             );
  //           } else if (tabFieldIndex === null) {
  //             tabFields.splice(tabRowIndex, 0, [newField]);
  //           } else {
  //             newField.type !== "separator"
  //               ? tabFields[tabRowIndex].splice(tabFieldIndex + 1, 0, newField)
  //               : tabFields.splice(tabRowIndex, 0, [newField]);
  //           }
  //         }
  //       }
  //     };

  //     if (
  //       newField.type === "group" &&
  //       columnIndex !== null &&
  //       newTodosData[rowIndex][columnIndex].type === "tab"
  //     ) {
  //       const activeTab = newTodosData[rowIndex][columnIndex].activeTab;
  //       addFieldToTab(
  //         newTodosData[rowIndex][columnIndex].tabs[activeTab].content,
  //         tabFieldIndex
  //       );
  //     } else if (newField.type === "group") {
  //       const newGroupField =
  //         newField.fields && newField.fields?.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               fields: [],
  //             };

  //       if (rowIndex === null) {
  //         newTodosData.push([newGroupField]);
  //       } else {
  //         let isChatter = null;
  //         if (prevTodosData?.length > 0) {
  //           isChatter =
  //             prevTodosData[prevTodosData.length - 1][0].type === "chatter";
  //         }
  //         if (isChatter && rowIndex === prevTodosData.length) {
  //           newTodosData.splice(rowIndex - 1, 0, [newGroupField]);
  //         } else {
  //           newTodosData.splice(rowIndex, 0, [newGroupField]);
  //         }
  //       }
  //     } else if (newField.type === "tab") {
  //       const newTabField =
  //         newField.tabs && newField.tabs.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               tabs: [{ tabName: "Tab 1", content: [] }],
  //               activeTab: 0,
  //             };
  //       if (rowIndex === null) {
  //         newTodosData.push([newTabField]);
  //       } else {
  //         const isChatter =
  //           prevTodosData[prevTodosData.length - 1][0].type === "chatter";
  //         if (isChatter && rowIndex === prevTodosData.length) {
  //           newTodosData.splice(rowIndex - 1, 0, [newTabField]);
  //         } else {
  //           newTodosData.splice(rowIndex, 0, [newTabField]);
  //         }
  //       }
  //     } else if (newField.type === "remark_history") {
  //       // const oldRemark = newField.tabs[0].content[0].find()
  //       const remarkObj = {
  //         tabName: "Remark History",
  //         content: [
  //           [
  //             {
  //               title: "Decision",
  //               technicalName: "x_decision",
  //               type: "selection",
  //               widget: "remark_field",
  //               options: [],
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: null,
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //             },
  //           ],
  //           [
  //             {
  //               title: "Remark",
  //               technicalName: "x_remark",
  //               type: "text",
  //               widget: "remark_field",
  //               options: null,
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: null,
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //             },
  //           ],
  //           [
  //             {
  //               type: "separator",
  //               title: "Separator",
  //               widget: "separator",
  //               defaultValue: "",
  //               technicalName: "x_o2b_",
  //               placeholder: "",
  //               tooltip: "",
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //             },
  //           ],
  //           [
  //             {
  //               title: "Remark Table",
  //               technicalName: "x_o2b_remark_table",
  //               type: "table",
  //               widget: "table",
  //               options: null,
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: [
  //                 {
  //                   title: "Activity",
  //                   technicalName: "current_stage",
  //                   type: "char",
  //                 },
  //                 {
  //                   title: "Decision",
  //                   technicalName: "decision",
  //                   type: "char",
  //                 },
  //                 {
  //                   title: "User",
  //                   technicalName: "remark_uid",
  //                   type: "many2one",
  //                   relatedModel: "res.users",
  //                 },
  //                 {
  //                   title: "Created Date",
  //                   technicalName: "create_date",
  //                   type: "date",
  //                 },
  //                 {
  //                   title: "Remark",
  //                   technicalName: "remark",
  //                   type: "char",
  //                 },
  //               ],
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //               tableRelatedField: `x_o2b_${processDetail.process_name
  //                 .trim()
  //                 .toLowerCase()
  //                 .replace(/ /g, "_")}_remark_history`,
  //               tableType: "new",
  //             },
  //           ],
  //         ],
  //       };
  //       const newTabField =
  //         newField.tabs && newField.tabs.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               tabs: [{ ...remarkObj }],
  //               activeTab: 0,
  //             };
  //       if (rowIndex === null) {
  //         newTodosData.push([newTabField]);
  //       } else {
  //         const isChatter =
  //           prevTodosData[prevTodosData.length - 1][0].type === "chatter";
  //         if (isChatter && rowIndex === prevTodosData.length) {
  //           newTodosData.splice(rowIndex - 1, 0, [newTabField]);
  //         } else {
  //           newTodosData.splice(rowIndex, 0, [newTabField]);
  //         }
  //       }
  //     } else if (newField.type === "chatter") {
  //       let isChatterAlready = null;
  //       isChatterAlready = prevTodosData.find(
  //         (field) => field[0].type === "chatter"
  //       );
  //       if (isChatterAlready === null || isChatterAlready === undefined) {
  //         newTodosData.push([newField]);
  //       } else {
  //         return;
  //       }
  //     } else if (newField.type === "ribbon") {
  //       let isRibbonAlready = null;
  //       isRibbonAlready = prevTodosData.find(
  //         (field) => field[0].type === "ribbon"
  //       );
  //       if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //         newTodosData.unshift([newField]);
  //       } else {
  //         return;
  //       }
  //     } else if (
  //       newField.type === "object" &&
  //       ["default-statinfo", "statinfo"].includes(newField.widget)
  //     ) {
  //       const findSmartButtonIndex = (arrays) => {
  //         for (let outerIndex = 0; outerIndex < arrays.length; outerIndex++) {
  //           const innerArray = arrays[outerIndex];
  //           const found = innerArray.some(
  //             (obj) =>
  //               obj.type === "object" &&
  //               ["default-statinfo", "statinfo"].includes(obj.widget)
  //           );
  //           if (found) {
  //             return outerIndex;
  //           }
  //         }
  //         return -1;
  //       };
  //       const index = findSmartButtonIndex(prevTodosData);
  //       // console.log("smart button index:::: ", index);
  //       let isRibbonAlready = null;
  //       isRibbonAlready = prevTodosData.find(
  //         (field) => field[0].type === "ribbon"
  //       );
  //       if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //         if (index !== -1) {
  //           newTodosData[index]?.push(newField);
  //         } else {
  //           newTodosData.unshift([newField]);
  //         }
  //       } else {
  //         if (index !== -1) {
  //           newTodosData[index]?.push(newField);
  //         } else {
  //           newTodosData.splice(1, 0, [newField]);
  //         }
  //       }
  //     } else {
  //       if (rowIndex === null) {
  //         let isChatter = false;
  //         if (prevTodosData?.length > 0) {
  //           isChatter =
  //             prevTodosData[prevTodosData?.length - 1][0].type === "chatter";
  //         }
  //         if (isChatter) {
  //           newTodosData.splice(prevTodosData.length - 1, 0, [newField]);
  //         } else {
  //           newTodosData.push([newField]);
  //         }
  //       } else {
  //         if (
  //           columnIndex !== null &&
  //           newTodosData[rowIndex][columnIndex]?.type === "group"
  //         ) {
  //           addFieldToGroup(
  //             newTodosData[rowIndex][columnIndex].fields,
  //             subGroupIndex
  //           );
  //         } else if (
  //           columnIndex !== null &&
  //           newTodosData[rowIndex][columnIndex]?.type === "tab"
  //         ) {
  //           const activeTab = newTodosData[rowIndex][columnIndex].activeTab;
  //           addFieldToTab(
  //             newTodosData[rowIndex][columnIndex].tabs[activeTab].content,
  //             tabFieldIndex
  //           );
  //         } else if (
  //           columnIndex !== null &&
  //           [
  //             "separator",
  //             "remark_history",
  //             "chatter",
  //             "ribbon",
  //             "chatter",
  //             "object",
  //             "statinfo",
  //             "default-statinfo",
  //           ].includes(newTodosData[rowIndex][columnIndex]?.type)
  //         ) {
  //           return newTodosData;
  //         } else if (columnIndex === null) {
  //           const isRibbon = newTodosData[rowIndex]?.some(
  //             (col) => col.type === "ribbon"
  //           );
  //           const isSmartButton = newTodosData[rowIndex]?.some(
  //             (col) =>
  //               col.type === "object" &&
  //               ["default-statinfo", "statinfo"].includes(col.widget)
  //           );

  //           if (isRibbon || isSmartButton) {
  //             return newTodosData;
  //           } else {
  //             let isChatter;
  //             if (prevTodosData?.length > 0) {
  //               isChatter =
  //                 prevTodosData[prevTodosData?.length - 1][0].type ===
  //                 "chatter";
  //             }
  //             if (isChatter && rowIndex === prevTodosData.length) {
  //               newTodosData.splice(rowIndex - 1, 0, [newField]);
  //             } else {
  //               newTodosData.splice(rowIndex, 0, [newField]);
  //             }
  //           }
  //         } else {
  //           newField.type !== "separator"
  //             ? newTodosData[rowIndex].splice(columnIndex + 1, 0, newField)
  //             : newTodosData.splice(rowIndex, 0, [newField]);
  //         }
  //       }
  //     }

  //     setDraggedOverIndex(null);
  //     // setTodosData(newTodosData);
  //     return newTodosData;
  //   });
  // };

  // drop method for documents form builder
  // const handleDocumentsDrop = (
  //   e,
  //   rowIndex = null,
  //   columnIndex = null,
  //   groupIndex = null,
  //   subGroupIndex = null,
  //   tabIndex = null,
  //   tabRowIndex = null,
  //   tabFieldIndex = null,
  //   tabGroupRowIndex = null,
  //   tabGroupFieldIndex = null,
  //   sourceIndex = null
  // ) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   let fieldData;
  //   let sourceIndices;

  //   try {
  //     const droppedData = JSON.parse(e.dataTransfer.getData("text/plain"));
  //     fieldData = droppedData.field ? droppedData.field : droppedData;
  //     sourceIndices = droppedData.sourceIndex;
  //   } catch (error) {
  //     console.error("Invalid drop data:", error);
  //     return;
  //   }

  //   let newField;
  //   if (fieldData?.widget === "res_partner_many2one") {
  //     newField = { ...fieldData, relatedModel: "res.partner" };
  //   } else {
  //     newField = { ...fieldData };
  //   }

  //   if (sourceIndices !== null && sourceIndices !== undefined) {
  //     setDocumentsData((prevDocumentsData) => {
  //       // First, remove the source field
  //       const newFormData = handleDeleteSourceField(
  //         sourceIndices,
  //         prevDocumentsData
  //       );
  //       return newFormData;
  //     });
  //   }

  //   if (sourceIndices === null || sourceIndices === undefined) {
  //     if (newField.type === "selection") {
  //       setModalData({
  //         rowIndex,
  //         columnIndex,
  //         groupIndex,
  //         subGroupIndex,
  //         newField,
  //         tabIndex,
  //         tabRowIndex,
  //         tabFieldIndex,
  //         tabGroupRowIndex,
  //         tabGroupFieldIndex,
  //       });
  //       setShowModal(true);
  //       return;
  //     } else if (newField.type === "table") {
  //       setModalData({
  //         rowIndex,
  //         columnIndex,
  //         groupIndex,
  //         subGroupIndex,
  //         newField,
  //         tabIndex,
  //         tabRowIndex,
  //         tabFieldIndex,
  //         tabGroupRowIndex,
  //         tabGroupFieldIndex,
  //       });
  //       setShowModal2(true);
  //       return;
  //     }
  //   }

  //   addDocumentsField(
  //     newField,
  //     rowIndex,
  //     columnIndex,
  //     groupIndex,
  //     subGroupIndex,
  //     tabIndex,
  //     tabRowIndex,
  //     tabFieldIndex,
  //     tabGroupRowIndex,
  //     tabGroupFieldIndex
  //   );
  // };
  // add documents field method to add in documentsData
  // const addDocumentsField = (
  //   newField,
  //   rowIndex,
  //   columnIndex,
  //   groupIndex,
  //   subGroupIndex,
  //   tabIndex,
  //   tabRowIndex,
  //   tabFieldIndex,
  //   tabGroupRowIndex,
  //   tabGroupFieldIndex
  // ) => {
  //   // console.log("documents data: ", prevDocumentsData);
  //   if (!Array.isArray(documentsData)) {
  //     setDocumentsData([]);
  //   }
  //   setDocumentsData((prevDocumentsData) => {
  //     let newDocumentsData =
  //       prevDocumentsData?.length > 0
  //         ? JSON.parse(JSON.stringify(prevDocumentsData))
  //         : [];
  //     // let newDocumentsData = prevDocumentsData?.length > 0 ? [...prevDocumentsData] : [];
  //     const addFieldToGroup = (groupFields, subGroupIndex) => {
  //       if (groupIndex === null) {
  //         if (groupFields) {
  //           groupFields.push([newField]);
  //         }
  //       } else if (subGroupIndex === null) {
  //         groupFields.splice(groupIndex, 0, [newField]);
  //       } else {
  //         newField.type !== "separator"
  //           ? groupFields[groupIndex].splice(subGroupIndex + 1, 0, newField)
  //           : groupFields.splice(groupIndex, 0, [newField]);
  //       }
  //     };
  //     const addFieldToTabGroup = (tabGroupFields, tabGroupFieldIndex) => {
  //       if (tabGroupRowIndex === null) {
  //         if (tabGroupFields) {
  //           tabGroupFields.push([newField]);
  //         }
  //       } else if (tabGroupFieldIndex === null) {
  //         tabGroupFields.splice(tabGroupRowIndex, 0, [newField]);
  //       } else {
  //         newField.type !== "separator"
  //           ? tabGroupFields[tabGroupRowIndex]?.splice(
  //               tabGroupFieldIndex + 1,
  //               0,
  //               newField
  //             )
  //           : tabGroupFields?.splice(tabGroupRowIndex, 0, [newField]);
  //       }
  //     };

  //     const addFieldToTab = (tabFields, tabFieldIndex) => {
  //       if (newField.type === "group") {
  //         const newGroupField =
  //           newField.fields && newField.fields?.length > 0
  //             ? { ...newField }
  //             : {
  //                 ...newField,
  //                 fields: [],
  //               };
  //         if (tabRowIndex === null) {
  //           tabFields.push([newGroupField]);
  //         } else {
  //           tabFields.splice(tabRowIndex, 0, [newGroupField]);
  //         }
  //       } else {
  //         if (tabRowIndex === null) {
  //           tabFields.push([newField]);
  //         } else {
  //           if (
  //             tabFieldIndex !== null &&
  //             tabFields[tabRowIndex][tabFieldIndex].type === "group"
  //           ) {
  //             addFieldToTabGroup(
  //               tabFields[tabRowIndex][tabFieldIndex].fields,
  //               tabGroupFieldIndex
  //             );
  //           } else if (tabFieldIndex === null) {
  //             tabFields.splice(tabRowIndex, 0, [newField]);
  //           } else {
  //             newField.type !== "separator"
  //               ? tabFields[tabRowIndex].splice(tabFieldIndex + 1, 0, newField)
  //               : tabFields.splice(tabRowIndex, 0, [newField]);
  //           }
  //         }
  //       }
  //     };

  //     if (
  //       newField.type === "group" &&
  //       columnIndex !== null &&
  //       newDocumentsData[rowIndex][columnIndex].type === "tab"
  //     ) {
  //       const activeTab = newDocumentsData[rowIndex][columnIndex].activeTab;
  //       addFieldToTab(
  //         newDocumentsData[rowIndex][columnIndex].tabs[activeTab].content,
  //         tabFieldIndex
  //       );
  //     } else if (newField.type === "group") {
  //       const newGroupField =
  //         newField.fields && newField.fields?.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               fields: [],
  //             };

  //       if (rowIndex === null) {
  //         newDocumentsData.push([newGroupField]);
  //       } else {
  //         let isChatter = null;
  //         if (prevDocumentsData?.length > 0) {
  //           isChatter =
  //             prevDocumentsData[prevDocumentsData.length - 1][0].type ===
  //             "chatter";
  //         }
  //         if (isChatter && rowIndex === prevDocumentsData.length) {
  //           newDocumentsData.splice(rowIndex - 1, 0, [newGroupField]);
  //         } else {
  //           newDocumentsData.splice(rowIndex, 0, [newGroupField]);
  //         }
  //       }
  //     } else if (newField.type === "tab") {
  //       const newTabField =
  //         newField.tabs && newField.tabs.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               tabs: [{ tabName: "Tab 1", content: [] }],
  //               activeTab: 0,
  //             };
  //       if (rowIndex === null) {
  //         newDocumentsData.push([newTabField]);
  //       } else {
  //         const isChatter =
  //           prevDocumentsData[prevDocumentsData.length - 1][0].type ===
  //           "chatter";
  //         if (isChatter && rowIndex === prevDocumentsData.length) {
  //           newDocumentsData.splice(rowIndex - 1, 0, [newTabField]);
  //         } else {
  //           newDocumentsData.splice(rowIndex, 0, [newTabField]);
  //         }
  //       }
  //     } else if (newField.type === "remark_history") {
  //       // const oldRemark = newField.tabs[0].content[0].find()
  //       const remarkObj = {
  //         tabName: "Remark History",
  //         content: [
  //           [
  //             {
  //               title: "Decision",
  //               technicalName: "x_decision",
  //               type: "selection",
  //               widget: "remark_field",
  //               options: [],
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: null,
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //             },
  //           ],
  //           [
  //             {
  //               title: "Remark",
  //               technicalName: "x_remark",
  //               type: "text",
  //               widget: "remark_field",
  //               options: null,
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: null,
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //             },
  //           ],
  //           [
  //             {
  //               type: "separator",
  //               title: "Separator",
  //               widget: "separator",
  //               defaultValue: "",
  //               technicalName: "x_o2b_",
  //               placeholder: "",
  //               tooltip: "",
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //             },
  //           ],
  //           [
  //             {
  //               title: "Remark Table",
  //               technicalName: "x_o2b_remark_table",
  //               type: "table",
  //               widget: "table",
  //               options: null,
  //               odooButtonColor: "btn-outline-dark",
  //               placeholder: "",
  //               tooltip: "",
  //               pythonCode: null,
  //               isRequired: false,
  //               isReadOnly: false,
  //               isTracking: false,
  //               isCreatedBy: false,
  //               isOnChange: false,
  //               isInvisible: false,
  //               defaultValue: "",
  //               columns: [
  //                 {
  //                   title: "Activity",
  //                   technicalName: "current_stage",
  //                   type: "char",
  //                 },
  //                 {
  //                   title: "Decision",
  //                   technicalName: "decision",
  //                   type: "char",
  //                 },
  //                 {
  //                   title: "User",
  //                   technicalName: "remark_uid",
  //                   type: "many2one",
  //                   relatedModel: "res.users",
  //                 },
  //                 {
  //                   title: "Created Date",
  //                   technicalName: "create_date",
  //                   type: "date",
  //                 },
  //                 {
  //                   title: "Remark",
  //                   technicalName: "remark",
  //                   type: "char",
  //                 },
  //               ],
  //               groupVisible: [],
  //               relatedModel: null,
  //               relationTable: null,
  //               relationField: null,
  //               tableRelatedField: `x_o2b_${processDetail.process_name
  //                 .trim()
  //                 .toLowerCase()
  //                 .replace(/ /g, "_")}_remark_history`,
  //               tableType: "new",
  //             },
  //           ],
  //         ],
  //       };
  //       const newTabField =
  //         newField.tabs && newField.tabs.length > 0
  //           ? { ...newField }
  //           : {
  //               ...newField,
  //               tabs: [{ ...remarkObj }],
  //               activeTab: 0,
  //             };
  //       if (rowIndex === null) {
  //         newDocumentsData.push([newTabField]);
  //       } else {
  //         const isChatter =
  //           prevDocumentsData[prevDocumentsData.length - 1][0].type ===
  //           "chatter";
  //         if (isChatter && rowIndex === prevDocumentsData.length) {
  //           newDocumentsData.splice(rowIndex - 1, 0, [newTabField]);
  //         } else {
  //           newDocumentsData.splice(rowIndex, 0, [newTabField]);
  //         }
  //       }
  //     } else if (newField.type === "chatter") {
  //       let isChatterAlready = null;
  //       isChatterAlready = prevDocumentsData.find(
  //         (field) => field[0].type === "chatter"
  //       );
  //       if (isChatterAlready === null || isChatterAlready === undefined) {
  //         newDocumentsData.push([newField]);
  //       } else {
  //         return;
  //       }
  //     } else if (newField.type === "ribbon") {
  //       let isRibbonAlready = null;
  //       isRibbonAlready = prevDocumentsData.find(
  //         (field) => field[0].type === "ribbon"
  //       );
  //       if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //         newDocumentsData.unshift([newField]);
  //       } else {
  //         return;
  //       }
  //     } else if (
  //       newField.type === "object" &&
  //       ["default-statinfo", "statinfo"].includes(newField.widget)
  //     ) {
  //       const findSmartButtonIndex = (arrays) => {
  //         for (let outerIndex = 0; outerIndex < arrays.length; outerIndex++) {
  //           const innerArray = arrays[outerIndex];
  //           const found = innerArray.some(
  //             (obj) =>
  //               obj.type === "object" &&
  //               ["default-statinfo", "statinfo"].includes(obj.widget)
  //           );
  //           if (found) {
  //             return outerIndex;
  //           }
  //         }
  //         return -1;
  //       };
  //       const index = findSmartButtonIndex(prevDocumentsData);
  //       // console.log("smart button index:::: ", index);
  //       let isRibbonAlready = null;
  //       isRibbonAlready = prevDocumentsData.find(
  //         (field) => field[0].type === "ribbon"
  //       );
  //       if (isRibbonAlready === null || isRibbonAlready === undefined) {
  //         if (index !== -1) {
  //           newDocumentsData[index]?.push(newField);
  //         } else {
  //           newDocumentsData.unshift([newField]);
  //         }
  //       } else {
  //         if (index !== -1) {
  //           newDocumentsData[index]?.push(newField);
  //         } else {
  //           newDocumentsData.splice(1, 0, [newField]);
  //         }
  //       }
  //     } else {
  //       if (rowIndex === null) {
  //         let isChatter = false;
  //         if (prevDocumentsData?.length > 0) {
  //           isChatter =
  //             prevDocumentsData[prevDocumentsData?.length - 1][0].type ===
  //             "chatter";
  //         }
  //         if (isChatter) {
  //           newDocumentsData.splice(prevDocumentsData.length - 1, 0, [
  //             newField,
  //           ]);
  //         } else {
  //           newDocumentsData.push([newField]);
  //         }
  //       } else {
  //         if (
  //           columnIndex !== null &&
  //           newDocumentsData[rowIndex][columnIndex]?.type === "group"
  //         ) {
  //           addFieldToGroup(
  //             newDocumentsData[rowIndex][columnIndex].fields,
  //             subGroupIndex
  //           );
  //         } else if (
  //           columnIndex !== null &&
  //           newDocumentsData[rowIndex][columnIndex]?.type === "tab"
  //         ) {
  //           const activeTab = newDocumentsData[rowIndex][columnIndex].activeTab;
  //           addFieldToTab(
  //             newDocumentsData[rowIndex][columnIndex].tabs[activeTab].content,
  //             tabFieldIndex
  //           );
  //         } else if (
  //           columnIndex !== null &&
  //           [
  //             "separator",
  //             "remark_history",
  //             "chatter",
  //             "ribbon",
  //             "chatter",
  //             "object",
  //             "statinfo",
  //             "default-statinfo",
  //           ].includes(newDocumentsData[rowIndex][columnIndex]?.type)
  //         ) {
  //           return newDocumentsData;
  //         } else if (columnIndex === null) {
  //           const isRibbon = newDocumentsData[rowIndex]?.some(
  //             (col) => col.type === "ribbon"
  //           );
  //           const isSmartButton = newDocumentsData[rowIndex]?.some(
  //             (col) =>
  //               col.type === "object" &&
  //               ["default-statinfo", "statinfo"].includes(col.widget)
  //           );

  //           if (isRibbon || isSmartButton) {
  //             return newDocumentsData;
  //           } else {
  //             let isChatter;
  //             if (prevDocumentsData?.length > 0) {
  //               isChatter =
  //                 prevDocumentsData[prevDocumentsData?.length - 1][0].type ===
  //                 "chatter";
  //             }
  //             if (isChatter && rowIndex === prevDocumentsData.length) {
  //               newDocumentsData.splice(rowIndex - 1, 0, [newField]);
  //             } else {
  //               newDocumentsData.splice(rowIndex, 0, [newField]);
  //             }
  //           }
  //         } else {
  //           newField.type !== "separator"
  //             ? newDocumentsData[rowIndex].splice(columnIndex + 1, 0, newField)
  //             : newDocumentsData.splice(rowIndex, 0, [newField]);
  //         }
  //       }
  //     }

  //     setDraggedOverIndex(null);
  //     // setDocumentsData(newDocumentsData);
  //     return newDocumentsData;
  //   });
  // };

  // method for drag start inside form board
  const handleDragStart = (e, field, indices) => {
    // Set the dragged field data with its source indices
    e.stopPropagation();
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        field,
        sourceIndex: indices,
      })
    );
  };

  // delete method for source field before drop at new location
  const handleDeleteSourceField = (sourceIndices, formdata) => {
    const {
      rowIndex,
      columnIndex,
      groupRowIndex,
      groupFieldIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    } = sourceIndices;

    console.log("Deleted field data::::: ", {
      rowIndex,
      columnIndex,
      groupRowIndex,
      groupFieldIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });

    let updatedFormData = [...formdata];

    // Handle deletion based on where the field is located
    if (tabGroupFieldIndex !== undefined) {
      // Delete from tab group fields
      updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
        tabRowIndex
      ][tabFieldIndex].fields[tabGroupRowIndex] = updatedFormData[rowIndex][
        columnIndex
      ].tabs[tabIndex].content[tabRowIndex][tabFieldIndex].fields[
        tabGroupRowIndex
      ].filter((_, index) => index !== tabGroupFieldIndex);

      // Remove empty tab group row
      if (
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields[tabGroupRowIndex].length === 0
      ) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ][tabFieldIndex].fields = updatedFormData[rowIndex][columnIndex].tabs[
          tabIndex
        ].content[tabRowIndex][tabFieldIndex].fields.filter(
          (_, index) => index !== tabGroupRowIndex
        );
      }
    } else if (groupFieldIndex !== undefined) {
      // Delete from group fields
      updatedFormData[rowIndex][columnIndex].fields[groupRowIndex] =
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex].filter(
          (_, index) => index !== groupFieldIndex
        );

      // Remove empty group row
      if (
        updatedFormData[rowIndex][columnIndex].fields[groupRowIndex].length ===
        0
      ) {
        updatedFormData[rowIndex][columnIndex].fields = updatedFormData[
          rowIndex
        ][columnIndex].fields.filter((_, index) => index !== groupRowIndex);
      }
    } else if (tabFieldIndex !== undefined) {
      // Delete from tabs
      updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
        tabRowIndex
      ] = updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
        tabRowIndex
      ].filter((_, index) => index !== tabFieldIndex);

      // Remove empty tab row
      if (
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content[
          tabRowIndex
        ].length === 0
      ) {
        updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content =
          updatedFormData[rowIndex][columnIndex].tabs[tabIndex].content.filter(
            (_, index) => index !== tabRowIndex
          );
      }
    } else {
      // General case: delete the column in the row
      updatedFormData[rowIndex].splice(columnIndex, 1);

      // Remove the entire row if no columns remain
      if (updatedFormData[rowIndex].length === 0) {
        updatedFormData.splice(rowIndex, 1);
      }
    }

    // Final clean up - Remove any empty rows
    updatedFormData = updatedFormData.filter((row) => row.length > 0);

    console.log("updated form data before setting state::: ", updatedFormData);
    // setFormData([...updatedFormData]);
    return updatedFormData;
  };

  // method for submiting the selection field modal
  const handleModalSubmit = () => {
    const {
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      newField,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    } = modalData;
    if (
      !selectionOptions.name ||
      selectionOptions.name.match(/^ *$/) !== null
    ) {
      centerAlert("error", "Label field is mandatory to set.");
      return;
    }
    if (
      !selectionOptions.options ||
      selectionOptions.options.match(/^ *$/) !== null
    ) {
      centerAlert("error", "Options field is mandatory to set.");
      return;
    }
    newField.title = selectionOptions.name.trim();
    newField.technicalName = `x_o2b_${selectionOptions.name
      .trim()
      .toLowerCase()
      .replace(/ /g, "_")}`;
    const optionsArray = selectionOptions.options
      .split(",")
      .map((option) => option.trim());
    newField.options = optionsArray;

    addField(newField, {
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });
    topRightAlert("success", "Selection field added in form view");
    setShowModal(false);
    // setOptions("");
    setSelectionOptions({ name: "", options: "" });
    setModalData(null);
  };

  // method for submiting the selection field modal on documents
  // const handleDocumentsModalSubmit = () => {
  //   const {
  //     rowIndex,
  //     columnIndex,
  //     groupIndex,
  //     subGroupIndex,
  //     newField,
  //     tabIndex,
  //     tabRowIndex,
  //     tabFieldIndex,
  //     tabGroupRowIndex,
  //     tabGroupFieldIndex,
  //   } = modalData;
  //   const optionsArray = options.split(",").map((option) => option.trim());
  //   newField.options = optionsArray;

  //   addDocumentsField(
  //     newField,
  //     rowIndex,
  //     columnIndex,
  //     groupIndex,
  //     subGroupIndex,
  //     tabIndex,
  //     tabRowIndex,
  //     tabFieldIndex,
  //     tabGroupRowIndex,
  //     tabGroupFieldIndex
  //   );
  //   topRightAlert("success", "Selection field added in form view");
  //   setShowModal(false);
  //   setOptions("");
  //   setModalData(null);
  // };

  // onclick method for clicking top level fields
  const handleClick = (e, field, rowIndex, columnIndex) => {
    e.stopPropagation();
    setSelectedFieldData({ field, rowIndex, columnIndex });
    setShowFieldDetail(true);
  };
  // onclick method for clicking top level todos fields
  // const handleTodosClick = (e, field, rowIndex, columnIndex) => {
  //   e.stopPropagation();
  //   setSelectedTodosField({ field, rowIndex, columnIndex });
  //   setShowFieldDetail(true);
  // };

  // onclick method for clicking top level documents fields
  // const handleDocumentsClick = (e, field, rowIndex, columnIndex) => {
  //   e.stopPropagation();
  //   setSelectedDocumentsField({ field, rowIndex, columnIndex });
  //   setShowFieldDetail(true);
  // };

  // onclick method for clicking over group field
  const handleGroupFieldClick = (
    e,
    groupField,
    rowIndex,
    columnIndex,
    groupRowIndex,
    groupFieldIndex
  ) => {
    e.stopPropagation();
    setSelectedFieldData({
      groupField,
      rowIndex,
      columnIndex,
      groupRowIndex,
      groupFieldIndex,
    });
    setShowFieldDetail(true);
  };
  // onclick method for clicking over tab field
  const handleTabFieldClick = (
    e,
    tabField,
    rowIndex,
    columnIndex,
    tabIndex,
    tabRowIndex,
    tabFieldIndex
  ) => {
    e.stopPropagation();
    setSelectedFieldData({
      tabField,
      rowIndex,
      columnIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
    });
    setShowFieldDetail(true);
  };
  // onclick method for clicking over tab-group field
  const handleTabGroupFieldClick = (
    e,
    tabGroupField,
    rowIndex,
    columnIndex,
    tabIndex,
    tabRowIndex,
    tabFieldIndex,
    tabGroupRowIndex,
    tabGroupFieldIndex
  ) => {
    e.stopPropagation();
    setSelectedFieldData({
      tabGroupField,
      rowIndex,
      columnIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });
    console.log("object for clicked field: ", {
      tabGroupField,
      rowIndex,
      columnIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });
    setShowFieldDetail(true);
  };

  // method to check mandatory field for relational field
  const checkMandatoryField = (relationFields) => {
    // Check if input is an array and has elements
    if (Array.isArray(relationFields) && relationFields.length > 0) {
      // Loop through each field in the array
      for (const field of relationFields) {
        // Check if the field's type requires a related model
        if (["many2many", "many2one", "one2many"].includes(field.type)) {
          // Check if relatedModel is empty, undefined, or null
          if (
            field.relatedModel === "" ||
            field.relatedModel === undefined ||
            field.relatedModel === null
          ) {
            // If relatedModel is invalid, return false with a message
            return {
              status: false,
              message: `Please fill 'Related Model' field at ${field.title}.`,
            };
          }
          if (field.type === "one2many") {
            if (
              field.relationField === "" ||
              field.relationField === undefined ||
              field.relationField === null
            ) {
              // If relatedModel is invalid, return false with a message
              return {
                status: false,
                message: `Please fill 'Relation Field' field at ${field.title}.`,
              };
            }
          }
          if (field.type === "many2many") {
            if (
              field.relationTable === "" ||
              field.relationTable === undefined ||
              field.relationTable === null
            ) {
              // If relatedModel is invalid, return false with a message
              return {
                status: false,
                message: `Please fill 'Relation Table' field at ${field.title}.`,
              };
            }
          }
        }
      }
      // If all fields are valid, return true
      return { status: true };
    }
    // If the input array is empty or not valid, return a default message
    return { status: true, message: "Please add fields first." };
  };

  const checkRemarkHistoryDecisionOptions = () => {
    const newFormData = [...formData];

    return newFormData.every((row) => {
      if (row[0].type === "remark_history") {
        const decisionField = row[0].tabs[0].content[0][0];
        const optionLength = decisionField?.options.length;
        return optionLength > 0; // Return true only if length is greater than 0
      }
      return true; // Ignore non-remark_history rows
    });
  };

  // method for onchange field validation check
  const checkOnchangeFieldValidation = () => {
    const newFormData = [...formData];
    console.log("newFormData::: ", newFormData);

    if (Array.isArray(newFormData) && newFormData.length > 0) {
      const formFields = parseFormData(newFormData);
      console.log("formFields: ", formFields);

      if (formFields.length > 0) {
        for (const field of formFields) {
          if (field.isOnChange === true) {
            if (
              field.on_change_relation === "" ||
              field.on_change_relation === null ||
              field.on_change_relation === undefined
            ) {
              return {
                status: false,
                message: `Releted Field is not set on ${field.title}`,
              };
            } else if (
              field.on_change_relation_model_field === "" ||
              field.on_change_relation_model_field === null ||
              field.on_change_relation_model_field === undefined
            ) {
              return {
                status: false,
                message: `Releted Model Field is not set on ${field.title}`,
              };
            }
          }
        }
      }
      return { status: true };
    }
    return { status: true };
  };

  // method for email template validation on send email button
  // const checkEmailButtonTemplate = ()=>{
  //   const newFormData = [...formData]
  //   const btnIndex = findHeaderButtonIndex(newFormData)
  //   if(btnIndex > -1){
  //     const buttons = newFormData[btnIndex]
  //     const emailButton = buttons.find(btn=>btn.widget === "email-button")
  //     if (emailButton){
  //       if(emailButton.template === null || emailButton.template === undefined){
  //         return true;
  //       }
  //     }
  //   }
  //   return false
  // }
  const checkEmailButtonTemplate = () => {
    const btnIndex = findHeaderButtonIndex(formData);
    if (btnIndex === -1) return false;

    const emailButton = formData[btnIndex]?.find(
      (btn) => btn.widget === "email-button"
    );
    return !emailButton?.template;
  };

  // save method for save button
  const handleSaveAndCloseForm = async () => {
    try {
      const mandatoryHandled = checkMandatoryField(allLocalRelationalField);
      if (mandatoryHandled.status === false) {
        centerAlert("error", mandatoryHandled.message);
        return;
      }
      if (!checkRemarkHistoryDecisionOptions()) {
        centerAlert(
          "error",
          "Please add options at remark history 'Decision' field."
        );
        return;
      }
      const onchangeFieldValidation = checkOnchangeFieldValidation();
      if (onchangeFieldValidation.status === false) {
        centerAlert("error", onchangeFieldValidation.message);
        return;
      }
      if (checkEmailButtonTemplate()) {
        centerAlert("error", "Please set template on Send Email Button");
        return;
      }
      // setFormData((prevData) => handleUpdateRelatedModelStatus(prevData));
      const payload = {
        formbuilder_data: formData,
        process_id: newProcessId,
        form_builder_id: newFormId,
      };
      // Encrypt the payload before sending
      const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/form/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedPayload }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("form update data: ", data);
        setForms([...forms, data.updateFormBuilder]);
        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            // Check if it's the node identified by shapeId OR if it has a matching form_builder_id
            if (
              node.id === shapeId ||
              (node.data.form && node.data.form.form_builder_id === newFormId)
            ) {
              return {
                ...node,
                data: {
                  ...node.data,
                  form: data.updateFormBuilder, // Update form with latest data
                  isFormSelected:
                    node.id === shapeId ? true : node.data.isFormSelected, // Set isFormSelected for the specific shapeId node
                },
              };
            }
            return node;
          })
        );
        topRightAlert("success", data.message);
        if (newProcessId !== null) {
          if (processDetail.process_id === newProcessId) {
            navigate("/process-modeler");
          }
        }
      } else {
        centerAlert("error", data.message);
        console.log("form update data: ", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.log("error: ", error);
    }
  };
  // save method for save button
  const handleSaveForm = async () => {
    try {
      const mandatoryHandled = checkMandatoryField(allLocalRelationalField);
      if (mandatoryHandled.status === false) {
        centerAlert("error", mandatoryHandled.message);
        return;
      }
      if (!checkRemarkHistoryDecisionOptions()) {
        centerAlert(
          "error",
          "Please add options at remark history 'Decision' field."
        );
        return;
      }
      const onchangeFieldValidation = checkOnchangeFieldValidation();
      if (onchangeFieldValidation.status === false) {
        centerAlert("error", onchangeFieldValidation.message);
        return;
      }
      if (checkEmailButtonTemplate()) {
        centerAlert("error", "Please set template on Send Email Button");
        return;
      }
      // setFormData((prevData) => handleUpdateRelatedModelStatus(prevData));
      const payload = {
        formbuilder_data: formData,
        process_id: newProcessId,
        form_builder_id: newFormId,
      };
      const encryptedPayload = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/form/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: payload,
        body: JSON.stringify({ data: encryptedPayload }),
        // body: JSON.stringify({
        //   formbuilder_data: formData,
        //   process_id: newProcessId,
        //   form_builder_id: newFormId,
        // }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("form update data: ", data);
        setForms([...forms, data.updateFormBuilder]);
        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            // Check if it's the node identified by shapeId OR if it has a matching form_builder_id
            if (
              node.id === shapeId ||
              (node.data.form && node.data.form.form_builder_id === newFormId)
            ) {
              return {
                ...node,
                data: {
                  ...node.data,
                  form: data.updateFormBuilder, // Update form with latest data
                  isFormSelected:
                    node.id === shapeId ? true : node.data.isFormSelected, // Set isFormSelected for the specific shapeId node
                },
              };
            }
            return node;
          })
        );
        topRightAlert("success", data.message);
      } else {
        centerAlert("error", data.message);
        console.log("form update data: ", data);
      }
    } catch (error) {
      centerAlert("error", error);
      console.log("error: ", error);
    }
  };

  // save method for todos save button
  // const handleTodosSaveAndCloseForm = async () => {
  //   if (formData && selectedFieldData) {
  //     const { field, rowIndex, columnIndex } = selectedFieldData;
  //     const latestFormData = JSON.parse(JSON.stringify(formData));
  //     // latestFormData[rowIndex][columnIndex] = latestFormData[rowIndex][columnIndex]
  //     latestFormData[rowIndex][columnIndex].todos_field = [...todosData];
  //     setFormData(latestFormData);
  //     setSelectedFieldData((prevSelectedData) => ({
  //       ...prevSelectedData,
  //       field: { ...prevSelectedData.field, todos_field: [...todosData] },
  //     }));
  //     topRightAlert("success", "Todos form data saved successfully.");
  //     setIsTodoFormBuilder(false);
  //     navigate("/form-builder");
  //   } else {
  //     centerAlert("error", "Form data not found.");
  //   }
  // };
  // save method for todos save button
  // const handleTodosSaveForm = async () => {
  //   if (formData && selectedFieldData) {
  //     const { field, rowIndex, columnIndex } = selectedFieldData;
  //     const latestFormData = JSON.parse(JSON.stringify(formData));
  //     // latestFormData[rowIndex][columnIndex] = latestFormData[rowIndex][columnIndex]
  //     latestFormData[rowIndex][columnIndex].todos_field = [...todosData];
  //     setFormData(latestFormData);
  //     setSelectedFieldData((prevSelectedData) => ({
  //       ...prevSelectedData,
  //       field: { ...prevSelectedData.field, todos_field: [...todosData] },
  //     }));
  //     topRightAlert("success", "Todos form data saved successfully.");
  //   } else {
  //     centerAlert("error", "Form data not found.");
  //   }
  // };

  // save method for documents save button
  // const handleDocumentsSaveAndCloseForm = async () => {
  //   if (formData && selectedFieldData) {
  //     const { field, rowIndex, columnIndex } = selectedFieldData;
  //     const latestFormData = JSON.parse(JSON.stringify(formData));
  //     // latestFormData[rowIndex][columnIndex] = latestFormData[rowIndex][columnIndex]
  //     latestFormData[rowIndex][columnIndex].documents_field = [
  //       ...documentsData,
  //     ];
  //     setFormData(latestFormData);
  //     setSelectedFieldData((prevSelectedData) => ({
  //       ...prevSelectedData,
  //       field: {
  //         ...prevSelectedData.field,
  //         documents_field: [...documentsData],
  //       },
  //     }));
  //     topRightAlert("success", "Documents form data saved successfully.");
  //     setIsDocumentFormBuilder(false);
  //     navigate("/form-builder");
  //   } else {
  //     centerAlert("error", "Form data not found.");
  //   }
  // };
  // save method for documents save button
  // const handleDocumentsSaveForm = async () => {
  //   if (formData && selectedFieldData) {
  //     const { field, rowIndex, columnIndex } = selectedFieldData;
  //     const latestFormData = JSON.parse(JSON.stringify(formData));
  //     // latestFormData[rowIndex][columnIndex] = latestFormData[rowIndex][columnIndex]
  //     latestFormData[rowIndex][columnIndex].documents_field = [
  //       ...documentsData,
  //     ];
  //     setFormData(latestFormData);
  //     setSelectedFieldData((prevSelectedData) => ({
  //       ...prevSelectedData,
  //       field: {
  //         ...prevSelectedData.field,
  //         documents_field: [...documentsData],
  //       },
  //     }));
  //     topRightAlert("success", "Documents form data saved successfully.");
  //   } else {
  //     centerAlert("error", "Form data not found.");
  //   }
  // };

  // method for save kanban data
  const handleKanbanSaveAndClose = async () => {
    try {
      const response = await fetch(`${BASE_URL}/form/update/kanban`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kanban_data: kanbanDetail,
          process_id: newProcessId,
          form_builder_id: newFormId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (shapeId !== null) {
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === shapeId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      kanbanData: [...kanbanDetail],
                    },
                  }
                : node
            )
          );
        }
        topRightAlert("success", "Kanban data updated...");
        // setIsKanbanBuilder(false);
        setBuilderType("form");
        if (newProcessId !== null) {
          if (processDetail.process_id === newProcessId) {
            navigate("/process-modeler");
          }
        }
      } else {
        topRightAlert("warning", "Error in saving kanban data!");
        console.log("error in saving kanban data: ", data);
      }
    } catch (err) {
      topRightAlert("error", "Server error occurred...");
      console.error("error: ", err);
    }
  };

  // method for save list data
  const handleListSaveAndClose = async () => {
    if (shapeId !== null) {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === shapeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  list_data: [...listDetail],
                },
              }
            : node
        )
      );
    }
    topRightAlert("success", "List data saved successfully.");
    // setIsKanbanBuilder(false);
    setBuilderType("form");
    if (newProcessId !== null) {
      if (processDetail.process_id === newProcessId) {
        navigate("/process-modeler");
      }
    }
    // try {
    //   const response = await fetch(`${BASE_URL}/form/update/list`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       list_data: kanbanDetail,
    //       process_id: newProcessId,
    //       form_builder_id: newFormId,
    //     }),
    //   });
    //   const data = await response.json();
    //   if (response.ok) {
    //     if (shapeId !== null) {
    //       setNodes((prevNodes) =>
    //         prevNodes.map((node) =>
    //           node.id === shapeId
    //             ? {
    //                 ...node,
    //                 data: {
    //                   ...node.data,
    //                   list_data: [...kanbanDetail],
    //                 },
    //               }
    //             : node
    //         )
    //       );
    //     }
    //     topRightAlert("success", data.message);
    //     // setIsKanbanBuilder(false);
    //     setBuilderType("form");
    //     if (newProcessId !== null) {
    //       if (processDetail.process_id === newProcessId) {
    //         navigate("/process-modeler");
    //       }
    //     }
    //   } else {
    //     centerAlert("error", data.message);
    //     console.log("error in saving list data: ", data);
    //   }
    // } catch (err) {
    //   centerAlert("error", err);
    //   console.error("error: ", err);
    // }
  };
  // method for save kanban data
  const handleKanbanSave = async () => {
    try {
      const response = await fetch(`${BASE_URL}/form/update/kanban`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          kanban_data: kanbanDetail,
          process_id: newProcessId,
          form_builder_id: newFormId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (shapeId !== null) {
          setNodes((prevNodes) =>
            prevNodes.map((node) =>
              node.id === shapeId
                ? {
                    ...node,
                    data: {
                      ...node.data,
                      kanbanData: [...kanbanDetail],
                    },
                  }
                : node
            )
          );
        }
        topRightAlert("success", "Kanban data updated...");
        // setIsKanbanBuilder(false);
        // setBuilderType("form");
      } else {
        topRightAlert("warning", "Error in saving kanban data!");
        console.log("error in saving kanban data: ", data);
      }
    } catch (err) {
      topRightAlert("error", "Server error occurred...");
      console.error("error: ", err);
    }
  };
  // method for save list data
  const handleListSave = async () => {
    if (shapeId !== null) {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === shapeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  list_data: [...listDetail],
                },
              }
            : node
        )
      );
    }
    topRightAlert("success", "List data saved successfully.");
    // try {
    //   const response = await fetch(`${BASE_URL}/form/update/list`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       list_data: kanbanDetail,
    //       process_id: newProcessId,
    //       form_builder_id: newFormId,
    //     }),
    //   });
    //   const data = await response.json();
    //   if (response.ok) {
    //     if (shapeId !== null) {
    //       setNodes((prevNodes) =>
    //         prevNodes.map((node) =>
    //           node.id === shapeId
    //             ? {
    //                 ...node,
    //                 data: {
    //                   ...node.data,
    //                   list_data: [...kanbanDetail],
    //                 },
    //               }
    //             : node
    //         )
    //       );
    //     }
    //     topRightAlert("success", data.message);
    //     // setIsKanbanBuilder(false);
    //     // setBuilderType("form");
    //   } else {
    //     centerAlert("error", data.message);
    //     console.log("error in saving kanban data: ", data);
    //   }
    // } catch (err) {
    //   centerAlert("error", err);
    //   console.error("error: ", err);
    // }
  };

  // method for save custom model data
  const handleCustomModelSave = async () => {
    try {
      const payload = {
        model_id: customModelDetail.model_id,
        id: customModelDetail.id,
        name: customModelDetail.name,
        model: customModelDetail.model,
        model_fields: [...formData] || customModelDetail.model_fields,
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        topRightAlert("success", data.message);
      } else {
        centerAlert("error", data.message);
        console.log("error in saving custom model data: ", data);
      }
    } catch (err) {
      centerAlert("error", err);
      console.error("error: ", err);
    }
  };
  // method for save custom model data
  const handleCustomModelSaveAndClose = async () => {
    try {
      const payload = {
        model_id: customModelDetail.model_id,
        id: customModelDetail.id,
        name: customModelDetail.name,
        model: customModelDetail.model,
        model_fields: [...formData] || customModelDetail.model_fields,
        user_id: userData.userid,
        key: API_KEY,
      };
      const encryptedData = encryptData(payload, PAYLOAD_SECRET);
      const response = await fetch(`${BASE_URL}/custom-model/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: encryptedData }),
      });
      const data = await response.json();
      if (response.ok) {
        setCustomModelDetail(null);
        setIsCustomModelForm(false);
        setFormData([]);
        topRightAlert("success", data.message);
        navigate("/dashboard/configurations/model");
      } else {
        centerAlert("error", data.message);
        console.log("error in saving custom model data: ", data);
      }
    } catch (err) {
      centerAlert("error", err);
      console.error("error: ", err);
    }
  };

  // method for selecting list fields
  const handleListFieldClick = (e, field, rowIndex) => {
    e.stopPropagation();
    setShowFieldDetail(true);
    setListFieldDelBtn(true);
    setSelectedListField({ field, rowIndex });
  };
  // method for selecting kanban fields
  const handleKanbanFieldClick = (e, field) => {
    e.stopPropagation();
    setKanbanFieldDelBtn(true);
    setSelectedKanbanField(field);
  };
  // method for kanbanfield delete
  const handleKanbanFieldDelete = () => {
    if (selectedKanbanField) {
      let updatedKanbanData = [...kanbanDetail];
      setKanbanFieldDelBtn(false);
      updatedKanbanData = kanbanDetail.map((row) =>
        row.filter((field) => field !== selectedKanbanField)
      );

      // Filter out any rows that are now empty
      updatedKanbanData = updatedKanbanData.filter((row) => row.length > 0);
      setKanbanDetail(updatedKanbanData);
    }
  };
  // method for kanbanfield delete
  const handleListFieldDelete = () => {
    if (selectedListField) {
      let updatedlistData = [...listDetail];
      setListFieldDelBtn(false);
      updatedlistData = listDetail.filter(
        (field) => field !== selectedListField.field
      );

      // Filter out any rows that are now empty
      // updatedlistData = updatedlistData.filter((row) => row.length > 0);
      setListDetail(updatedlistData);
    }
  };

  // useEffect for getting form name
  useEffect(() => {
    if (newFormId !== null) {
      setFormDesc(forms.find((form) => form.form_builder_id === newFormId));
    }
  }, [newFormId]);

  // handling table modal
  const tableFieldTypes = [
    "char",
    "text",
    "date",
    "datetime",
    "boolean",
    "float",
    "integer",
    "selection",
    "many2many",
    "many2one",
  ];
  const [allOne2ManyFields, setAllOne2ManyFields] = useState([]);
  const [tableRelatedField, setTableRelatedField] = useState("");
  const [tableModelName, setTableModelName] = useState({
    title: "",
    technicalName: "x_o2b_",
  });
  const [totalFieldForTable, setTotalFieldForTable] = useState({
    isTotalField: false,
    totalField_field: "",
    totalField_operator: "+",
  });
  const [on_change, setOnChange] = useState({
    isOnChange: false,
    on_change_relation: "",
    on_change_relation_model_field: "",
  });
  const [newTableField, setNewTableField] = useState({
    title: "",
    technicalName: "x_o2b_",
    type: "char",
    isSubtotalField: false,
  });
  const [newTableFieldOption, setNewTableFieldOption] = useState("");
  const [newTableFieldSelectOptions, setNewTableFieldSelectOptions] = useState(
    []
  );
  const [allOdooModels, setAllOdooModels] = useState(null);
  const [newTableFieldReletedModel, setNewTableFieldReletedModel] =
    useState(null);
  const [newTableFieldReletionField, setNewTableFieldReletionField] =
    useState(null);
  const [filteredRelatedField, setFilteredRelatedField] = useState(null);
  const [newTableFieldReletedModelFields, setNewTableFieldReletedModelFields] =
    useState(null);
  const [newTableRelatedModelDomain, setNewTableRelatedModelDomain] =
    useState(null);
  const [tableRelatedModelFields, setTableRelatedModelFields] = useState([]);
  const [tableType, setTableType] = useState("new");
  const [showTableFieldInputBox, setShowTableFieldInputBox] = useState(true);
  const [showSubtotalEquationFields, setShowSubtotalEquationFields] =
    useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [subTotalEligibleFields, setSubTotalEligibleField] = useState(null);
  const [subTotalEquation, setSubTotalEquation] = useState("");
  const [equationOperator, setEquationOperator] = useState("");
  const [equationOperand, setEquationOperand] = useState("");

  // useEffect for fetching all models in odoo
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/odoo/models?database_url=${
            isCustomModelForm
              ? customModelDetail.database_url
              : processDetail.database_url
          }&api_key=${API_KEY}`
        );
        const data = await response.json();
        if (response.ok) {
          if (Array.isArray(data.data)) {
            setAllOdooModels(data.data);
          }
        } else {
          console.error("Error in fetch: ", data);
        }
      } catch (error) {
        console.log("server error: ", error);
      }
    };
    fetchModels();
    console.log("all odoo models:::::: ", allOdooModels);
  }, []);

  // useEffect for fetching all fields for selected model
  useEffect(() => {
    if (newTableFieldReletedModel && newTableFieldReletedModel !== "") {
      const fetchModalFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/field?model=${newTableFieldReletedModel}&database_url=${
              isCustomModelForm
                ? customModelDetail.database_url
                : processDetail.database_url
            }&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              setNewTableFieldReletedModelFields(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };

      const fetchRelatedFields = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/odoo/fields?model=${newTableFieldReletedModel}&database_url=${
              isCustomModelForm
                ? customModelDetail.database_url
                : processDetail.database_url
            }&api_key=${API_KEY}`
          );
          const data = await response.json();
          if (response.ok) {
            if (Array.isArray(data.data)) {
              // console.log("related field data:::::::: ", data);
              // console.log("related fields:::::: ", JSON.stringify(data.data));
              setFilteredRelatedField(data.data);
            }
          } else {
            console.error("Error in fetch: ", data);
          }
        } catch (error) {
          console.log("server error: ", error);
        }
      };
      fetchModalFields();
      fetchRelatedFields();
    }
  }, [newTableFieldReletedModel]);

  // useEffect for getting all one2many type field
  useEffect(() => {
    // Step 1: Filter data where type is 'one2many'
    const filteredData = allFields?.filter((item) => item.type === "one2many");
    // Step 2: Ensure uniqueness based on 'technical_name'
    const seenTechnicalNames = new Set();
    const uniqueFilteredData = filteredData?.filter((item) => {
      if (!seenTechnicalNames.has(item.technicalName)) {
        seenTechnicalNames.add(item.technicalName);
        return true;
      }
      return false;
    });

    setAllOne2ManyFields(uniqueFilteredData);
  }, [allFields]);
  // useEffect for fetching all Eligible field for subtotal
  useEffect(() => {
    if (tableColumns) {
      const fields = tableColumns.filter((column) =>
        ["integer", "float"].includes(column.type)
      );
      setSubTotalEligibleField([...fields]);
    }
  }, [tableColumns]);

  const handleAddOperand = (value) => {
    setSubTotalEquation((prevEquation) => prevEquation + value);
  };
  // Handler for setting the selected operator
  const handleSetOperator = (value) => {
    if (subTotalEquation && !equationOperator) {
      setEquationOperator(value);
      setSubTotalEquation(subTotalEquation + value);
    }
  };
  // Handler for clearing the equation
  const handleClear = () => {
    setSubTotalEquation("");
    setEquationOperator("");
  };

  const handleNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value <= 10) {
      setColNumber(value);
      setColumns(new Array(value).fill("")); // Reset or initialize column values
    }
  };
  const handleTableRelatedFieldChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      setTableRelatedField(value);
    }
  };
  const handleTableModelNameChange = (e) => {
    const value = e.target.value;
    const technicalName = `x_o2b_${value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "_")}`;
    setTableModelName({
      ...tableModelName,
      title: value,
      technicalName,
    });
  };
  const handleTableFieldDetailChange = (e) => {
    const value = e.target.value;
    if (e.target.name === "type") {
      setNewTableField({ ...newTableField, type: value });
    } else {
      const technicalName = `x_o2b_${value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, "_")}`;
      setNewTableField({ ...newTableField, title: value, technicalName });
    }
  };
  const handleTotalFieldChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTotalFieldForTable((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleChangeOnChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOnChange((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleAddTableColumn = (e) => {
    e.preventDefault();
    const cols = tableColumns;
    if (newTableField.title === "") {
      centerAlert("error", "Field name is mandatory to fill.");
      return;
    }
    if (on_change.isOnChange) {
      newTableField.isOnChange = on_change.isOnChange;
      if (
        on_change.isOnChange === true &&
        (on_change.on_change_relation === "" ||
          on_change.on_change_relation === null ||
          on_change.on_change_relation === undefined)
      ) {
        centerAlert("error", "Related Field is not set.");
        return;
      } else if (
        on_change.isOnChange === true &&
        (on_change.on_change_relation_model_field === "" ||
          on_change.on_change_relation_model_field === null ||
          on_change.on_change_relation_model_field === undefined)
      ) {
        centerAlert("error", "Related Model Field is not set.");
        return;
      }
      newTableField.on_change_relation = on_change.on_change_relation;
      newTableField.on_change_relation_model_field =
        on_change.on_change_relation_model_field;
    }
    if (newTableField.type === "selection") {
      if (newTableFieldSelectOptions.length === 0) {
        centerAlert("error", "Options are not defined");
        return;
      } else {
        newTableField.options = [...newTableFieldSelectOptions];
      }
    }
    if (["many2many", "many2one", "one2many"].includes(newTableField.type)) {
      if (!newTableFieldReletedModel || newTableFieldReletedModel === "") {
        centerAlert("error", "Related Model field is mandatory to fill");
        return;
      } else {
        newTableField.relatedModel = newTableFieldReletedModel;
        newTableField.domain = newTableRelatedModelDomain;
      }
      if (newTableField.type === "one2many") {
        if (!newTableFieldReletionField || newTableFieldReletionField === "") {
          centerAlert("error", "Relation Field is mandatory to fill");
          return;
        } else {
          newTableField.relationField = newTableFieldReletionField;
        }
      }
      switch (newTableField.type) {
        case "many2one":
          newTableField.widget = "many2one";
          break;
        case "many2many":
          newTableField.widget = "many2many_tags";
          break;
        case "one2many":
          newTableField.widget = "one2many";
          break;

        default:
          break;
      }
    }
    if (subTotalEquation && showSubtotalEquationFields) {
      newTableField.equation = subTotalEquation;
      newTableField.isSubtotalField = true;
      setSubTotalEquation("");
      setEquationOperand("");
      setEquationOperator("");
      setShowSubtotalEquationFields(false);
    }
    cols.push(newTableField);
    setOnChange({
      isOnChange: false,
      on_change_relation: "",
      on_change_relation_model_field: "",
    });
    setTableColumns([...cols]);
    setNewTableFieldSelectOptions([]);
    setNewTableFieldReletedModel(null);
    setNewTableRelatedModelDomain(null);
    setShowTableFieldInputBox(false);
    console.log("newTableField:::: ", newTableField);
    setNewTableField({
      title: "",
      technicalName: "x_o2b_",
      type: "char",
      isSubtotalField: false,
    });
  };
  const handleAddTableFieldSelectOption = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const opts = newTableFieldSelectOptions;
    if (newTableFieldOption && newTableFieldOption !== "") {
      opts.push(newTableFieldOption);
    }
    setNewTableFieldSelectOptions([...opts]);
    setNewTableFieldOption("");
  };
  const handleRemoveTableColumn = (e, colIndex) => {
    e.preventDefault();
    const cols = tableColumns;
    cols.splice(colIndex, 1);
    setTableColumns([...cols]);
  };

  const handleRemoveTableFieldSelectOption = (e, colIndex) => {
    e.preventDefault();
    e.stopPropagation();
    const opts = newTableFieldSelectOptions;
    opts.splice(colIndex, 1);
    setNewTableFieldSelectOptions([...opts]);
  };
  const handleFetchRelatedNormalField = async (modelName) => {
    try {
      const response = await fetch(
        `${BASE_URL}/odoo/field?model=${modelName}&database_url=${
          isCustomModelForm
            ? customModelDetail.database_url
            : processDetail.database_url
        }&api_key=${API_KEY}`
      );
      const data = await response.json();
      if (response.ok) {
        setTableRelatedModelFields(data.data);
        console.log("response data for fields:::::: ", data);
      } else {
        console.log("error in fetching: ", data);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (on_change && on_change.on_change_relation) {
      const modelName = tableColumns.find(
        (field) => field.technicalName === on_change?.on_change_relation
      )?.relatedModel;
      handleFetchRelatedNormalField(modelName);
    }
  }, [on_change, on_change.on_change_relation]);

  const handleModal2Submit = async () => {
    const {
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      newField,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    } = modalData;

    // if (tableType === "new") {
    newField.tableType = tableType;
    newField.columns = [...tableColumns];
    newField.tableRelatedField = tableModelName.technicalName;
    newField.tableModelName = tableModelName.title;
    newField.title = tableModelName.title;
    newField.technicalName = tableModelName.technicalName;

    if (totalFieldForTable.isTotalField === true) {
      newField.isTotalField = true;
      newField.totalfield_field = totalFieldForTable.totalField_field;
      newField.totalfield_operator = totalFieldForTable.totalField_operator;
    }

    if (
      !newField.tableModelName ||
      newField.tableModelName === "" ||
      newField.tableModelName === undefined
    ) {
      centerAlert("error", "Model Name field is mandatory to fill.");
      return;
    }
    const availableTableFields = parseTableFields(formData);
    if (
      availableTableFields &&
      availableTableFields.find(
        (tbl) => tbl.tableRelatedField === newField.tableRelatedField
      )
    ) {
      centerAlert("error", "Model Name already exist. Try another name!");
      return;
    }
    // let modelsData = null;
    const getModelData = async () => {
      const response = await fetch(
        `${BASE_URL}/odoo/models?database_url=${
          isCustomModelForm
            ? customModelDetail.database_url
            : processDetail.database_url
        }&api_key=${API_KEY}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log("models:: ", data.data);

        return data.data;
      } else {
        return null;
      }
    };
    const modelsData = await getModelData();
    console.log("modelsData::: ", modelsData);
    console.log("arrrayyy::: ", [
      `x_o2b.${newField.tableRelatedField}`,
      `o2b.${newField.tableRelatedField}`,
      `o2b.${newField.tableRelatedField.replace("x_o2b", "")}`,
      `x_o2b.${newField.tableRelatedField.replace("x_o2b", "")}`,
    ]);

    if (
      modelsData?.find((mdl) =>
        [
          `x_o2b.${newField.tableRelatedField}`,
          `o2b.${newField.tableRelatedField}`,
          `o2b.${newField.tableRelatedField.replace("x_o2b_", "")}`,
          `x_o2b.${newField.tableRelatedField.replace("x_o2b_", "")}`,
        ].includes(mdl.model)
      )
    ) {
      centerAlert("error", "Model Name already exist. Try another name!");
      return;
    }

    if (Array.isArray(newField.columns) && newField.columns.length < 1) {
      centerAlert("error", "Please insert atleast one field.");
      return;
    }
    // } else {
    //   newField.tableType = tableType;
    //   newField.columns = [...columns];
    //   newField.tableRelatedField = tableRelatedField;
    // }

    addField(newField, {
      rowIndex,
      columnIndex,
      groupIndex,
      subGroupIndex,
      tabIndex,
      tabRowIndex,
      tabFieldIndex,
      tabGroupRowIndex,
      tabGroupFieldIndex,
    });
    setNewTableField({
      title: "",
      technicalName: "x_o2b_",
      type: "char",
      isSubtotalField: false,
    });
    topRightAlert("success", "Table added in form view");
    setModalData(null);
    setTableModelName({ technicalName: "", title: "" });
    setTotalFieldForTable({
      isTotalField: false,
      totalField_field: "",
      totalField_operator: "+",
    });
    setTableColumns([]);
    setShowModal2(false);
  };

  // console.log("allFields: ", JSON.stringify(allFields));
  // console.log("allFields: ", allFields);
  // console.log("allOne2ManyFields: ", JSON.stringify(allOne2ManyFields));
  // console.log("tableRelatedModelFields: ", tableRelatedModelFields);
  // console.log("forms:::: ", forms);
  // console.log("formDesc::: ", formDesc);
  // console.log("formdata: ", formData);
  // console.log("formdata json: ", JSON.stringify(formData));

  return (
    <div className="fb_board">
      {/* code for form name */}
      {/* {!isKanbanBuilder && ( */}
      {builderType === "form" && (
        <div className="fb_formName_container">
          {isCustomModelForm === true ? (
            <span>
              {customModelDetail ? customModelDetail.model : "Model field"}
            </span>
          ) : (
            <span>
              {formDesc !== null
                ? formDesc?.form_builder_name
                : "no process or form selected"}
            </span>
          )}
          {/* {isCustomModelForm === true ? (
            <span>
              {customModelDetail ? customModelDetail.model : "Model field"}
            </span>
          ) : isTodoFormBuilder === true ? (
            <span>Todos Form Builder</span>
          ) : isDocumentFormBuilder === true ? (
            <span>Documents Form Builder</span>
          ) : (
            <span>
              {formDesc !== null
                ? formDesc?.form_builder_name
                : "no process or form selected"}
            </span>
          )} */}
        </div>
      )}
      <div
        style={{ width: "100%", height: "80px" }}
        className="d-flex flex-column justify-content-center align-items-center gap-1"
      >
        {/* code for save button */}
        {/* <Link
          to={
            isCustomModelForm === true
              ? "/dashboard/configurations/model"
              : isTodoFormBuilder === true || isDocumentFormBuilder === true
              ? "/form-builder"
              : "/process-modeler"
          } */}
        <Link
          to={
            isCustomModelForm === true
              ? "/dashboard/configurations/model"
              : "/process-modeler"
          }
        >
          <button
            className="fb_discardForm_btn"
            onClick={() => {
              if (isCustomModelForm) {
                setIsCustomModelForm(false);
                setCustomModelDetail(null);
                setFormData([]);
              }
              // if (isTodoFormBuilder || isDocumentFormBuilder) {
              //   setIsTodoFormBuilder(false);
              //   setIsDocumentFormBuilder(false);
              //   setTodosData(null);
              // }
            }}
          >
            Discard
          </button>
        </Link>
        {/* code for save button */}
        <button
          className="fb_saveForm_btn"
          // onClick={isKanbanBuilder ? handleKanbanSave : handleSaveForm}
          onClick={
            builderType === "kanban"
              ? handleKanbanSave
              : builderType === "list"
              ? handleListSave
              : newFormId === null && isCustomModelForm === true
              ? handleCustomModelSave
              : handleSaveForm
          }
        >
          {/* {isKanbanBuilder ? "Save Kanban" : "Save Form"} */}
          {`Save ${builderType[0].toUpperCase() + builderType.substring(1)}`}
        </button>
        <button
          className="fb_saveAndCloseForm_btn"
          onClick={
            builderType === "kanban"
              ? handleKanbanSaveAndClose
              : builderType === "list"
              ? handleListSaveAndClose
              : newFormId === null && isCustomModelForm === true
              ? handleCustomModelSaveAndClose
              : handleSaveAndCloseForm
          }
        >
          {/* {isKanbanBuilder ? "Save & Close Kanban" : "Save & Close"} */}
          {/* {`Save & Close ${
          builderType[0].toUpperCase() + builderType.substring(1)
        }`} */}
          {`Save & Close`}
        </button>
        {/* <h2>{isKanbanBuilder ? "Design Your Kanban" : "Design Your Form"}</h2> */}
        <div
          className={`d-flex justify-content-center align-items-start overflow-hidden text-nowrap m-0`}
          // className={`d-flex ${isProcessNameOverflowing ? "justify-content-start" : "justify-content-center"} align-items-start overflow-hidden text-nowrap m-0`}
          style={{
            width: "340px",
            height: "40px",
            whiteSpace: "nowrap",
          }}
        >
          {isCustomModelForm === true ? (
            <h2
              // ref={processNameRef}
              style={{
                display: "inline-block",
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontSize: "1.7rem",
              }}
            >
              {customModelDetail ? customModelDetail.name : "No Model Selected"}
            </h2>
          ) : (
            <h2
              // ref={processNameRef}
              style={{
                display: "inline-block",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {processDetail
                ? processDetail.process_name
                : "No Process Selected"}
            </h2>
          )}
        </div>
        <p
          className="mt-1 pb-0"
          style={{ marginTop: "-20px", fontSize: "14px" }}
        >
          {/* {isKanbanBuilder ? "Design Your Kanban" : "Design Your Form"} */}
          {`Design Your ${
            builderType[0].toUpperCase() + builderType.substring(1)
          }`}
        </p>
      </div>
      {/* {isKanbanBuilder ? ( */}
      {builderType === "kanban" && (
        // code for kanban builder
        <div
          className="inputfields"
          style={{ justifyContent: "center", height: "92%" }}
        >
          <div
            className="kb_kanban_container"
            onDragOver={(e) => handleKanbanDragOver(e)}
            onDrop={(e) => handleKanbanDrop(e)}
          >
            {kanbanDetail &&
              Array.isArray(kanbanDetail) &&
              kanbanDetail.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className="kb_kanban_row"
                  onDragOver={(e) => handleKanbanDragOver(e, rowIndex)}
                  onDrop={(e) => handleKanbanDrop(e, rowIndex)}
                >
                  {row &&
                    row.map((field, columnIndex) => (
                      <div
                        key={columnIndex}
                        className="kb_kanban_column"
                        onDragOver={(e) =>
                          handleKanbanDragOver(e, rowIndex, columnIndex)
                        }
                        onDrop={(e) =>
                          handleKanbanDrop(e, rowIndex, columnIndex)
                        }
                        onClick={(e) => handleKanbanFieldClick(e, field)}
                      >
                        {kanbanFieldDelBtn && selectedKanbanField === field && (
                          <button
                            className="del_button"
                            onClick={handleKanbanFieldDelete}
                          >
                            <MdDeleteOutline style={{ fontSize: "20px" }} />
                          </button>
                        )}
                        {field.type === "button" ||
                        field.type === "object" ||
                        field.type === "action" ? (
                          <button className="kbnbtn" title={field.tooltip}>
                            {field.title}
                          </button>
                        ) : (
                          <span title={field.tooltip}>{field.title}</span>
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      )}
      {/* code for list builder */}
      {builderType === "list" && (
        // code for kanban builder
        <div
          className="inputfields"
          style={{ justifyContent: "center", height: "92%" }}
        >
          <div
            className="lb_list_container"
            onDragOver={(e) => handleListDragOver(e)}
            onDrop={(e) => handleListDrop(e)}
          >
            {listDetail &&
              Array.isArray(listDetail) &&
              listDetail.map((field, rowIndex) => (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  // style={{ width: "100%" }}
                >
                  <div
                    key={rowIndex}
                    className="lb_list_column"
                    onDragOver={(e) => handleListDragOver(e, rowIndex)}
                    onDrop={(e) => handleListDrop(e, rowIndex)}
                    onClick={(e) => handleListFieldClick(e, field, rowIndex)}
                  >
                    {field.type === "button" ||
                    field.type === "object" ||
                    field.type === "action" ? (
                      <button className="kbnbtn" title={field.tooltip}>
                        {field.title}
                      </button>
                    ) : (
                      <span title={field.tooltip}>{field.title}</span>
                    )}
                    {listFieldDelBtn && selectedListField.field === field && (
                      <button
                        className="del_button"
                        onClick={handleListFieldDelete}
                      >
                        <MdDeleteOutline style={{ fontSize: "20px" }} />
                      </button>
                    )}
                  </div>
                  <div
                    className="lb_list_cell"
                    style={{ backgroundColor: "#f4f4f4" }}
                  />
                  <div
                    className="lb_list_cell"
                    style={{ backgroundColor: "#d5d5d5" }}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      {/* : */}
      {builderType === "form" && (
        // code for form builder
        <div
          className="inputfields"
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDrop(e)}
        >
          {formData &&
            formData.map((row, rowIndex) => (
              <div
                key={`${rowIndex}_0_${rowIndex}`}
                className="fb_row"
                onDragOver={(e) => handleDragOver(e, rowIndex)}
                onDrop={(e) => handleDrop(e, rowIndex)}
              >
                {row.map((field, columnIndex) => (
                  <div
                    key={`${rowIndex}_1_${columnIndex}`}
                    className="fb_inputfield"
                    title={field.tooltip}
                    onDragOver={(e) =>
                      handleDragOver(e, rowIndex, columnIndex, null, null)
                    }
                    onDrop={(e) =>
                      handleDrop(e, rowIndex, columnIndex, null, null)
                    }
                    onClick={(e) =>
                      handleClick(e, field, rowIndex, columnIndex)
                    }
                    draggable
                    onDragStart={(e) =>
                      handleDragStart(e, field, { rowIndex, columnIndex })
                    }
                  >
                    {["button", "object", "action"].includes(field.type) ? (
                      <>
                        {/* // code for field if type is button */}
                        {["default-statinfo", "statinfo"].includes(
                          field.widget
                        ) ? (
                          <button
                            className={`${
                              field?.alignment === "center"
                                ? "align-self-center"
                                : field?.alignment === "left"
                                ? "align-self-start"
                                : "align-self-end"
                            }`}
                            style={{
                              border: "1px solid #2e2e2e",
                              width: "100px",
                              backgroundColor: "transparent",
                              color: "#3e3e3e",
                              fontSize: "16px",
                              height: "50px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflow: "hidden",
                            }}
                          >
                            {field.title}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className={`btn ${
                              field?.alignment === "right"
                                ? "align-self-end"
                                : field?.alignment === "left"
                                ? "align-self-start"
                                : "align-self-center"
                            } ${
                              field.odooButtonColor
                                ? field.odooButtonColor
                                : "btn-outline-dark"
                            } `}
                          >
                            {field.title}
                          </button>
                        )}
                      </>
                    ) : field.type === "header-button" ? (
                      <button
                        type="submit"
                        className={`btn ${
                          field?.alignment === "left"
                            ? "align-self-start"
                            : "align-self-start"
                        } ${
                          field.odooButtonColor
                            ? field.odooButtonColor
                            : "btn-outline-dark"
                        } `}
                      >
                        {field.title}
                      </button>
                    ) : field.type === "selection" ? (
                      // code for field if type is selection
                      <>
                        <label htmlFor={field.type}>
                          {field.title}
                          {field?.isRequired && "*"}
                        </label>
                        <CustomDropdown options={field.options} />
                      </>
                    ) : field.type === "label" ? (
                      // code for field if type is label
                      <>
                        <span
                          className={`${
                            field?.alignment === "center"
                              ? "align-self-center"
                              : field?.alignment === "right"
                              ? "align-self-end"
                              : "align-self-start"
                          }`}
                          style={{
                            fontSize: `${field?.fontSize || "24"}px`,
                            fontWeight: `${field?.fontWeight || "500"}`,
                          }}
                        >
                          {field.title}
                        </span>
                      </>
                    ) : field.type === "static_image" ? (
                      // code for field if type is label
                      <>
                        {field?.defaultValue !== "" ? (
                          <img
                            src={field?.defaultValue || ""}
                            className={`${
                              field?.alignment === "center"
                                ? "align-self-center"
                                : field?.alignment === "left"
                                ? "align-self-start"
                                : "align-self-end"
                            }`}
                            width="100px"
                            height="100px"
                            style={{
                              border: "1px solid #383838",
                            }}
                            alt="static img"
                          />
                        ) : (
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              border: "1px solid #383838",
                              fontSize: "60px",
                            }}
                            className={`${
                              field?.alignment === "center"
                                ? "align-self-center"
                                : field?.alignment === "left"
                                ? "align-self-start"
                                : "align-self-end"
                            } d-flex justify-content-center align-items-center`}
                          >
                            <FaRegImage />
                          </div>
                        )}
                      </>
                    ) : field.type === "separator" ? (
                      // code for field if type is separator
                      <>
                        {/* <label htmlFor={field.type}>{field.title}</label> */}
                        <div style={{ width: "100%" }}>
                          <hr
                            style={{
                              margin: "5px 0",
                              border: "none",
                              borderTop: "2px solid #333",
                            }}
                          />
                          <hr
                            style={{
                              margin: "5px 0",
                              border: "none",
                              borderTop: "2px solid #333",
                            }}
                          />
                        </div>
                      </>
                    ) : field.type === "table" ? (
                      // code for field if type is table
                      <>
                        <label htmlFor={field.type}>{field.title}</label>
                        <table
                          style={{ width: "90%", alignSelf: "center" }}
                          className="table table-secondary table-bordered"
                        >
                          <thead className="">
                            <tr className="d-table-row table-light">
                              {field.columns &&
                                field.columns.map((col, i) => (
                                  <th
                                    key={i}
                                    scope="col"
                                    className="d-table-cell"
                                  >
                                    {typeof col === "string" ? col : col.title}
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="">
                            <tr className="d-table-row">
                              {field.columns &&
                                field.columns.map((_, i) => (
                                  <td className="d-table-cell" key={i}></td>
                                ))}
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : field.type === "chatter" ? (
                      // code for field if type is chatter
                      <>
                        <label htmlFor={field.type}>{field.title}</label>
                        {/* <div style={{ width: "100%" }}>
                          <hr
                            style={{
                              margin: "5px 0",
                              border: "none",
                              borderTop: "2px solid #ccc",
                            }}
                          />
                        </div> */}
                        {/* <IoChatbubbles style={{ fontSize: "40px" }} /> */}
                        <img
                          src={ChatterScreen}
                          alt="chatter screen"
                          style={{ width: "100%" }}
                        />
                      </>
                    ) : field.type === "ribbon" ? (
                      // code for field if type is ribbon
                      <>
                        <div
                          className={`ribbon_container ${
                            field?.ribbonBgColor
                              ? field.ribbonBgColor
                              : "bg-danger"
                          }`}
                        >
                          <span>{field.title}</span>
                        </div>
                      </>
                    ) : field.type === "boolean" ? (
                      // code for field if type is boolean
                      <>
                        <div
                          className="d-flex justify-content-start align-items-center gap-5"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor={field.type}>
                            {field.title}
                            {field?.isRequired && "*"}
                          </label>
                          <input
                            type="checkbox"
                            value={field.defaultValue}
                            required={field.isRequired}
                            readOnly={field.isReadOnly}
                            style={{ width: "17px" }}
                          />
                        </div>
                      </>
                    ) : field.type === "html" ? (
                      // code for field if type is chatter
                      <>
                        <label htmlFor={field.type}>{field.title}</label>
                        <div style={{ width: "100%" }}>
                          <hr
                            style={{
                              margin: "5px 0",
                              border: "none",
                              borderTop: "2px solid #333",
                            }}
                          />
                        </div>
                        <IoGlobeOutline style={{ fontSize: "40px" }} />
                      </>
                    ) : field.type === "oe_avatar" ? (
                      // code for field if type is oe_avatar(form image)
                      <>
                        <div
                          className={`${
                            field?.alignment === "center"
                              ? "align-self-center"
                              : field?.alignment === "left"
                              ? "align-self-start"
                              : "align-self-end"
                          } border d-flex justify-content-center align-items-center`}
                          style={{
                            width: "70px",
                            height: "70px",
                            fontSize: `${field?.fontSize || "24"}px`,
                            fontWeight: `${field?.fontWeight || "500"}`,
                          }}
                        >
                          {field?.defaultValue !== "" ? (
                            <img
                              src={field?.defaultValue || ""}
                              alt="form_img"
                              width="90%"
                            />
                          ) : (
                            <FaPlus />
                          )}
                        </div>
                      </>
                    ) : field.type === "binary" ? (
                      field.widget === "image" ? (
                        // code for field if type is image/binary
                        <>
                          <label htmlFor={field.type}>
                            {field.title}
                            {field?.isRequired && "*"}
                          </label>
                          <CiCamera
                            style={{ fontSize: "40px", color: "#2e2e2e" }}
                          />
                        </>
                      ) : (
                        // code for field if type is file/binary
                        <>
                          <label htmlFor={field.type}>
                            {field.title}
                            {field?.isRequired && "*"}
                          </label>
                          <input
                            type="file"
                            placeholder={field.placeholder}
                            required={field.isRequired}
                            readOnly={field.isReadOnly}
                          />
                        </>
                      )
                    ) : (
                      // code for field if type is anything besides tab and group
                      <>
                        <label htmlFor={field.type}>
                          {field.title}
                          {field?.isRequired && "*"}
                        </label>
                        {!["group", "tab", "remark_history"].includes(
                          field.type
                        ) && (
                          <input
                            type={
                              field.type === "datetime"
                                ? "datetime-local"
                                : field.type === "age"
                                ? "date"
                                : field.type
                            }
                            placeholder={
                              field.placeholder || `Enter ${field.title}`
                            }
                            value={field.defaultValue}
                            required={field.isRequired}
                            readOnly={field.isReadOnly}
                          />
                        )}
                      </>
                    )}
                    {/* code for field if type is remark_history */}
                    {field.type === "remark_history" && (
                      <>
                        <ul className="nav nav-tabs">
                          {field.tabs &&
                            field.tabs.map((tab, tabIndex) => (
                              <li className="nav-item" key={tabIndex}>
                                <button
                                  className={`nav-link ${
                                    field.activeTab === tabIndex ? "active" : ""
                                  }`}
                                >
                                  {tab.tabName}
                                </button>
                              </li>
                            ))}
                        </ul>
                        <div className="tab-content fb_group">
                          {field.tabs &&
                            field.tabs[field.activeTab || 0].content.map(
                              (tabRow, tabRowIndex) => (
                                <div
                                  key={tabRowIndex}
                                  className={`fb_tab_row `}
                                >
                                  {tabRow.length > 0
                                    ? tabRow.map((tabField, tabFieldIndex) => (
                                        <div
                                          key={tabFieldIndex}
                                          className="fb_inputfield"
                                          title={tabField.tooltip}
                                          onClick={(e) =>
                                            handleTabFieldClick(
                                              e,
                                              tabField,
                                              rowIndex,
                                              columnIndex,
                                              field.activeTab,
                                              tabRowIndex,
                                              tabFieldIndex
                                            )
                                          }
                                        >
                                          {tabField.type === "table" ? (
                                            // code for field if type is table
                                            <>
                                              <label htmlFor={tabField.type}>
                                                {tabField.title}
                                              </label>
                                              <table
                                                style={{
                                                  width: "90%",
                                                  alignSelf: "center",
                                                }}
                                                className="table table-secondary table-bordered"
                                              >
                                                <thead className="">
                                                  <tr className="d-table-row table-light">
                                                    {tabField.columns &&
                                                      tabField.columns.map(
                                                        (col, i) => (
                                                          <th
                                                            key={i}
                                                            scope="col"
                                                            className="d-table-cell"
                                                          >
                                                            {typeof col ===
                                                            "string"
                                                              ? col
                                                              : col.title}
                                                          </th>
                                                        )
                                                      )}
                                                  </tr>
                                                </thead>
                                                <tbody className="">
                                                  <tr className="d-table-row">
                                                    {tabField.columns &&
                                                      tabField.columns.map(
                                                        (_, i) => (
                                                          <td
                                                            className="d-table-cell"
                                                            key={i}
                                                          ></td>
                                                        )
                                                      )}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </>
                                          ) : tabField.type === "separator" ? (
                                            // code for field if type is separator
                                            <>
                                              {/* <label htmlFor={field.type}>{field.title}</label> */}
                                              <div style={{ width: "100%" }}>
                                                <hr
                                                  style={{
                                                    margin: "5px 0",
                                                    border: "none",
                                                    borderTop: "2px solid #333",
                                                  }}
                                                />
                                                <hr
                                                  style={{
                                                    margin: "5px 0",
                                                    border: "none",
                                                    borderTop: "2px solid #333",
                                                  }}
                                                />
                                              </div>
                                            </>
                                          ) : tabField.type === "selection" ? (
                                            <>
                                              <label htmlFor={tabField.type}>
                                                {tabField.title}
                                                {tabField?.isRequired && "*"}
                                              </label>
                                              <select
                                                required={tabField.isRequired}
                                              >
                                                {tabField.options.map(
                                                  (option, index) => (
                                                    <option
                                                      key={index}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                            </>
                                          ) : (
                                            // code for field if type is anything besides tab and group
                                            <>
                                              <label htmlFor={tabField.type}>
                                                {tabField.title}
                                                {tabField?.isRequired && "*"}
                                              </label>
                                              {!["group", "tab"].includes(
                                                tabField.type
                                              ) && (
                                                <input
                                                  type={tabField.type}
                                                  placeholder={
                                                    tabField.placeholder ||
                                                    `Enter ${tabField.title}`
                                                  }
                                                  value={tabField.defaultValue}
                                                  required={tabField.isRequired}
                                                  readOnly={tabField.isReadOnly}
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      ))
                                    : ""}
                                </div>
                              )
                            )}
                        </div>
                      </>
                    )}

                    {/* code for field if type is tab */}
                    {field.type === "tab" && (
                      <>
                        <ul className="nav nav-tabs">
                          {field.tabs &&
                            field.tabs.map((tab, tabIndex) => (
                              <li className="nav-item" key={tabIndex}>
                                <button
                                  className={`nav-link ${
                                    field.activeTab === tabIndex ? "active" : ""
                                  }`}
                                  onClick={(e) =>
                                    handleTabChange(
                                      e,
                                      rowIndex,
                                      columnIndex,
                                      tabIndex,
                                      tab.tabName
                                    )
                                  }
                                >
                                  {tab.tabName}
                                </button>
                              </li>
                            ))}
                          <li className="nav-item">
                            <button
                              className="nav-link"
                              onClick={() => addNewTab(rowIndex, columnIndex)}
                            >
                              + Add Tab
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content fb_group">
                          {field.tabs &&
                            field.tabs[field.activeTab || 0].content.map(
                              (tabRow, tabRowIndex) => (
                                <div
                                  key={tabRowIndex}
                                  className={`fb_tab_row `}
                                  onDragOver={(e) =>
                                    handleDragOver(
                                      e,
                                      rowIndex,
                                      columnIndex,
                                      null,
                                      null,
                                      field.activeTab,
                                      tabRowIndex
                                    )
                                  }
                                  onDrop={(e) =>
                                    handleDrop(
                                      e,
                                      rowIndex,
                                      columnIndex,
                                      null,
                                      null,
                                      field.activeTab,
                                      tabRowIndex
                                    )
                                  }
                                >
                                  {tabRow.length > 0 ? (
                                    tabRow.map((tabField, tabFieldIndex) => (
                                      <div
                                        key={tabFieldIndex}
                                        className="fb_inputfield"
                                        title={tabField.tooltip}
                                        onDragOver={(e) =>
                                          handleDragOver(
                                            e,
                                            rowIndex,
                                            columnIndex,
                                            null,
                                            null,
                                            field.activeTab,
                                            tabRowIndex,
                                            tabFieldIndex
                                          )
                                        }
                                        onDrop={(e) =>
                                          handleDrop(
                                            e,
                                            rowIndex,
                                            columnIndex,
                                            null,
                                            null,
                                            field.activeTab,
                                            tabRowIndex,
                                            tabFieldIndex
                                          )
                                        }
                                        onClick={(e) =>
                                          handleTabFieldClick(
                                            e,
                                            tabField,
                                            rowIndex,
                                            columnIndex,
                                            field.activeTab,
                                            tabRowIndex,
                                            tabFieldIndex
                                          )
                                        }
                                        draggable
                                        onDragStart={(e) =>
                                          handleDragStart(e, tabField, {
                                            rowIndex,
                                            columnIndex,
                                            tabIndex: field.activeTab,
                                            tabRowIndex,
                                            tabFieldIndex,
                                          })
                                        }
                                      >
                                        {[
                                          "button",
                                          "object",
                                          "action",
                                        ].includes(tabField.type) ? (
                                          <button
                                            type="submit"
                                            className={`btn ${
                                              tabField?.alignment === "right"
                                                ? "align-self-end"
                                                : tabField?.alignment === "left"
                                                ? "align-self-start"
                                                : "align-self-center"
                                            } ${
                                              tabField.odooButtonColor
                                                ? tabField.odooButtonColor
                                                : "btn-outline-dark"
                                            } `}
                                          >
                                            {tabField.title}
                                          </button>
                                        ) : tabField.type === "selection" ? (
                                          <>
                                            <label htmlFor={tabField.type}>
                                              {tabField.title}
                                              {tabField?.isRequired && "*"}
                                            </label>
                                            <select
                                              required={tabField.isRequired}
                                            >
                                              {tabField.options.map(
                                                (option, index) => (
                                                  <option
                                                    key={index}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </>
                                        ) : tabField.type === "label" ? (
                                          // code for field if type is label
                                          <>
                                            {/* <label htmlFor={field.type}>{field.title}</label> */}
                                            <span
                                              className={`${
                                                field?.alignment === "center"
                                                  ? "align-self-center"
                                                  : field?.alignment === "right"
                                                  ? "align-self-end"
                                                  : "align-self-start"
                                              }`}
                                              style={{
                                                fontSize: `${
                                                  tabField?.fontSize || "24"
                                                }px`,
                                                fontWeight: `${
                                                  tabField?.fontWeight || "500"
                                                }`,
                                              }}
                                            >
                                              {tabField.title}
                                            </span>
                                            {/* <div style={{ width: "100%" }}>
                                              <hr
                                                style={{
                                                  margin: "5px 0",
                                                  border: "none",
                                                  borderTop: "2px solid #ccc",
                                                }}
                                              />
                                            </div> */}
                                          </>
                                        ) : tabField.type === "separator" ? (
                                          // code for field if type is separator
                                          <>
                                            {/* <label htmlFor={field.type}>{field.title}</label> */}
                                            <div style={{ width: "100%" }}>
                                              <hr
                                                style={{
                                                  margin: "5px 0",
                                                  border: "none",
                                                  borderTop: "2px solid #333",
                                                }}
                                              />
                                              <hr
                                                style={{
                                                  margin: "5px 0",
                                                  border: "none",
                                                  borderTop: "2px solid #333",
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : tabField.type === "table" ? (
                                          // code for field if type is table
                                          <>
                                            <label htmlFor={tabField.type}>
                                              {tabField.title}
                                            </label>
                                            <table
                                              style={{
                                                width: "90%",
                                                alignSelf: "center",
                                              }}
                                              className="table table-secondary table-bordered"
                                            >
                                              <thead className="">
                                                <tr className="d-table-row table-light">
                                                  {tabField.columns &&
                                                    tabField.columns.map(
                                                      (col, i) => (
                                                        <th
                                                          key={i}
                                                          scope="col"
                                                          className="d-table-cell"
                                                        >
                                                          {typeof col ===
                                                          "string"
                                                            ? col
                                                            : col.title}
                                                        </th>
                                                      )
                                                    )}
                                                </tr>
                                              </thead>
                                              <tbody className="">
                                                <tr className="d-table-row">
                                                  {tabField.columns &&
                                                    tabField.columns.map(
                                                      (_, i) => (
                                                        <td
                                                          className="d-table-cell"
                                                          key={i}
                                                        ></td>
                                                      )
                                                    )}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </>
                                        ) : tabField.type === "boolean" ? (
                                          // code for field if type is boolean
                                          <>
                                            <div className="d-flex justify-content-start align-items-center gap-4">
                                              <label htmlFor={tabField.type}>
                                                {tabField.title}
                                                {tabField?.isRequired && "*"}
                                              </label>
                                              <input
                                                type={
                                                  tabField.type === "boolean"
                                                    ? "checkbox"
                                                    : "text"
                                                }
                                                placeholder={
                                                  tabField.placeholder ||
                                                  `Enter ${tabField.title}`
                                                }
                                                value={tabField.defaultValue}
                                                required={tabField.isRequired}
                                                readOnly={tabField.isReadOnly}
                                              />
                                            </div>
                                          </>
                                        ) : tabField.type === "html" ? (
                                          // code for field if type is chatter
                                          <>
                                            <label htmlFor={tabField.type}>
                                              {tabField.title}
                                            </label>
                                            <div style={{ width: "100%" }}>
                                              <hr
                                                style={{
                                                  margin: "5px 0",
                                                  border: "none",
                                                  borderTop: "2px solid #ccc",
                                                }}
                                              />
                                            </div>
                                            <IoGlobeOutline
                                              style={{ fontSize: "40px" }}
                                            />
                                          </>
                                        ) : tabField.type === "static_image" ? (
                                          // code for field if type is label
                                          <>
                                            {tabField?.defaultValue !== "" ? (
                                              <img
                                                src={
                                                  tabField?.defaultValue || ""
                                                }
                                                className={`${
                                                  tabField?.alignment ===
                                                  "center"
                                                    ? "align-self-center"
                                                    : tabField?.alignment ===
                                                      "left"
                                                    ? "align-self-start"
                                                    : "align-self-end"
                                                }`}
                                                width="100px"
                                                height="100px"
                                                style={{
                                                  border: "1px solid #383838",
                                                }}
                                                alt="static img"
                                              />
                                            ) : (
                                              <div
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  border: "1px solid #383838",
                                                  fontSize: "60px",
                                                }}
                                                className={`${
                                                  tabField?.alignment ===
                                                  "center"
                                                    ? "align-self-center"
                                                    : tabField?.alignment ===
                                                      "left"
                                                    ? "align-self-start"
                                                    : "align-self-end"
                                                } d-flex justify-content-center align-items-center`}
                                              >
                                                <FaRegImage />
                                              </div>
                                            )}
                                          </>
                                        ) : tabField.type === "oe_avatar" ? (
                                          // code for field if type is oe_avatar(form image)
                                          <>
                                            <div
                                              className={`${
                                                tabField?.alignment === "center"
                                                  ? "align-self-center"
                                                  : tabField?.alignment ===
                                                    "left"
                                                  ? "align-self-start"
                                                  : "align-self-end"
                                              } border d-flex justify-content-center align-items-center`}
                                              style={{
                                                width: "70px",
                                                height: "70px",
                                                fontSize: `${
                                                  tabField?.fontSize || "24"
                                                }px`,
                                                fontWeight: `${
                                                  tabField?.fontWeight || "500"
                                                }`,
                                              }}
                                            >
                                              {tabField?.defaultValue !== "" ? (
                                                <img
                                                  src={
                                                    tabField?.defaultValue || ""
                                                  }
                                                  alt="form_img"
                                                  width="90%"
                                                />
                                              ) : (
                                                <FaPlus />
                                              )}
                                            </div>
                                          </>
                                        ) : tabField.type === "binary" ? (
                                          tabField.widget === "image" ? (
                                            // code for field if type is image/binary
                                            <>
                                              <label htmlFor={tabField.type}>
                                                {tabField.title}
                                                {tabField?.isRequired && "*"}
                                              </label>
                                              <CiCamera
                                                style={{
                                                  fontSize: "40px",
                                                  color: "#2e2e2e",
                                                }}
                                              />
                                            </>
                                          ) : (
                                            // code for field if type is file/binary
                                            <>
                                              <label htmlFor={tabField.type}>
                                                {tabField.title}
                                                {tabField?.isRequired && "*"}
                                              </label>
                                              <input
                                                type="file"
                                                placeholder={
                                                  tabField.placeholder
                                                }
                                                required={tabField.isRequired}
                                                readOnly={tabField.isReadOnly}
                                              />
                                            </>
                                          )
                                        ) : (
                                          // code for field if type is anything besides tab and group
                                          <>
                                            <label htmlFor={tabField.type}>
                                              {tabField.title}
                                              {tabField?.isRequired && "*"}
                                            </label>
                                            {!["group", "tab"].includes(
                                              tabField.type
                                            ) && (
                                              <input
                                                type={tabField.type}
                                                placeholder={
                                                  tabField.placeholder ||
                                                  `Enter ${tabField.title}`
                                                }
                                                value={tabField.defaultValue}
                                                required={tabField.isRequired}
                                                readOnly={tabField.isReadOnly}
                                              />
                                            )}
                                          </>
                                        )}

                                        {/* code for tab field if type is group */}
                                        {tabField.type === "group" && (
                                          <div
                                            className="fb_group"
                                            title={tabField.tooltip}
                                          >
                                            {tabField.fields &&
                                            tabField.fields.length > 0 ? (
                                              tabField.fields.map(
                                                (
                                                  tabGroupRow,
                                                  tabGroupRowIndex
                                                ) => (
                                                  <div
                                                    key={tabGroupRowIndex}
                                                    className="fb_row"
                                                    onDragOver={(e) =>
                                                      handleDragOver(
                                                        e,
                                                        rowIndex,
                                                        columnIndex,
                                                        null,
                                                        null,
                                                        field.activeTab,
                                                        tabRowIndex,
                                                        tabFieldIndex,
                                                        tabGroupRowIndex
                                                      )
                                                    }
                                                    onDrop={(e) =>
                                                      handleDrop(
                                                        e,
                                                        rowIndex,
                                                        columnIndex,
                                                        null,
                                                        null,
                                                        field.activeTab,
                                                        tabRowIndex,
                                                        tabFieldIndex,
                                                        tabGroupRowIndex
                                                      )
                                                    }
                                                  >
                                                    {tabGroupRow.map(
                                                      (
                                                        tabGroupField,
                                                        tabGroupFieldIndex
                                                      ) => (
                                                        <div
                                                          key={
                                                            tabGroupFieldIndex
                                                          }
                                                          className="fb_inputfield"
                                                          title={
                                                            tabGroupField.tooltip
                                                          }
                                                          onDragOver={(e) =>
                                                            handleDragOver(
                                                              e,
                                                              rowIndex,
                                                              columnIndex,
                                                              null,
                                                              null,
                                                              field.activeTab,
                                                              tabRowIndex,
                                                              tabFieldIndex,
                                                              tabGroupRowIndex,
                                                              tabGroupFieldIndex
                                                            )
                                                          }
                                                          onDrop={(e) =>
                                                            handleDrop(
                                                              e,
                                                              rowIndex,
                                                              columnIndex,
                                                              null,
                                                              null,
                                                              field.activeTab,
                                                              tabRowIndex,
                                                              tabFieldIndex,
                                                              tabGroupRowIndex,
                                                              tabGroupFieldIndex
                                                            )
                                                          }
                                                          onClick={(e) =>
                                                            handleTabGroupFieldClick(
                                                              e,
                                                              tabGroupField,
                                                              rowIndex,
                                                              columnIndex,
                                                              field.activeTab,
                                                              tabRowIndex,
                                                              tabFieldIndex,
                                                              tabGroupRowIndex,
                                                              tabGroupFieldIndex
                                                            )
                                                          }
                                                          draggable
                                                          onDragStart={(e) =>
                                                            handleDragStart(
                                                              e,
                                                              tabGroupField,
                                                              {
                                                                rowIndex,
                                                                columnIndex,
                                                                tabIndex:
                                                                  field.activeTab,
                                                                tabRowIndex,
                                                                tabFieldIndex,
                                                                tabGroupRowIndex,
                                                                tabGroupFieldIndex,
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {[
                                                            "button",
                                                            "object",
                                                            "action",
                                                          ].includes(
                                                            tabGroupField.type
                                                          ) ? (
                                                            <button
                                                              type="submit"
                                                              className={`btn ${
                                                                tabGroupField?.alignment ===
                                                                "right"
                                                                  ? "align-self-end"
                                                                  : tabGroupField?.alignment ===
                                                                    "left"
                                                                  ? "align-self-start"
                                                                  : "align-self-center"
                                                              } ${
                                                                tabGroupField.odooButtonColor
                                                                  ? tabGroupField.odooButtonColor
                                                                  : "btn-outline-dark"
                                                              } `}
                                                            >
                                                              {
                                                                tabGroupField.title
                                                              }
                                                            </button>
                                                          ) : tabGroupField.type ===
                                                            "selection" ? (
                                                            <>
                                                              <label>
                                                                {
                                                                  tabGroupField.title
                                                                }
                                                                {tabGroupField?.isRequired &&
                                                                  "*"}
                                                              </label>
                                                              <select
                                                                required={
                                                                  tabGroupField.isRequired
                                                                }
                                                              >
                                                                {tabGroupField.options.map(
                                                                  (
                                                                    option,
                                                                    index
                                                                  ) => (
                                                                    <option
                                                                      key={
                                                                        index
                                                                      }
                                                                      value={
                                                                        option
                                                                      }
                                                                    >
                                                                      {option}
                                                                    </option>
                                                                  )
                                                                )}
                                                              </select>
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "label" ? (
                                                            // code for field if type is label
                                                            <>
                                                              {/* <label htmlFor={tabField.type}>{tabField.title}</label> */}
                                                              <span
                                                                className={`${
                                                                  tabField?.alignment ===
                                                                  "center"
                                                                    ? "align-self-center"
                                                                    : tabField?.alignment ===
                                                                      "right"
                                                                    ? "align-self-end"
                                                                    : "align-self-start"
                                                                }`}
                                                                style={{
                                                                  fontSize: `${
                                                                    tabGroupField?.fontSize ||
                                                                    "24"
                                                                  }px`,
                                                                  fontWeight: `${
                                                                    tabGroupField?.fontWeight ||
                                                                    "500"
                                                                  }`,
                                                                }}
                                                              >
                                                                {
                                                                  tabGroupField.title
                                                                }
                                                              </span>
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "separator" ? (
                                                            // code for field if type is separator
                                                            <>
                                                              {/* <label htmlFor={tabField.type}>{tabField.title}</label> */}
                                                              <div
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <hr
                                                                  style={{
                                                                    margin:
                                                                      "5px 0",
                                                                    border:
                                                                      "none",
                                                                    borderTop:
                                                                      "2px solid #333",
                                                                  }}
                                                                />
                                                                <hr
                                                                  style={{
                                                                    margin:
                                                                      "5px 0",
                                                                    border:
                                                                      "none",
                                                                    borderTop:
                                                                      "2px solid #333",
                                                                  }}
                                                                />
                                                              </div>
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "static_image" ? (
                                                            // code for field if type is label
                                                            <>
                                                              {tabGroupField?.defaultValue !==
                                                              "" ? (
                                                                <img
                                                                  src={
                                                                    tabGroupField?.defaultValue ||
                                                                    ""
                                                                  }
                                                                  className={`${
                                                                    tabGroupField?.alignment ===
                                                                    "center"
                                                                      ? "align-self-center"
                                                                      : tabGroupField?.alignment ===
                                                                        "left"
                                                                      ? "align-self-start"
                                                                      : "align-self-end"
                                                                  }`}
                                                                  width="100px"
                                                                  height="100px"
                                                                  style={{
                                                                    border:
                                                                      "1px solid #383838",
                                                                  }}
                                                                  alt="static img"
                                                                />
                                                              ) : (
                                                                <div
                                                                  style={{
                                                                    width:
                                                                      "100px",
                                                                    height:
                                                                      "100px",
                                                                    border:
                                                                      "1px solid #383838",
                                                                    fontSize:
                                                                      "60px",
                                                                  }}
                                                                  className={`${
                                                                    tabGroupField?.alignment ===
                                                                    "center"
                                                                      ? "align-self-center"
                                                                      : tabGroupField?.alignment ===
                                                                        "left"
                                                                      ? "align-self-start"
                                                                      : "align-self-end"
                                                                  } d-flex justify-content-center align-items-center`}
                                                                >
                                                                  <FaRegImage />
                                                                </div>
                                                              )}
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "table" ? (
                                                            // code for field if type is table
                                                            <>
                                                              <label
                                                                htmlFor={
                                                                  tabGroupField.type
                                                                }
                                                              >
                                                                {
                                                                  tabGroupField.title
                                                                }
                                                              </label>
                                                              <table
                                                                style={{
                                                                  width: "90%",
                                                                  alignSelf:
                                                                    "center",
                                                                }}
                                                                className="table table-secondary table-bordered"
                                                              >
                                                                <thead className="">
                                                                  <tr className="d-table-row table-light">
                                                                    {tabGroupField &&
                                                                      tabGroupField.columns.map(
                                                                        (
                                                                          col,
                                                                          i
                                                                        ) => (
                                                                          <th
                                                                            key={
                                                                              i
                                                                            }
                                                                            scope="col"
                                                                            className="d-table-cell"
                                                                          >
                                                                            {typeof col ===
                                                                            "string"
                                                                              ? col
                                                                              : col.title}
                                                                          </th>
                                                                        )
                                                                      )}
                                                                  </tr>
                                                                </thead>
                                                                <tbody className="">
                                                                  <tr className="d-table-row">
                                                                    {tabGroupField.columns.map(
                                                                      (
                                                                        _,
                                                                        i
                                                                      ) => (
                                                                        <td
                                                                          className="d-table-cell"
                                                                          key={
                                                                            i
                                                                          }
                                                                        ></td>
                                                                      )
                                                                    )}
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "boolean" ? (
                                                            // code for field if type is boolean
                                                            <>
                                                              <div className="d-flex justify-content-start align-items-center gap-4">
                                                                <label
                                                                  htmlFor={
                                                                    tabGroupField.type
                                                                  }
                                                                >
                                                                  {
                                                                    tabGroupField.title
                                                                  }
                                                                  {tabGroupField?.isRequired &&
                                                                    "*"}
                                                                </label>
                                                                <input
                                                                  type={
                                                                    tabGroupField.type ===
                                                                    "boolean"
                                                                      ? "checkbox"
                                                                      : "text"
                                                                  }
                                                                  placeholder={
                                                                    tabGroupField.placeholder ||
                                                                    `Enter ${tabGroupField.title}`
                                                                  }
                                                                  value={
                                                                    tabGroupField.defaultValue
                                                                  }
                                                                  required={
                                                                    tabGroupField.isRequired
                                                                  }
                                                                  readOnly={
                                                                    tabGroupField.isReadOnly
                                                                  }
                                                                />
                                                              </div>
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "html" ? (
                                                            // code for field if type is chatter
                                                            <>
                                                              <label
                                                                htmlFor={
                                                                  tabGroupField.type
                                                                }
                                                              >
                                                                {
                                                                  tabGroupField.title
                                                                }
                                                              </label>
                                                              <div
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <hr
                                                                  style={{
                                                                    margin:
                                                                      "5px 0",
                                                                    border:
                                                                      "none",
                                                                    borderTop:
                                                                      "2px solid #333",
                                                                  }}
                                                                />
                                                              </div>
                                                              <IoGlobeOutline
                                                                style={{
                                                                  fontSize:
                                                                    "40px",
                                                                }}
                                                              />
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "oe_avatar" ? (
                                                            // code for field if type is oe_avatar(form image)
                                                            <>
                                                              <div
                                                                className={`${
                                                                  tabGroupField?.alignment ===
                                                                  "center"
                                                                    ? "align-self-center"
                                                                    : tabGroupField?.alignment ===
                                                                      "left"
                                                                    ? "align-self-start"
                                                                    : "align-self-end"
                                                                } border d-flex justify-content-center align-items-center`}
                                                                style={{
                                                                  width: "70px",
                                                                  height:
                                                                    "70px",
                                                                  fontSize: `${
                                                                    tabGroupField?.fontSize ||
                                                                    "24"
                                                                  }px`,
                                                                  fontWeight: `${
                                                                    tabGroupField?.fontWeight ||
                                                                    "500"
                                                                  }`,
                                                                }}
                                                              >
                                                                {tabGroupField?.defaultValue !==
                                                                "" ? (
                                                                  <img
                                                                    src={
                                                                      tabGroupField?.defaultValue ||
                                                                      ""
                                                                    }
                                                                    alt="form_img"
                                                                    width="90%"
                                                                  />
                                                                ) : (
                                                                  <FaPlus />
                                                                )}
                                                              </div>
                                                            </>
                                                          ) : tabGroupField.type ===
                                                            "binary" ? (
                                                            tabGroupField.widget ===
                                                            "image" ? (
                                                              // code for field if type is image/binary
                                                              <>
                                                                <label
                                                                  htmlFor={
                                                                    tabGroupField.type
                                                                  }
                                                                >
                                                                  {
                                                                    tabGroupField.title
                                                                  }
                                                                  {tabGroupField?.isRequired &&
                                                                    "*"}
                                                                </label>
                                                                <CiCamera
                                                                  style={{
                                                                    fontSize:
                                                                      "40px",
                                                                    color:
                                                                      "#2e2e2e",
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              // code for field if type is image/binary
                                                              <>
                                                                <label
                                                                  htmlFor={
                                                                    tabGroupField.type
                                                                  }
                                                                >
                                                                  {
                                                                    tabGroupField.title
                                                                  }
                                                                  {tabGroupField?.isRequired &&
                                                                    "*"}
                                                                </label>
                                                                <input
                                                                  type="file"
                                                                  placeholder={
                                                                    tabGroupField.placeholder
                                                                  }
                                                                  required={
                                                                    tabGroupField.isRequired
                                                                  }
                                                                  readOnly={
                                                                    tabGroupField.isReadOnly
                                                                  }
                                                                />
                                                              </>
                                                            )
                                                          ) : (
                                                            <>
                                                              <label>
                                                                {
                                                                  tabGroupField.title
                                                                }
                                                                {tabGroupField?.isRequired &&
                                                                  "*"}
                                                              </label>
                                                              <input
                                                                type={
                                                                  tabGroupField.type
                                                                }
                                                                placeholder={
                                                                  tabGroupField.placeholder ||
                                                                  `Enter ${tabGroupField.title}`
                                                                }
                                                                value={
                                                                  tabGroupField.defaultValue
                                                                }
                                                                required={
                                                                  tabGroupField.isRequired
                                                                }
                                                                readOnly={
                                                                  tabGroupField.isReadOnly
                                                                }
                                                              />
                                                            </>
                                                          )}
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <span
                                                style={{ color: "#bdbaba" }}
                                              >
                                                Drop Here
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <span style={{ color: "#bdbaba" }}>
                                      Drop Here
                                    </span>
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      </>
                    )}

                    {/* code for field if type is group */}
                    {field.type === "group" && (
                      <div className="fb_group" title={field.tooltip}>
                        {field.fields && field.fields.length > 0 ? (
                          field.fields.map((groupRow, groupRowIndex) => (
                            <div
                              key={groupRowIndex}
                              className="fb_row"
                              onDragOver={(e) =>
                                handleDragOver(
                                  e,
                                  rowIndex,
                                  columnIndex,
                                  groupRowIndex
                                )
                              }
                              onDrop={(e) =>
                                handleDrop(
                                  e,
                                  rowIndex,
                                  columnIndex,
                                  groupRowIndex
                                )
                              }
                            >
                              {groupRow.map((groupField, groupFieldIndex) => (
                                <div
                                  key={groupFieldIndex}
                                  className="fb_inputfield"
                                  title={groupField.tooltip}
                                  onDragOver={(e) =>
                                    handleDragOver(
                                      e,
                                      rowIndex,
                                      columnIndex,
                                      groupRowIndex,
                                      groupFieldIndex
                                    )
                                  }
                                  onDrop={(e) =>
                                    handleDrop(
                                      e,
                                      rowIndex,
                                      columnIndex,
                                      groupRowIndex,
                                      groupFieldIndex
                                    )
                                  }
                                  onClick={(e) =>
                                    handleGroupFieldClick(
                                      e,
                                      groupField,
                                      rowIndex,
                                      columnIndex,
                                      groupRowIndex,
                                      groupFieldIndex
                                    )
                                  }
                                  draggable
                                  onDragStart={(e) =>
                                    handleDragStart(e, groupField, {
                                      rowIndex,
                                      columnIndex,
                                      groupRowIndex,
                                      groupFieldIndex,
                                    })
                                  }
                                >
                                  {["button", "object", "action"].includes(
                                    groupField.type
                                  ) ? (
                                    <button
                                      type="submit"
                                      className={`btn ${
                                        groupField?.alignment === "right"
                                          ? "align-self-end"
                                          : groupField?.alignment === "left"
                                          ? "align-self-start"
                                          : "align-self-center"
                                      } ${
                                        groupField.odooButtonColor
                                          ? groupField.odooButtonColor
                                          : "btn-outline-dark"
                                      } `}
                                    >
                                      {groupField.title}
                                    </button>
                                  ) : groupField.type === "selection" ? (
                                    <>
                                      <label>
                                        {groupField.title}
                                        {groupField?.isRequired && "*"}
                                      </label>
                                      <select required={groupField.isRequired}>
                                        {groupField.options.map(
                                          (option, index) => (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </>
                                  ) : groupField.type === "label" ? (
                                    // code for field if type is label
                                    <>
                                      {/* <label htmlFor={field.type}>{field.title}</label> */}
                                      <span
                                        className={`${
                                          field?.alignment === "center"
                                            ? "align-self-center"
                                            : field?.alignment === "right"
                                            ? "align-self-end"
                                            : "align-self-start"
                                        }`}
                                        style={{
                                          fontSize: `${
                                            groupField?.fontSize || "24"
                                          }px`,
                                          fontWeight: `${
                                            groupField?.fontWeight || "500"
                                          }`,
                                        }}
                                      >
                                        {groupField.title}
                                      </span>
                                      {/* <div style={{ width: "100%" }}>
                                        <hr
                                          style={{
                                            margin: "5px 0",
                                            border: "none",
                                            borderTop: "2px solid #ccc",
                                          }}
                                        />
                                      </div> */}
                                    </>
                                  ) : groupField.type === "separator" ? (
                                    // code for field if type is separator
                                    <>
                                      {/* <label htmlFor={field.type}>{field.title}</label> */}
                                      <div style={{ width: "100%" }}>
                                        <hr
                                          style={{
                                            margin: "5px 0",
                                            border: "none",
                                            borderTop: "2px solid #333",
                                          }}
                                        />
                                        <hr
                                          style={{
                                            margin: "5px 0",
                                            border: "none",
                                            borderTop: "2px solid #333",
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === "table" ? (
                                    // code for field if type is table
                                    <>
                                      <label htmlFor={groupField.type}>
                                        {groupField.title}
                                      </label>
                                      <table
                                        style={{
                                          width: "90%",
                                          alignSelf: "center",
                                        }}
                                        className="table table-secondary table-bordered"
                                      >
                                        <thead className="">
                                          <tr className="d-table-row table-light">
                                            {groupField &&
                                              groupField.columns.map(
                                                (col, i) => (
                                                  <th
                                                    key={i}
                                                    scope="col"
                                                    className="d-table-cell"
                                                  >
                                                    {typeof col === "string"
                                                      ? col
                                                      : col.title}
                                                  </th>
                                                )
                                              )}
                                          </tr>
                                        </thead>
                                        <tbody className="">
                                          <tr className="d-table-row">
                                            {groupField.columns.map((_, i) => (
                                              <td
                                                className="d-table-cell"
                                                key={i}
                                              ></td>
                                            ))}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </>
                                  ) : groupField.type === "boolean" ? (
                                    // code for field if type is boolean
                                    <>
                                      <div className="d-flex justify-content-start align-items-center gap-4">
                                        <label htmlFor={groupField.type}>
                                          {groupField.title}
                                          {groupField?.isRequired && "*"}
                                        </label>
                                        <input
                                          type={
                                            groupField.type === "boolean"
                                              ? "checkbox"
                                              : "text"
                                          }
                                          placeholder={
                                            groupField.placeholder ||
                                            `Enter ${groupField.title}`
                                          }
                                          value={groupField.defaultValue}
                                          required={groupField.isRequired}
                                          readOnly={groupField.isReadOnly}
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === "html" ? (
                                    // code for field if type is chatter
                                    <>
                                      <label htmlFor={groupField.type}>
                                        {groupField.title}
                                      </label>
                                      <div style={{ width: "100%" }}>
                                        <hr
                                          style={{
                                            margin: "5px 0",
                                            border: "none",
                                            borderTop: "2px solid #333",
                                          }}
                                        />
                                      </div>
                                      <IoGlobeOutline
                                        style={{ fontSize: "40px" }}
                                      />
                                    </>
                                  ) : groupField.type === "static_image" ? (
                                    // code for field if type is label
                                    <>
                                      {groupField?.defaultValue !== "" ? (
                                        <img
                                          src={groupField?.defaultValue || ""}
                                          className={`${
                                            groupField?.alignment === "center"
                                              ? "align-self-center"
                                              : groupField?.alignment === "left"
                                              ? "align-self-start"
                                              : "align-self-end"
                                          }`}
                                          width="100px"
                                          height="100px"
                                          style={{
                                            border: "1px solid #383838",
                                          }}
                                          alt="static img"
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            border: "1px solid #383838",
                                            fontSize: "60px",
                                          }}
                                          className={`${
                                            groupField?.alignment === "center"
                                              ? "align-self-center"
                                              : groupField?.alignment === "left"
                                              ? "align-self-start"
                                              : "align-self-end"
                                          } d-flex justify-content-center align-items-center`}
                                        >
                                          <FaRegImage />
                                        </div>
                                      )}
                                    </>
                                  ) : groupField.type === "oe_avatar" ? (
                                    // code for field if type is oe_avatar(form image)
                                    <>
                                      <div
                                        className={`${
                                          groupField?.alignment === "center"
                                            ? "align-self-center"
                                            : groupField?.alignment === "left"
                                            ? "align-self-start"
                                            : "align-self-end"
                                        } border d-flex justify-content-center align-items-center`}
                                        style={{
                                          width: "70px",
                                          height: "70px",
                                          fontSize: `${
                                            groupField?.fontSize || "24"
                                          }px`,
                                          fontWeight: `${
                                            groupField?.fontWeight || "500"
                                          }`,
                                        }}
                                      >
                                        {groupField?.defaultValue !== "" ? (
                                          <img
                                            src={groupField?.defaultValue || ""}
                                            alt="form_img"
                                            width="90%"
                                          />
                                        ) : (
                                          <FaPlus />
                                        )}
                                      </div>
                                    </>
                                  ) : groupField.type === "binary" ? (
                                    groupField.widget === "image" ? (
                                      // code for field if type is image/binary
                                      <>
                                        <label htmlFor={groupField.type}>
                                          {groupField.title}
                                          {groupField?.isRequired && "*"}
                                        </label>
                                        <CiCamera
                                          style={{
                                            fontSize: "40px",
                                            color: "#2e2e2e",
                                          }}
                                        />
                                      </>
                                    ) : (
                                      // code for field if type is image/binary
                                      <>
                                        <label htmlFor={groupField.type}>
                                          {groupField.title}
                                          {groupField?.isRequired && "*"}
                                        </label>
                                        <input
                                          type="file"
                                          placeholder={groupField.placeholder}
                                          required={groupField.isRequired}
                                          readOnly={groupField.isReadOnly}
                                        />
                                      </>
                                    )
                                  ) : (
                                    <>
                                      <label>
                                        {groupField.title}
                                        {groupField?.isRequired && "*"}
                                      </label>
                                      <input
                                        type={groupField.type}
                                        placeholder={
                                          groupField.placeholder ||
                                          `Enter ${groupField.title}`
                                        }
                                        value={groupField.defaultValue}
                                        required={groupField.isRequired}
                                        readOnly={groupField.isReadOnly}
                                      />
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          <span style={{ color: "#bdbaba" }}>Drop Here</span>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}

          {/* code for placeholder to drop field */}
          <div
            className="fb_row placeholder"
            onDragOver={(e) => handleDragOver(e, formData.length)}
            onDrop={(e) => handleDrop(e, formData.length)}
          />
        </div>
      )}
      {/* code for selection field options modal */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="selectionModal"
        tabIndex="-1"
        role="dialog"
        data-bs-backdrop="static"
        style={{ display: showModal ? "block" : "none" }}
        aria-labelledby="selectionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Options</h5>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  position: "absolute",
                  right: "20px",
                  fontSize: "25px",
                  color: "white",
                }}
              >
                <IoMdClose />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group mb-2">
                <label
                  htmlFor="selectionFieldName"
                  style={{ marginBottom: "5px" }}
                >
                  Label*:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="selectionFieldName"
                  value={selectionOptions.name}
                  onChange={(e) =>
                    setSelectionOptions((prevSelectionOptions) => ({
                      ...prevSelectionOptions,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Enter field label"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="formOptions" style={{ marginBottom: "10px" }}>
                  Enter options (comma separated)*:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formOptions"
                  value={selectionOptions.options}
                  // value={options}
                  onChange={(e) =>
                    setSelectionOptions((prevSelectionOptions) => ({
                      ...prevSelectionOptions,
                      options: e.target.value,
                    }))
                  }
                  // onChange={(e) => setOptions(e.target.value)}
                  placeholder="Option1, Option2, Option3"
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn modal_save_button"
                onClick={handleModalSubmit}
              >
                Add Field
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* code for table field columns modal */}
      <div
        className={`modal fade ${showModal2 ? "show" : ""}`}
        id="tableModal"
        tabIndex="-1"
        role="dialog"
        data-bs-backdrop="static"
        style={{ display: showModal2 ? "block" : "none" }}
        aria-labelledby="tableModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Columns</h5>
              <button
                type="button"
                onClick={() => setShowModal2(false)}
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  position: "absolute",
                  right: "20px",
                  fontSize: "25px",
                  color: "white",
                }}
              >
                <IoMdClose />
              </button>
            </div>
            <div className="modal-body">
              {tableType === "new" && (
                <>
                  <div className="form-group" style={{ marginBottom: "5px" }}>
                    <label
                      htmlFor="tableModelName"
                      style={{ marginBottom: "5px" }}
                    >
                      Model Name*:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="tableRelatedField"
                      value={tableModelName.title}
                      onChange={handleTableModelNameChange}
                      placeholder="Enter model name"
                      required
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        marginLeft: "5px",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                        lineBreak: "anywhere",
                      }}
                    >
                      {/* Technical name: {tableModelName.technicalName} */}
                    </span>
                  </div>
                  {Array.isArray(tableColumns) && tableColumns?.length > 0 && (
                    <div>
                      <label htmlFor="">Added Fields:</label>
                      <div
                        className="d-flex flex-column justify-content-center gap-2"
                        style={{ width: "100%" }}
                      >
                        {tableColumns?.map((column, inx) => (
                          <div
                            key={column.technicalName}
                            className="d-flex justify-content-between align-content-center"
                          >
                            <div style={{ width: "80%" }}>
                              <strong className="me-2">{inx + 1}</strong>
                              <span>{column.title}</span>
                              <span
                                className="ms-3"
                                style={{ fontSize: "14px" }}
                              >
                                Type: {column.type}
                              </span>
                            </div>
                            <button
                              className="table_field_remove_button"
                              onClick={(e) => handleRemoveTableColumn(e, inx)}
                            >
                              <FiDelete />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {showTableFieldInputBox ? (
                    <div style={{ width: "100%" }}>
                      <label htmlFor="">Field Details</label>
                      <div
                        className="table_newfield d-flex justify-content-between align-items-start gap-2"
                        style={{ width: "100%" }}
                      >
                        <div className="form-group" style={{ width: "100%" }}>
                          <label
                            htmlFor="tableFieldName"
                            style={{ marginBottom: "5px", fontSize: "14px" }}
                          >
                            Field Name*:
                          </label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            id="tableFieldName"
                            value={newTableField.title}
                            onChange={handleTableFieldDetailChange}
                            placeholder="Enter field name"
                            style={{ height: "35px", fontSize: "14px" }}
                            required
                          />
                          <span
                            style={{
                              fontSize: "11px",
                              marginLeft: "5px",
                              wordWrap: "break-word",
                              wordBreak: "break-word",
                              lineBreak: "anywhere",
                            }}
                          >
                            Technical name: {newTableField.technicalName}
                          </span>
                        </div>
                        <div className="form-group" style={{ width: "100%" }}>
                          <label
                            htmlFor="tableFieldType"
                            style={{ marginBottom: "5px", fontSize: "14px" }}
                          >
                            Field Type:
                          </label>
                          <CustomDropdown
                            name="type"
                            defaultValue={newTableField.type || null}
                            options={tableFieldTypes?.map((ftype) => {
                              return {
                                value: ftype,
                                label: ftype,
                              };
                            })}
                            onChange={(event) =>
                              handleTableFieldDetailChange(event)
                            }
                            width={"95%"}
                            height={"35px"}
                            border="1px solid #ddd"
                          />
                        </div>
                      </div>
                      {newTableField.type &&
                        newTableField.type === "selection" && (
                          <div className="d-flex flex-column justify-content-center align-items-start gap-1">
                            <div
                              className="form-group"
                              style={{ width: "100%" }}
                            >
                              <label
                                htmlFor="tableFieldSelectionOption"
                                style={{
                                  marginBottom: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                Add option*:
                              </label>
                              <input
                                type="text"
                                // name="title"
                                className="form-control"
                                id="tableFieldSelectionOption"
                                value={newTableFieldOption}
                                onChange={(e) =>
                                  setNewTableFieldOption(e.target.value)
                                }
                                placeholder="Enter option"
                                style={{ height: "35px", fontSize: "14px" }}
                                required
                              />
                            </div>
                            <button
                              className="table_newfield_option_add_button"
                              onClick={handleAddTableFieldSelectOption}
                            >
                              Add Option
                            </button>
                            {newTableFieldSelectOptions.length > 0 && (
                              <div
                                className="d-flex flex-column justify-content-start align-items-start gap-2"
                                style={{ width: "100%" }}
                              >
                                <label>Field Options:</label>
                                {newTableFieldSelectOptions.length > 0 &&
                                  newTableFieldSelectOptions.map(
                                    (opt, index) => (
                                      <div
                                        key={`new_table_select_option_${index}`}
                                        className="d-flex justify-content-between align-items-start"
                                        style={{ width: "100%" }}
                                      >
                                        <div
                                          className="d-flex justify-content-start align-items-center gap-2"
                                          style={{ width: "80%" }}
                                        >
                                          <strong>{index + 1}</strong>
                                          <span>{opt}</span>
                                        </div>
                                        <button
                                          className="table_field_remove_button"
                                          onClick={(e) =>
                                            handleRemoveTableFieldSelectOption(
                                              e,
                                              index
                                            )
                                          }
                                        >
                                          <FiDelete />
                                        </button>
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      {newTableField.type &&
                        ["many2many", "many2one", "one2many"].includes(
                          newTableField.type
                        ) && (
                          <>
                            <div
                              className="form-group"
                              style={{ width: "100%", marginBottom: "5px" }}
                            >
                              <label
                                htmlFor="tableFieldReletedModel"
                                style={{
                                  marginBottom: "5px",
                                  fontSize: "14px",
                                }}
                              >
                                Releted Model*:
                              </label>
                              <CustomDropdown
                                // name="type"
                                defaultValue={newTableFieldReletedModel || null}
                                options={allOdooModels?.map((model) => {
                                  return {
                                    value: model.model,
                                    label: model.model,
                                  };
                                })}
                                onChange={(event) =>
                                  setNewTableFieldReletedModel(
                                    event.target.value
                                  )
                                }
                                width={"95%"}
                                height={"35px"}
                                border="1px solid #ddd"
                              />
                            </div>
                            {newTableField.type === "one2many" && (
                              <div
                                className="form-group"
                                style={{ width: "100%", marginBottom: "5px" }}
                              >
                                <label
                                  htmlFor="tableFieldRelationField"
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Reletion Field*:
                                </label>
                                <input
                                  type="text"
                                  // name="title"
                                  className="form-control"
                                  id="tableFieldRelationField"
                                  list="newTableRelationFieldList"
                                  value={newTableFieldReletionField}
                                  onChange={(e) =>
                                    setNewTableFieldReletionField(
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter related field"
                                  style={{ height: "35px", fontSize: "14px" }}
                                  required
                                />
                                <datalist id="newTableRelationFieldList">
                                  {filteredRelatedField?.map(
                                    (relatedField, index) => (
                                      <option
                                        key={index}
                                        value={relatedField.name}
                                      ></option>
                                    )
                                  )}
                                </datalist>
                                {/* <CustomDropdown
                                  // name="type"
                                  defaultValue={
                                    newTableFieldReletedModel || null
                                  }
                                  options={allOdooModels?.map((model) => {
                                    return {
                                      value: model.model,
                                      label: model.model,
                                    };
                                  })}
                                  onChange={(event) =>
                                    setNewTableFieldReletionField(
                                      event.target.value
                                    )
                                  }
                                  width={"95%"}
                                  height={"35px"}
                                  border="1px solid #ddd"
                                /> */}
                              </div>
                            )}
                          </>
                        )}
                      {newTableField.type &&
                        ["many2many", "many2one", "one2many"].includes(
                          newTableField.type
                        ) && (
                          <div
                            className="form-group"
                            style={{ width: "100%", marginBottom: "5px" }}
                          >
                            <label>Domain</label>
                            {newTableRelatedModelDomain ? (
                              <div
                                style={{ width: "90%" }}
                                className="ms-3 d-flex justify-content-between align-items-center"
                              >
                                <p className="mb-0">
                                  <span>
                                    <TbPointFilled />
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      wordBreak: "break-all",
                                      lineBreak: "anywhere",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {newTableRelatedModelDomain}
                                  </span>
                                </p>
                                <button
                                  className="fdu_group_delete"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setNewTableRelatedModelDomain(null);
                                  }}
                                >
                                  <GiCancel />
                                </button>
                              </div>
                            ) : (
                              <DomainComponent
                                selectedModalFields={
                                  newTableFieldReletedModelFields
                                }
                                newTableField={newTableField}
                                setNewTableRelatedModelDomain={
                                  setNewTableRelatedModelDomain
                                }
                              />
                            )}
                          </div>
                        )}
                      {/* code for on change in table */}
                      <div
                        className="fb_field_details_form_group_checkbox_container mt-1 mb-1"
                        style={{ width: "100%" }}
                      >
                        <label
                          htmlFor="fieldisOnChange"
                          className="label_checkbox"
                          style={{ fontSize: "14px" }}
                        >
                          On-Change
                        </label>
                        <label className="checkbox_container">
                          <input
                            type="checkbox"
                            id="fieldisOnChange"
                            name="isOnChange"
                            checked={on_change.isOnChange}
                            onChange={handleChangeOnChange}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      {on_change.isOnChange && (
                        <>
                          <div
                            className="fb_field_details_form_group"
                            style={{ width: "100%" }}
                          >
                            <label htmlFor="fieldRelatedField">
                              Related Field
                            </label>
                            <select
                              name="on_change_relation"
                              id="fieldRelatedField"
                              onChange={handleChangeOnChange}
                              value={on_change.on_change_relation || ""}
                              style={{ width: "95%" }}
                            >
                              <option value="">Select</option>
                              {tableColumns &&
                                tableColumns
                                  ?.filter((field) => field.type === "many2one")
                                  .map((field) => (
                                    <option
                                      key={`${field.technicalName}_${field.title}`}
                                      value={field.technicalName}
                                    >
                                      {field.title}
                                    </option>
                                  ))}
                            </select>
                          </div>
                          <div
                            className="fb_field_details_form_group mb-1"
                            style={{ width: "100%" }}
                          >
                            <label htmlFor="fieldRelatedModelFields">
                              Related Model Field
                            </label>
                            <select
                              name="on_change_relation_model_field"
                              id="fieldRelatedModelFields"
                              onChange={handleChangeOnChange}
                              value={
                                on_change.on_change_relation_model_field || ""
                              }
                              style={{ width: "95%" }}
                            >
                              <option value="">Select</option>
                              {tableRelatedModelFields &&
                                tableRelatedModelFields
                                  .filter(
                                    (field) =>
                                      field !== "" &&
                                      field !== null &&
                                      field !== undefined
                                  )
                                  .map((field, index) => (
                                    <option
                                      key={`${field}_${field}_${index}`}
                                      value={field}
                                    >
                                      {field}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </>
                      )}

                      <div className="d-flex gap-2">
                        <button
                          className="table_newfield_add_button"
                          onClick={handleAddTableColumn}
                        >
                          Add Field
                        </button>
                        <button
                          className="table_newfield_add_button"
                          onClick={() => setShowTableFieldInputBox(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      className="table_newfield_add_button"
                      onClick={() => setShowTableFieldInputBox(true)}
                    >
                      Add More Field
                    </button>
                  )}
                  {subTotalEligibleFields &&
                    subTotalEligibleFields?.length > 0 && (
                      <>
                        {showSubtotalEquationFields ? (
                          <div style={{ width: "100%" }}>
                            <label htmlFor="">Subtotal Field</label>
                            <div
                              className="table_newfield d-flex justify-content-between align-items-start gap-2"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="form-group"
                                style={{ width: "100%" }}
                              >
                                <label
                                  htmlFor="tableFieldName"
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Field Name*:
                                </label>
                                <input
                                  type="text"
                                  name="title"
                                  className="form-control"
                                  id="tableFieldName"
                                  value={newTableField.title}
                                  onChange={handleTableFieldDetailChange}
                                  placeholder="Enter field name"
                                  style={{ height: "35px", fontSize: "14px" }}
                                  required
                                />
                                <span
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "5px",
                                    wordWrap: "break-word",
                                    wordBreak: "break-word",
                                    lineBreak: "anywhere",
                                  }}
                                >
                                  Technical name: {newTableField.technicalName}
                                </span>
                              </div>
                              <div
                                className="form-group"
                                style={{ width: "100%" }}
                              >
                                <label
                                  htmlFor="tableFieldType"
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  Field Type:
                                </label>
                                <CustomDropdown
                                  name="type"
                                  defaultValue={newTableField.type || null}
                                  options={tableFieldTypes
                                    .filter((ftype) =>
                                      ["float", "integer"].includes(ftype)
                                    )
                                    ?.map((ftype) => {
                                      return {
                                        value: ftype,
                                        label: ftype,
                                      };
                                    })}
                                  onChange={(event) =>
                                    handleTableFieldDetailChange(event)
                                  }
                                  width={"95%"}
                                  height={"35px"}
                                  border="1px solid #ddd"
                                />
                              </div>
                            </div>
                            <div style={{ width: "100%" }}>
                              <label htmlFor="">Generate Equation </label>
                              <input
                                type="text"
                                name="equation"
                                className="form-control"
                                id="tableFieldName"
                                value={subTotalEquation}
                                placeholder="create equation"
                                style={{ height: "35px", fontSize: "14px" }}
                                readOnly
                              />
                              <div className="d-flex justify-content-start align-items-start gap-2">
                                <select
                                  value={equationOperand}
                                  onChange={(e) => {
                                    handleAddOperand(e.target.value);
                                    setEquationOperand("");
                                  }}
                                >
                                  <option value="">Select Operand</option>
                                  {subTotalEligibleFields &&
                                    subTotalEligibleFields.map((field) => (
                                      <option
                                        key={field.technicalName}
                                        value={field.technicalName}
                                      >
                                        {field.title}
                                      </option>
                                    ))}
                                </select>
                                <select
                                  value={equationOperator}
                                  onChange={(e) =>
                                    handleSetOperator(e.target.value)
                                  }
                                >
                                  <option value="">Select Operator</option>
                                  {["+", "-", "*", "/"].map((op) => (
                                    <option key={op} value={op}>
                                      {op}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  className="table_newfield_add_button"
                                  onClick={handleClear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                            <div className="d-flex gap-2">
                              <button
                                className="table_newfield_add_button"
                                onClick={handleAddTableColumn}
                              >
                                Add Field
                              </button>
                              <button
                                className="table_newfield_add_button"
                                onClick={() =>
                                  setShowSubtotalEquationFields(false)
                                }
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        ) : (
                          <button
                            className="table_newfield_add_button"
                            onClick={() => setShowSubtotalEquationFields(true)}
                          >
                            Add Subtotal Field
                          </button>
                        )}
                        {/* code for total field */}
                        <div className="fb_field_details_form_group_checkbox_container mt-2">
                          <label
                            htmlFor="fieldTotalTable"
                            className="label_checkbox"
                          >
                            Add Total Field
                          </label>
                          <label className="checkbox_container">
                            <input
                              type="checkbox"
                              id="fieldTotalTable"
                              name="isTotalField"
                              checked={totalFieldForTable.isTotalField}
                              onChange={handleTotalFieldChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                        {totalFieldForTable.isTotalField && (
                          <div className="d-flex justify-content-start align-items-start gap-2 mt-1">
                            <select
                              name="totalField_field"
                              value={totalFieldForTable.totalField_field}
                              onChange={handleTotalFieldChange}
                            >
                              <option value="">Select Field</option>
                              {subTotalEligibleFields &&
                                subTotalEligibleFields.map((field) => (
                                  <option
                                    key={field.technicalName}
                                    value={field.technicalName}
                                  >
                                    {field.title}
                                  </option>
                                ))}
                            </select>
                            <select
                              name="totalField_operator"
                              value={
                                totalFieldForTable.totalField_operator || "+"
                              }
                              onChange={handleTotalFieldChange}
                            >
                              <option value="">Select Operator</option>
                              {["+", "-", "*", "/"].map((op) => (
                                <option key={op} value={op}>
                                  {op}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </>
                    )}
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowModal2(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn modal_save_button"
                onClick={handleModal2Submit}
              >
                Add Table
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FormBoard);

// ================================================================
// builderType === "form" ? (
//   // code for form builder
//   <div
//     className="inputfields"
//     onDragOver={(e) => handleDragOver(e)}
//     onDrop={(e) => handleDrop(e)}
//   >
//     {formData &&
//       formData.map((row, rowIndex) => (
//         <div
//           key={rowIndex}
//           className="fb_row"
//           onDragOver={(e) => handleDragOver(e, rowIndex)}
//           onDrop={(e) => handleDrop(e, rowIndex)}
//         >
//           {row.map((field, columnIndex) => (
//             <div
//               key={columnIndex}
//               className="fb_inputfield"
//               title={field.tooltip}
//               onDragOver={(e) =>
//                 handleDragOver(e, rowIndex, columnIndex, null, null)
//               }
//               onDrop={(e) =>
//                 handleDrop(e, rowIndex, columnIndex, null, null)
//               }
//               onClick={(e) =>
//                 handleClick(e, field, rowIndex, columnIndex)
//               }
//               draggable
//               onDragStart={(e) =>
//                 handleDragStart(e, field, { rowIndex, columnIndex })
//               }
//             >
//               {["button", "object", "action"].includes(field.type) ? (
//                 <>
//                   {/* // code for field if type is button */}
//                   {["default-statinfo", "statinfo"].includes(
//                     field.widget
//                   ) ? (
//                     <button
//                       className={`${
//                         field?.alignment === "center"
//                           ? "align-self-center"
//                           : field?.alignment === "left"
//                           ? "align-self-start"
//                           : "align-self-end"
//                       }`}
//                       style={{
//                         border: "1px solid #2e2e2e",
//                         width: "100px",
//                         backgroundColor: "transparent",
//                         color: "#3e3e3e",
//                         fontSize: "16px",
//                         height: "50px",
//                         textAlign: "center",
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         overflow: "hidden",
//                       }}
//                     >
//                       {field.title}
//                     </button>
//                   ) : (
//                     <button
//                       type="submit"
//                       className={`btn ${
//                         field?.alignment === "right"
//                           ? "align-self-end"
//                           : field?.alignment === "left"
//                           ? "align-self-start"
//                           : "align-self-center"
//                       } ${
//                         field.odooButtonColor
//                           ? field.odooButtonColor
//                           : "btn-outline-dark"
//                       } `}
//                     >
//                       {field.title}
//                     </button>
//                   )}
//                 </>
//               ) : field.type === "selection" ? (
//                 // code for field if type is selection
//                 <>
//                   <label htmlFor={field.type}>
//                     {field.title}
//                     {field?.isRequired && "*"}
//                   </label>
//                   <CustomDropdown options={field.options} />
//                 </>
//               ) : field.type === "label" ? (
//                 // code for field if type is label
//                 <>
//                   <span
//                     className={`${
//                       field?.alignment === "center"
//                         ? "align-self-center"
//                         : field?.alignment === "right"
//                         ? "align-self-end"
//                         : "align-self-start"
//                     }`}
//                     style={{
//                       fontSize: `${field?.fontSize || "24"}px`,
//                       fontWeight: `${field?.fontWeight || "500"}`,
//                     }}
//                   >
//                     {field.title}
//                   </span>
//                 </>
//               ) : field.type === "static_image" ? (
//                 // code for field if type is label
//                 <>
//                   {field?.defaultValue !== "" ? (
//                     <img
//                       src={field?.defaultValue || ""}
//                       className={`${
//                         field?.alignment === "center"
//                           ? "align-self-center"
//                           : field?.alignment === "left"
//                           ? "align-self-start"
//                           : "align-self-end"
//                       }`}
//                       width="100px"
//                       height="100px"
//                       style={{
//                         border: "1px solid #383838",
//                       }}
//                       alt="static img"
//                     />
//                   ) : (
//                     <div
//                       style={{
//                         width: "100px",
//                         height: "100px",
//                         border: "1px solid #383838",
//                         fontSize: "60px",
//                       }}
//                       className={`${
//                         field?.alignment === "center"
//                           ? "align-self-center"
//                           : field?.alignment === "left"
//                           ? "align-self-start"
//                           : "align-self-end"
//                       } d-flex justify-content-center align-items-center`}
//                     >
//                       <FaRegImage />
//                     </div>
//                   )}
//                 </>
//               ) : field.type === "separator" ? (
//                 // code for field if type is separator
//                 <>
//                   {/* <label htmlFor={field.type}>{field.title}</label> */}
//                   <div style={{ width: "100%" }}>
//                     <hr
//                       style={{
//                         margin: "5px 0",
//                         border: "none",
//                         borderTop: "2px solid #333",
//                       }}
//                     />
//                     <hr
//                       style={{
//                         margin: "5px 0",
//                         border: "none",
//                         borderTop: "2px solid #333",
//                       }}
//                     />
//                   </div>
//                 </>
//               ) : field.type === "table" ? (
//                 // code for field if type is table
//                 <>
//                   <label htmlFor={field.type}>{field.title}</label>
//                   <table
//                     style={{ width: "90%", alignSelf: "center" }}
//                     className="table table-secondary table-bordered"
//                   >
//                     <thead className="">
//                       <tr className="d-table-row table-light">
//                         {field.columns &&
//                           field.columns.map((col, i) => (
//                             <th
//                               key={i}
//                               scope="col"
//                               className="d-table-cell"
//                             >
//                               {typeof col === "string" ? col : col.title}
//                             </th>
//                           ))}
//                       </tr>
//                     </thead>
//                     <tbody className="">
//                       <tr className="d-table-row">
//                         {field.columns &&
//                           field.columns.map((_, i) => (
//                             <td className="d-table-cell" key={i}></td>
//                           ))}
//                       </tr>
//                     </tbody>
//                   </table>
//                 </>
//               ) : field.type === "chatter" ? (
//                 // code for field if type is chatter
//                 <>
//                   <label htmlFor={field.type}>{field.title}</label>
//                   {/* <div style={{ width: "100%" }}>
//                     <hr
//                       style={{
//                         margin: "5px 0",
//                         border: "none",
//                         borderTop: "2px solid #ccc",
//                       }}
//                     />
//                   </div> */}
//                   {/* <IoChatbubbles style={{ fontSize: "40px" }} /> */}
//                   <img
//                     src={ChatterScreen}
//                     alt="chatter screen"
//                     style={{ width: "100%" }}
//                   />
//                 </>
//               ) : field.type === "ribbon" ? (
//                 // code for field if type is ribbon
//                 <>
//                   <div
//                     className={`ribbon_container ${
//                       field?.ribbonBgColor
//                         ? field.ribbonBgColor
//                         : "bg-danger"
//                     }`}
//                   >
//                     <span>{field.title}</span>
//                   </div>
//                 </>
//               ) : field.type === "boolean" ? (
//                 // code for field if type is boolean
//                 <>
//                   <div
//                     className="d-flex justify-content-start align-items-center gap-5"
//                     style={{ width: "100%" }}
//                   >
//                     <label htmlFor={field.type}>
//                       {field.title}
//                       {field?.isRequired && "*"}
//                     </label>
//                     <input
//                       type="checkbox"
//                       value={field.defaultValue}
//                       required={field.isRequired}
//                       readOnly={field.isReadOnly}
//                       style={{ width: "17px" }}
//                     />
//                   </div>
//                 </>
//               ) : field.type === "html" ? (
//                 // code for field if type is chatter
//                 <>
//                   <label htmlFor={field.type}>{field.title}</label>
//                   <div style={{ width: "100%" }}>
//                     <hr
//                       style={{
//                         margin: "5px 0",
//                         border: "none",
//                         borderTop: "2px solid #333",
//                       }}
//                     />
//                   </div>
//                   <IoGlobeOutline style={{ fontSize: "40px" }} />
//                 </>
//               ) : field.type === "oe_avatar" ? (
//                 // code for field if type is oe_avatar(form image)
//                 <>
//                   <div
//                     className={`${
//                       field?.alignment === "center"
//                         ? "align-self-center"
//                         : field?.alignment === "left"
//                         ? "align-self-start"
//                         : "align-self-end"
//                     } border d-flex justify-content-center align-items-center`}
//                     style={{
//                       width: "70px",
//                       height: "70px",
//                       fontSize: `${field?.fontSize || "24"}px`,
//                       fontWeight: `${field?.fontWeight || "500"}`,
//                     }}
//                   >
//                     {field?.defaultValue !== "" ? (
//                       <img
//                         src={field?.defaultValue || ""}
//                         alt="form_img"
//                         width="90%"
//                       />
//                     ) : (
//                       <FaPlus />
//                     )}
//                   </div>
//                 </>
//               ) : field.type === "binary" ? (
//                 field.widget === "image" ? (
//                   // code for field if type is image/binary
//                   <>
//                     <label htmlFor={field.type}>
//                       {field.title}
//                       {field?.isRequired && "*"}
//                     </label>
//                     <CiCamera
//                       style={{ fontSize: "40px", color: "#2e2e2e" }}
//                     />
//                   </>
//                 ) : (
//                   // code for field if type is file/binary
//                   <>
//                     <label htmlFor={field.type}>
//                       {field.title}
//                       {field?.isRequired && "*"}
//                     </label>
//                     <input
//                       type="file"
//                       placeholder={field.placeholder}
//                       required={field.isRequired}
//                       readOnly={field.isReadOnly}
//                     />
//                   </>
//                 )
//               ) : (
//                 // code for field if type is anything besides tab and group
//                 <>
//                   <label htmlFor={field.type}>
//                     {field.title}
//                     {field?.isRequired && "*"}
//                   </label>
//                   {!["group", "tab", "remark_history"].includes(
//                     field.type
//                   ) && (
//                     <input
//                       type={
//                         field.type === "datetime"
//                           ? "datetime-local"
//                           : field.type === "age"
//                           ? "date"
//                           : field.type
//                       }
//                       placeholder={
//                         field.placeholder || `Enter ${field.title}`
//                       }
//                       value={field.defaultValue}
//                       required={field.isRequired}
//                       readOnly={field.isReadOnly}
//                     />
//                   )}
//                 </>
//               )}
//               {/* code for field if type is remark_history */}
//               {field.type === "remark_history" && (
//                 <>
//                   <ul className="nav nav-tabs">
//                     {field.tabs &&
//                       field.tabs.map((tab, tabIndex) => (
//                         <li className="nav-item" key={tabIndex}>
//                           <button
//                             className={`nav-link ${
//                               field.activeTab === tabIndex ? "active" : ""
//                             }`}
//                           >
//                             {tab.tabName}
//                           </button>
//                         </li>
//                       ))}
//                   </ul>
//                   <div className="tab-content fb_group">
//                     {field.tabs &&
//                       field.tabs[field.activeTab || 0].content.map(
//                         (tabRow, tabRowIndex) => (
//                           <div
//                             key={tabRowIndex}
//                             className={`fb_tab_row `}
//                           >
//                             {tabRow.length > 0
//                               ? tabRow.map((tabField, tabFieldIndex) => (
//                                   <div
//                                     key={tabFieldIndex}
//                                     className="fb_inputfield"
//                                     title={tabField.tooltip}
//                                     onClick={(e) =>
//                                       handleTabFieldClick(
//                                         e,
//                                         tabField,
//                                         rowIndex,
//                                         columnIndex,
//                                         field.activeTab,
//                                         tabRowIndex,
//                                         tabFieldIndex
//                                       )
//                                     }
//                                   >
//                                     {tabField.type === "table" ? (
//                                       // code for field if type is table
//                                       <>
//                                         <label htmlFor={tabField.type}>
//                                           {tabField.title}
//                                         </label>
//                                         <table
//                                           style={{
//                                             width: "90%",
//                                             alignSelf: "center",
//                                           }}
//                                           className="table table-secondary table-bordered"
//                                         >
//                                           <thead className="">
//                                             <tr className="d-table-row table-light">
//                                               {tabField.columns &&
//                                                 tabField.columns.map(
//                                                   (col, i) => (
//                                                     <th
//                                                       key={i}
//                                                       scope="col"
//                                                       className="d-table-cell"
//                                                     >
//                                                       {typeof col ===
//                                                       "string"
//                                                         ? col
//                                                         : col.title}
//                                                     </th>
//                                                   )
//                                                 )}
//                                             </tr>
//                                           </thead>
//                                           <tbody className="">
//                                             <tr className="d-table-row">
//                                               {tabField.columns &&
//                                                 tabField.columns.map(
//                                                   (_, i) => (
//                                                     <td
//                                                       className="d-table-cell"
//                                                       key={i}
//                                                     ></td>
//                                                   )
//                                                 )}
//                                             </tr>
//                                           </tbody>
//                                         </table>
//                                       </>
//                                     ) : tabField.type === "separator" ? (
//                                       // code for field if type is separator
//                                       <>
//                                         {/* <label htmlFor={field.type}>{field.title}</label> */}
//                                         <div style={{ width: "100%" }}>
//                                           <hr
//                                             style={{
//                                               margin: "5px 0",
//                                               border: "none",
//                                               borderTop: "2px solid #333",
//                                             }}
//                                           />
//                                           <hr
//                                             style={{
//                                               margin: "5px 0",
//                                               border: "none",
//                                               borderTop: "2px solid #333",
//                                             }}
//                                           />
//                                         </div>
//                                       </>
//                                     ) : tabField.type === "selection" ? (
//                                       <>
//                                         <label htmlFor={tabField.type}>
//                                           {tabField.title}
//                                           {tabField?.isRequired && "*"}
//                                         </label>
//                                         <select
//                                           required={tabField.isRequired}
//                                         >
//                                           {tabField.options.map(
//                                             (option, index) => (
//                                               <option
//                                                 key={index}
//                                                 value={option}
//                                               >
//                                                 {option}
//                                               </option>
//                                             )
//                                           )}
//                                         </select>
//                                       </>
//                                     ) : (
//                                       // code for field if type is anything besides tab and group
//                                       <>
//                                         <label htmlFor={tabField.type}>
//                                           {tabField.title}
//                                           {tabField?.isRequired && "*"}
//                                         </label>
//                                         {!["group", "tab"].includes(
//                                           tabField.type
//                                         ) && (
//                                           <input
//                                             type={tabField.type}
//                                             placeholder={
//                                               tabField.placeholder ||
//                                               `Enter ${tabField.title}`
//                                             }
//                                             value={tabField.defaultValue}
//                                             required={tabField.isRequired}
//                                             readOnly={tabField.isReadOnly}
//                                           />
//                                         )}
//                                       </>
//                                     )}
//                                   </div>
//                                 ))
//                               : ""}
//                           </div>
//                         )
//                       )}
//                   </div>
//                 </>
//               )}

//               {/* code for field if type is tab */}
//               {field.type === "tab" && (
//                 <>
//                   <ul className="nav nav-tabs">
//                     {field.tabs &&
//                       field.tabs.map((tab, tabIndex) => (
//                         <li className="nav-item" key={tabIndex}>
//                           <button
//                             className={`nav-link ${
//                               field.activeTab === tabIndex ? "active" : ""
//                             }`}
//                             onClick={(e) =>
//                               handleTabChange(
//                                 e,
//                                 rowIndex,
//                                 columnIndex,
//                                 tabIndex,
//                                 tab.tabName
//                               )
//                             }
//                           >
//                             {tab.tabName}
//                           </button>
//                         </li>
//                       ))}
//                     <li className="nav-item">
//                       <button
//                         className="nav-link"
//                         onClick={() => addNewTab(rowIndex, columnIndex)}
//                       >
//                         + Add Tab
//                       </button>
//                     </li>
//                   </ul>
//                   <div className="tab-content fb_group">
//                     {field.tabs &&
//                       field.tabs[field.activeTab || 0].content.map(
//                         (tabRow, tabRowIndex) => (
//                           <div
//                             key={tabRowIndex}
//                             className={`fb_tab_row `}
//                             onDragOver={(e) =>
//                               handleDragOver(
//                                 e,
//                                 rowIndex,
//                                 columnIndex,
//                                 null,
//                                 null,
//                                 field.activeTab,
//                                 tabRowIndex
//                               )
//                             }
//                             onDrop={(e) =>
//                               handleDrop(
//                                 e,
//                                 rowIndex,
//                                 columnIndex,
//                                 null,
//                                 null,
//                                 field.activeTab,
//                                 tabRowIndex
//                               )
//                             }
//                           >
//                             {tabRow.length > 0 ? (
//                               tabRow.map((tabField, tabFieldIndex) => (
//                                 <div
//                                   key={tabFieldIndex}
//                                   className="fb_inputfield"
//                                   title={tabField.tooltip}
//                                   onDragOver={(e) =>
//                                     handleDragOver(
//                                       e,
//                                       rowIndex,
//                                       columnIndex,
//                                       null,
//                                       null,
//                                       field.activeTab,
//                                       tabRowIndex,
//                                       tabFieldIndex
//                                     )
//                                   }
//                                   onDrop={(e) =>
//                                     handleDrop(
//                                       e,
//                                       rowIndex,
//                                       columnIndex,
//                                       null,
//                                       null,
//                                       field.activeTab,
//                                       tabRowIndex,
//                                       tabFieldIndex
//                                     )
//                                   }
//                                   onClick={(e) =>
//                                     handleTabFieldClick(
//                                       e,
//                                       tabField,
//                                       rowIndex,
//                                       columnIndex,
//                                       field.activeTab,
//                                       tabRowIndex,
//                                       tabFieldIndex
//                                     )
//                                   }
//                                   draggable
//                                   onDragStart={(e) =>
//                                     handleDragStart(e, tabField, {
//                                       rowIndex,
//                                       columnIndex,
//                                       tabIndex: field.activeTab,
//                                       tabRowIndex,
//                                       tabFieldIndex,
//                                     })
//                                   }
//                                 >
//                                   {[
//                                     "button",
//                                     "object",
//                                     "action",
//                                   ].includes(tabField.type) ? (
//                                     <button
//                                       type="submit"
//                                       className={`btn ${
//                                         tabField?.alignment === "right"
//                                           ? "align-self-end"
//                                           : tabField?.alignment === "left"
//                                           ? "align-self-start"
//                                           : "align-self-center"
//                                       } ${
//                                         tabField.odooButtonColor
//                                           ? tabField.odooButtonColor
//                                           : "btn-outline-dark"
//                                       } `}
//                                     >
//                                       {tabField.title}
//                                     </button>
//                                   ) : tabField.type === "selection" ? (
//                                     <>
//                                       <label htmlFor={tabField.type}>
//                                         {tabField.title}
//                                         {tabField?.isRequired && "*"}
//                                       </label>
//                                       <select
//                                         required={tabField.isRequired}
//                                       >
//                                         {tabField.options.map(
//                                           (option, index) => (
//                                             <option
//                                               key={index}
//                                               value={option}
//                                             >
//                                               {option}
//                                             </option>
//                                           )
//                                         )}
//                                       </select>
//                                     </>
//                                   ) : tabField.type === "label" ? (
//                                     // code for field if type is label
//                                     <>
//                                       {/* <label htmlFor={field.type}>{field.title}</label> */}
//                                       <span
//                                         className={`${
//                                           field?.alignment === "center"
//                                             ? "align-self-center"
//                                             : field?.alignment === "right"
//                                             ? "align-self-end"
//                                             : "align-self-start"
//                                         }`}
//                                         style={{
//                                           fontSize: `${
//                                             tabField?.fontSize || "24"
//                                           }px`,
//                                           fontWeight: `${
//                                             tabField?.fontWeight || "500"
//                                           }`,
//                                         }}
//                                       >
//                                         {tabField.title}
//                                       </span>
//                                       {/* <div style={{ width: "100%" }}>
//                                         <hr
//                                           style={{
//                                             margin: "5px 0",
//                                             border: "none",
//                                             borderTop: "2px solid #ccc",
//                                           }}
//                                         />
//                                       </div> */}
//                                     </>
//                                   ) : tabField.type === "separator" ? (
//                                     // code for field if type is separator
//                                     <>
//                                       {/* <label htmlFor={field.type}>{field.title}</label> */}
//                                       <div style={{ width: "100%" }}>
//                                         <hr
//                                           style={{
//                                             margin: "5px 0",
//                                             border: "none",
//                                             borderTop: "2px solid #333",
//                                           }}
//                                         />
//                                         <hr
//                                           style={{
//                                             margin: "5px 0",
//                                             border: "none",
//                                             borderTop: "2px solid #333",
//                                           }}
//                                         />
//                                       </div>
//                                     </>
//                                   ) : tabField.type === "table" ? (
//                                     // code for field if type is table
//                                     <>
//                                       <label htmlFor={tabField.type}>
//                                         {tabField.title}
//                                       </label>
//                                       <table
//                                         style={{
//                                           width: "90%",
//                                           alignSelf: "center",
//                                         }}
//                                         className="table table-secondary table-bordered"
//                                       >
//                                         <thead className="">
//                                           <tr className="d-table-row table-light">
//                                             {tabField.columns &&
//                                               tabField.columns.map(
//                                                 (col, i) => (
//                                                   <th
//                                                     key={i}
//                                                     scope="col"
//                                                     className="d-table-cell"
//                                                   >
//                                                     {typeof col ===
//                                                     "string"
//                                                       ? col
//                                                       : col.title}
//                                                   </th>
//                                                 )
//                                               )}
//                                           </tr>
//                                         </thead>
//                                         <tbody className="">
//                                           <tr className="d-table-row">
//                                             {tabField.columns &&
//                                               tabField.columns.map(
//                                                 (_, i) => (
//                                                   <td
//                                                     className="d-table-cell"
//                                                     key={i}
//                                                   ></td>
//                                                 )
//                                               )}
//                                           </tr>
//                                         </tbody>
//                                       </table>
//                                     </>
//                                   ) : tabField.type === "boolean" ? (
//                                     // code for field if type is boolean
//                                     <>
//                                       <div className="d-flex justify-content-start align-items-center gap-4">
//                                         <label htmlFor={tabField.type}>
//                                           {tabField.title}
//                                           {tabField?.isRequired && "*"}
//                                         </label>
//                                         <input
//                                           type={
//                                             tabField.type === "boolean"
//                                               ? "checkbox"
//                                               : "text"
//                                           }
//                                           placeholder={
//                                             tabField.placeholder ||
//                                             `Enter ${tabField.title}`
//                                           }
//                                           value={tabField.defaultValue}
//                                           required={tabField.isRequired}
//                                           readOnly={tabField.isReadOnly}
//                                         />
//                                       </div>
//                                     </>
//                                   ) : tabField.type === "html" ? (
//                                     // code for field if type is chatter
//                                     <>
//                                       <label htmlFor={tabField.type}>
//                                         {tabField.title}
//                                       </label>
//                                       <div style={{ width: "100%" }}>
//                                         <hr
//                                           style={{
//                                             margin: "5px 0",
//                                             border: "none",
//                                             borderTop: "2px solid #ccc",
//                                           }}
//                                         />
//                                       </div>
//                                       <IoGlobeOutline
//                                         style={{ fontSize: "40px" }}
//                                       />
//                                     </>
//                                   ) : tabField.type === "static_image" ? (
//                                     // code for field if type is label
//                                     <>
//                                       {tabField?.defaultValue !== "" ? (
//                                         <img
//                                           src={
//                                             tabField?.defaultValue || ""
//                                           }
//                                           className={`${
//                                             tabField?.alignment ===
//                                             "center"
//                                               ? "align-self-center"
//                                               : tabField?.alignment ===
//                                                 "left"
//                                               ? "align-self-start"
//                                               : "align-self-end"
//                                           }`}
//                                           width="100px"
//                                           height="100px"
//                                           style={{
//                                             border: "1px solid #383838",
//                                           }}
//                                           alt="static img"
//                                         />
//                                       ) : (
//                                         <div
//                                           style={{
//                                             width: "100px",
//                                             height: "100px",
//                                             border: "1px solid #383838",
//                                             fontSize: "60px",
//                                           }}
//                                           className={`${
//                                             tabField?.alignment ===
//                                             "center"
//                                               ? "align-self-center"
//                                               : tabField?.alignment ===
//                                                 "left"
//                                               ? "align-self-start"
//                                               : "align-self-end"
//                                           } d-flex justify-content-center align-items-center`}
//                                         >
//                                           <FaRegImage />
//                                         </div>
//                                       )}
//                                     </>
//                                   ) : tabField.type === "oe_avatar" ? (
//                                     // code for field if type is oe_avatar(form image)
//                                     <>
//                                       <div
//                                         className={`${
//                                           tabField?.alignment === "center"
//                                             ? "align-self-center"
//                                             : tabField?.alignment ===
//                                               "left"
//                                             ? "align-self-start"
//                                             : "align-self-end"
//                                         } border d-flex justify-content-center align-items-center`}
//                                         style={{
//                                           width: "70px",
//                                           height: "70px",
//                                           fontSize: `${
//                                             tabField?.fontSize || "24"
//                                           }px`,
//                                           fontWeight: `${
//                                             tabField?.fontWeight || "500"
//                                           }`,
//                                         }}
//                                       >
//                                         {tabField?.defaultValue !== "" ? (
//                                           <img
//                                             src={
//                                               tabField?.defaultValue || ""
//                                             }
//                                             alt="form_img"
//                                             width="90%"
//                                           />
//                                         ) : (
//                                           <FaPlus />
//                                         )}
//                                       </div>
//                                     </>
//                                   ) : tabField.type === "binary" ? (
//                                     tabField.widget === "image" ? (
//                                       // code for field if type is image/binary
//                                       <>
//                                         <label htmlFor={tabField.type}>
//                                           {tabField.title}
//                                           {tabField?.isRequired && "*"}
//                                         </label>
//                                         <CiCamera
//                                           style={{
//                                             fontSize: "40px",
//                                             color: "#2e2e2e",
//                                           }}
//                                         />
//                                       </>
//                                     ) : (
//                                       // code for field if type is file/binary
//                                       <>
//                                         <label htmlFor={tabField.type}>
//                                           {tabField.title}
//                                           {tabField?.isRequired && "*"}
//                                         </label>
//                                         <input
//                                           type="file"
//                                           placeholder={
//                                             tabField.placeholder
//                                           }
//                                           required={tabField.isRequired}
//                                           readOnly={tabField.isReadOnly}
//                                         />
//                                       </>
//                                     )
//                                   ) : (
//                                     // code for field if type is anything besides tab and group
//                                     <>
//                                       <label htmlFor={tabField.type}>
//                                         {tabField.title}
//                                         {tabField?.isRequired && "*"}
//                                       </label>
//                                       {!["group", "tab"].includes(
//                                         tabField.type
//                                       ) && (
//                                         <input
//                                           type={tabField.type}
//                                           placeholder={
//                                             tabField.placeholder ||
//                                             `Enter ${tabField.title}`
//                                           }
//                                           value={tabField.defaultValue}
//                                           required={tabField.isRequired}
//                                           readOnly={tabField.isReadOnly}
//                                         />
//                                       )}
//                                     </>
//                                   )}

//                                   {/* code for tab field if type is group */}
//                                   {tabField.type === "group" && (
//                                     <div
//                                       className="fb_group"
//                                       title={tabField.tooltip}
//                                     >
//                                       {tabField.fields &&
//                                       tabField.fields.length > 0 ? (
//                                         tabField.fields.map(
//                                           (
//                                             tabGroupRow,
//                                             tabGroupRowIndex
//                                           ) => (
//                                             <div
//                                               key={tabGroupRowIndex}
//                                               className="fb_row"
//                                               onDragOver={(e) =>
//                                                 handleDragOver(
//                                                   e,
//                                                   rowIndex,
//                                                   columnIndex,
//                                                   null,
//                                                   null,
//                                                   field.activeTab,
//                                                   tabRowIndex,
//                                                   tabFieldIndex,
//                                                   tabGroupRowIndex
//                                                 )
//                                               }
//                                               onDrop={(e) =>
//                                                 handleDrop(
//                                                   e,
//                                                   rowIndex,
//                                                   columnIndex,
//                                                   null,
//                                                   null,
//                                                   field.activeTab,
//                                                   tabRowIndex,
//                                                   tabFieldIndex,
//                                                   tabGroupRowIndex
//                                                 )
//                                               }
//                                             >
//                                               {tabGroupRow.map(
//                                                 (
//                                                   tabGroupField,
//                                                   tabGroupFieldIndex
//                                                 ) => (
//                                                   <div
//                                                     key={
//                                                       tabGroupFieldIndex
//                                                     }
//                                                     className="fb_inputfield"
//                                                     title={
//                                                       tabGroupField.tooltip
//                                                     }
//                                                     onDragOver={(e) =>
//                                                       handleDragOver(
//                                                         e,
//                                                         rowIndex,
//                                                         columnIndex,
//                                                         null,
//                                                         null,
//                                                         field.activeTab,
//                                                         tabRowIndex,
//                                                         tabFieldIndex,
//                                                         tabGroupRowIndex,
//                                                         tabGroupFieldIndex
//                                                       )
//                                                     }
//                                                     onDrop={(e) =>
//                                                       handleDrop(
//                                                         e,
//                                                         rowIndex,
//                                                         columnIndex,
//                                                         null,
//                                                         null,
//                                                         field.activeTab,
//                                                         tabRowIndex,
//                                                         tabFieldIndex,
//                                                         tabGroupRowIndex,
//                                                         tabGroupFieldIndex
//                                                       )
//                                                     }
//                                                     onClick={(e) =>
//                                                       handleTabGroupFieldClick(
//                                                         e,
//                                                         tabGroupField,
//                                                         rowIndex,
//                                                         columnIndex,
//                                                         field.activeTab,
//                                                         tabRowIndex,
//                                                         tabFieldIndex,
//                                                         tabGroupRowIndex,
//                                                         tabGroupFieldIndex
//                                                       )
//                                                     }
//                                                     draggable
//                                                     onDragStart={(e) =>
//                                                       handleDragStart(
//                                                         e,
//                                                         tabGroupField,
//                                                         {
//                                                           rowIndex,
//                                                           columnIndex,
//                                                           tabIndex:
//                                                             field.activeTab,
//                                                           tabRowIndex,
//                                                           tabFieldIndex,
//                                                           tabGroupRowIndex,
//                                                           tabGroupFieldIndex,
//                                                         }
//                                                       )
//                                                     }
//                                                   >
//                                                     {[
//                                                       "button",
//                                                       "object",
//                                                       "action",
//                                                     ].includes(
//                                                       tabGroupField.type
//                                                     ) ? (
//                                                       <button
//                                                         type="submit"
//                                                         className={`btn ${
//                                                           tabGroupField?.alignment ===
//                                                           "right"
//                                                             ? "align-self-end"
//                                                             : tabGroupField?.alignment ===
//                                                               "left"
//                                                             ? "align-self-start"
//                                                             : "align-self-center"
//                                                         } ${
//                                                           tabGroupField.odooButtonColor
//                                                             ? tabGroupField.odooButtonColor
//                                                             : "btn-outline-dark"
//                                                         } `}
//                                                       >
//                                                         {
//                                                           tabGroupField.title
//                                                         }
//                                                       </button>
//                                                     ) : tabGroupField.type ===
//                                                       "selection" ? (
//                                                       <>
//                                                         <label>
//                                                           {
//                                                             tabGroupField.title
//                                                           }
//                                                           {tabGroupField?.isRequired &&
//                                                             "*"}
//                                                         </label>
//                                                         <select
//                                                           required={
//                                                             tabGroupField.isRequired
//                                                           }
//                                                         >
//                                                           {tabGroupField.options.map(
//                                                             (
//                                                               option,
//                                                               index
//                                                             ) => (
//                                                               <option
//                                                                 key={
//                                                                   index
//                                                                 }
//                                                                 value={
//                                                                   option
//                                                                 }
//                                                               >
//                                                                 {option}
//                                                               </option>
//                                                             )
//                                                           )}
//                                                         </select>
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "label" ? (
//                                                       // code for field if type is label
//                                                       <>
//                                                         {/* <label htmlFor={tabField.type}>{tabField.title}</label> */}
//                                                         <span
//                                                           className={`${
//                                                             tabField?.alignment ===
//                                                             "center"
//                                                               ? "align-self-center"
//                                                               : tabField?.alignment ===
//                                                                 "right"
//                                                               ? "align-self-end"
//                                                               : "align-self-start"
//                                                           }`}
//                                                           style={{
//                                                             fontSize: `${
//                                                               tabGroupField?.fontSize ||
//                                                               "24"
//                                                             }px`,
//                                                             fontWeight: `${
//                                                               tabGroupField?.fontWeight ||
//                                                               "500"
//                                                             }`,
//                                                           }}
//                                                         >
//                                                           {
//                                                             tabGroupField.title
//                                                           }
//                                                         </span>
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "separator" ? (
//                                                       // code for field if type is separator
//                                                       <>
//                                                         {/* <label htmlFor={tabField.type}>{tabField.title}</label> */}
//                                                         <div
//                                                           style={{
//                                                             width: "100%",
//                                                           }}
//                                                         >
//                                                           <hr
//                                                             style={{
//                                                               margin:
//                                                                 "5px 0",
//                                                               border:
//                                                                 "none",
//                                                               borderTop:
//                                                                 "2px solid #333",
//                                                             }}
//                                                           />
//                                                           <hr
//                                                             style={{
//                                                               margin:
//                                                                 "5px 0",
//                                                               border:
//                                                                 "none",
//                                                               borderTop:
//                                                                 "2px solid #333",
//                                                             }}
//                                                           />
//                                                         </div>
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "static_image" ? (
//                                                       // code for field if type is label
//                                                       <>
//                                                         {tabGroupField?.defaultValue !==
//                                                         "" ? (
//                                                           <img
//                                                             src={
//                                                               tabGroupField?.defaultValue ||
//                                                               ""
//                                                             }
//                                                             className={`${
//                                                               tabGroupField?.alignment ===
//                                                               "center"
//                                                                 ? "align-self-center"
//                                                                 : tabGroupField?.alignment ===
//                                                                   "left"
//                                                                 ? "align-self-start"
//                                                                 : "align-self-end"
//                                                             }`}
//                                                             width="100px"
//                                                             height="100px"
//                                                             style={{
//                                                               border:
//                                                                 "1px solid #383838",
//                                                             }}
//                                                             alt="static img"
//                                                           />
//                                                         ) : (
//                                                           <div
//                                                             style={{
//                                                               width:
//                                                                 "100px",
//                                                               height:
//                                                                 "100px",
//                                                               border:
//                                                                 "1px solid #383838",
//                                                               fontSize:
//                                                                 "60px",
//                                                             }}
//                                                             className={`${
//                                                               tabGroupField?.alignment ===
//                                                               "center"
//                                                                 ? "align-self-center"
//                                                                 : tabGroupField?.alignment ===
//                                                                   "left"
//                                                                 ? "align-self-start"
//                                                                 : "align-self-end"
//                                                             } d-flex justify-content-center align-items-center`}
//                                                           >
//                                                             <FaRegImage />
//                                                           </div>
//                                                         )}
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "table" ? (
//                                                       // code for field if type is table
//                                                       <>
//                                                         <label
//                                                           htmlFor={
//                                                             tabGroupField.type
//                                                           }
//                                                         >
//                                                           {
//                                                             tabGroupField.title
//                                                           }
//                                                         </label>
//                                                         <table
//                                                           style={{
//                                                             width: "90%",
//                                                             alignSelf:
//                                                               "center",
//                                                           }}
//                                                           className="table table-secondary table-bordered"
//                                                         >
//                                                           <thead className="">
//                                                             <tr className="d-table-row table-light">
//                                                               {tabGroupField &&
//                                                                 tabGroupField.columns.map(
//                                                                   (
//                                                                     col,
//                                                                     i
//                                                                   ) => (
//                                                                     <th
//                                                                       key={
//                                                                         i
//                                                                       }
//                                                                       scope="col"
//                                                                       className="d-table-cell"
//                                                                     >
//                                                                       {typeof col ===
//                                                                       "string"
//                                                                         ? col
//                                                                         : col.title}
//                                                                     </th>
//                                                                   )
//                                                                 )}
//                                                             </tr>
//                                                           </thead>
//                                                           <tbody className="">
//                                                             <tr className="d-table-row">
//                                                               {tabGroupField.columns.map(
//                                                                 (
//                                                                   _,
//                                                                   i
//                                                                 ) => (
//                                                                   <td
//                                                                     className="d-table-cell"
//                                                                     key={
//                                                                       i
//                                                                     }
//                                                                   ></td>
//                                                                 )
//                                                               )}
//                                                             </tr>
//                                                           </tbody>
//                                                         </table>
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "boolean" ? (
//                                                       // code for field if type is boolean
//                                                       <>
//                                                         <div className="d-flex justify-content-start align-items-center gap-4">
//                                                           <label
//                                                             htmlFor={
//                                                               tabGroupField.type
//                                                             }
//                                                           >
//                                                             {
//                                                               tabGroupField.title
//                                                             }
//                                                             {tabGroupField?.isRequired &&
//                                                               "*"}
//                                                           </label>
//                                                           <input
//                                                             type={
//                                                               tabGroupField.type ===
//                                                               "boolean"
//                                                                 ? "checkbox"
//                                                                 : "text"
//                                                             }
//                                                             placeholder={
//                                                               tabGroupField.placeholder ||
//                                                               `Enter ${tabGroupField.title}`
//                                                             }
//                                                             value={
//                                                               tabGroupField.defaultValue
//                                                             }
//                                                             required={
//                                                               tabGroupField.isRequired
//                                                             }
//                                                             readOnly={
//                                                               tabGroupField.isReadOnly
//                                                             }
//                                                           />
//                                                         </div>
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "html" ? (
//                                                       // code for field if type is chatter
//                                                       <>
//                                                         <label
//                                                           htmlFor={
//                                                             tabGroupField.type
//                                                           }
//                                                         >
//                                                           {
//                                                             tabGroupField.title
//                                                           }
//                                                         </label>
//                                                         <div
//                                                           style={{
//                                                             width: "100%",
//                                                           }}
//                                                         >
//                                                           <hr
//                                                             style={{
//                                                               margin:
//                                                                 "5px 0",
//                                                               border:
//                                                                 "none",
//                                                               borderTop:
//                                                                 "2px solid #333",
//                                                             }}
//                                                           />
//                                                         </div>
//                                                         <IoGlobeOutline
//                                                           style={{
//                                                             fontSize:
//                                                               "40px",
//                                                           }}
//                                                         />
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "oe_avatar" ? (
//                                                       // code for field if type is oe_avatar(form image)
//                                                       <>
//                                                         <div
//                                                           className={`${
//                                                             tabGroupField?.alignment ===
//                                                             "center"
//                                                               ? "align-self-center"
//                                                               : tabGroupField?.alignment ===
//                                                                 "left"
//                                                               ? "align-self-start"
//                                                               : "align-self-end"
//                                                           } border d-flex justify-content-center align-items-center`}
//                                                           style={{
//                                                             width: "70px",
//                                                             height:
//                                                               "70px",
//                                                             fontSize: `${
//                                                               tabGroupField?.fontSize ||
//                                                               "24"
//                                                             }px`,
//                                                             fontWeight: `${
//                                                               tabGroupField?.fontWeight ||
//                                                               "500"
//                                                             }`,
//                                                           }}
//                                                         >
//                                                           {tabGroupField?.defaultValue !==
//                                                           "" ? (
//                                                             <img
//                                                               src={
//                                                                 tabGroupField?.defaultValue ||
//                                                                 ""
//                                                               }
//                                                               alt="form_img"
//                                                               width="90%"
//                                                             />
//                                                           ) : (
//                                                             <FaPlus />
//                                                           )}
//                                                         </div>
//                                                       </>
//                                                     ) : tabGroupField.type ===
//                                                       "binary" ? (
//                                                       tabGroupField.widget ===
//                                                       "image" ? (
//                                                         // code for field if type is image/binary
//                                                         <>
//                                                           <label
//                                                             htmlFor={
//                                                               tabGroupField.type
//                                                             }
//                                                           >
//                                                             {
//                                                               tabGroupField.title
//                                                             }
//                                                             {tabGroupField?.isRequired &&
//                                                               "*"}
//                                                           </label>
//                                                           <CiCamera
//                                                             style={{
//                                                               fontSize:
//                                                                 "40px",
//                                                               color:
//                                                                 "#2e2e2e",
//                                                             }}
//                                                           />
//                                                         </>
//                                                       ) : (
//                                                         // code for field if type is image/binary
//                                                         <>
//                                                           <label
//                                                             htmlFor={
//                                                               tabGroupField.type
//                                                             }
//                                                           >
//                                                             {
//                                                               tabGroupField.title
//                                                             }
//                                                             {tabGroupField?.isRequired &&
//                                                               "*"}
//                                                           </label>
//                                                           <input
//                                                             type="file"
//                                                             placeholder={
//                                                               tabGroupField.placeholder
//                                                             }
//                                                             required={
//                                                               tabGroupField.isRequired
//                                                             }
//                                                             readOnly={
//                                                               tabGroupField.isReadOnly
//                                                             }
//                                                           />
//                                                         </>
//                                                       )
//                                                     ) : (
//                                                       <>
//                                                         <label>
//                                                           {
//                                                             tabGroupField.title
//                                                           }
//                                                           {tabGroupField?.isRequired &&
//                                                             "*"}
//                                                         </label>
//                                                         <input
//                                                           type={
//                                                             tabGroupField.type
//                                                           }
//                                                           placeholder={
//                                                             tabGroupField.placeholder ||
//                                                             `Enter ${tabGroupField.title}`
//                                                           }
//                                                           value={
//                                                             tabGroupField.defaultValue
//                                                           }
//                                                           required={
//                                                             tabGroupField.isRequired
//                                                           }
//                                                           readOnly={
//                                                             tabGroupField.isReadOnly
//                                                           }
//                                                         />
//                                                       </>
//                                                     )}
//                                                   </div>
//                                                 )
//                                               )}
//                                             </div>
//                                           )
//                                         )
//                                       ) : (
//                                         <span
//                                           style={{ color: "#bdbaba" }}
//                                         >
//                                           Drop Here
//                                         </span>
//                                       )}
//                                     </div>
//                                   )}
//                                 </div>
//                               ))
//                             ) : (
//                               <span style={{ color: "#bdbaba" }}>
//                                 Drop Here
//                               </span>
//                             )}
//                           </div>
//                         )
//                       )}
//                   </div>
//                 </>
//               )}

//               {/* code for field if type is group */}
//               {field.type === "group" && (
//                 <div className="fb_group" title={field.tooltip}>
//                   {field.fields && field.fields.length > 0 ? (
//                     field.fields.map((groupRow, groupRowIndex) => (
//                       <div
//                         key={groupRowIndex}
//                         className="fb_row"
//                         onDragOver={(e) =>
//                           handleDragOver(
//                             e,
//                             rowIndex,
//                             columnIndex,
//                             groupRowIndex
//                           )
//                         }
//                         onDrop={(e) =>
//                           handleDrop(
//                             e,
//                             rowIndex,
//                             columnIndex,
//                             groupRowIndex
//                           )
//                         }
//                       >
//                         {groupRow.map((groupField, groupFieldIndex) => (
//                           <div
//                             key={groupFieldIndex}
//                             className="fb_inputfield"
//                             title={groupField.tooltip}
//                             onDragOver={(e) =>
//                               handleDragOver(
//                                 e,
//                                 rowIndex,
//                                 columnIndex,
//                                 groupRowIndex,
//                                 groupFieldIndex
//                               )
//                             }
//                             onDrop={(e) =>
//                               handleDrop(
//                                 e,
//                                 rowIndex,
//                                 columnIndex,
//                                 groupRowIndex,
//                                 groupFieldIndex
//                               )
//                             }
//                             onClick={(e) =>
//                               handleGroupFieldClick(
//                                 e,
//                                 groupField,
//                                 rowIndex,
//                                 columnIndex,
//                                 groupRowIndex,
//                                 groupFieldIndex
//                               )
//                             }
//                             draggable
//                             onDragStart={(e) =>
//                               handleDragStart(e, groupField, {
//                                 rowIndex,
//                                 columnIndex,
//                                 groupRowIndex,
//                                 groupFieldIndex,
//                               })
//                             }
//                           >
//                             {["button", "object", "action"].includes(
//                               groupField.type
//                             ) ? (
//                               <button
//                                 type="submit"
//                                 className={`btn ${
//                                   groupField?.alignment === "right"
//                                     ? "align-self-end"
//                                     : groupField?.alignment === "left"
//                                     ? "align-self-start"
//                                     : "align-self-center"
//                                 } ${
//                                   groupField.odooButtonColor
//                                     ? groupField.odooButtonColor
//                                     : "btn-outline-dark"
//                                 } `}
//                               >
//                                 {groupField.title}
//                               </button>
//                             ) : groupField.type === "selection" ? (
//                               <>
//                                 <label>
//                                   {groupField.title}
//                                   {groupField?.isRequired && "*"}
//                                 </label>
//                                 <select required={groupField.isRequired}>
//                                   {groupField.options.map(
//                                     (option, index) => (
//                                       <option key={index} value={option}>
//                                         {option}
//                                       </option>
//                                     )
//                                   )}
//                                 </select>
//                               </>
//                             ) : groupField.type === "label" ? (
//                               // code for field if type is label
//                               <>
//                                 {/* <label htmlFor={field.type}>{field.title}</label> */}
//                                 <span
//                                   className={`${
//                                     field?.alignment === "center"
//                                       ? "align-self-center"
//                                       : field?.alignment === "right"
//                                       ? "align-self-end"
//                                       : "align-self-start"
//                                   }`}
//                                   style={{
//                                     fontSize: `${
//                                       groupField?.fontSize || "24"
//                                     }px`,
//                                     fontWeight: `${
//                                       groupField?.fontWeight || "500"
//                                     }`,
//                                   }}
//                                 >
//                                   {groupField.title}
//                                 </span>
//                                 {/* <div style={{ width: "100%" }}>
//                                   <hr
//                                     style={{
//                                       margin: "5px 0",
//                                       border: "none",
//                                       borderTop: "2px solid #ccc",
//                                     }}
//                                   />
//                                 </div> */}
//                               </>
//                             ) : groupField.type === "separator" ? (
//                               // code for field if type is separator
//                               <>
//                                 {/* <label htmlFor={field.type}>{field.title}</label> */}
//                                 <div style={{ width: "100%" }}>
//                                   <hr
//                                     style={{
//                                       margin: "5px 0",
//                                       border: "none",
//                                       borderTop: "2px solid #333",
//                                     }}
//                                   />
//                                   <hr
//                                     style={{
//                                       margin: "5px 0",
//                                       border: "none",
//                                       borderTop: "2px solid #333",
//                                     }}
//                                   />
//                                 </div>
//                               </>
//                             ) : groupField.type === "table" ? (
//                               // code for field if type is table
//                               <>
//                                 <label htmlFor={groupField.type}>
//                                   {groupField.title}
//                                 </label>
//                                 <table
//                                   style={{
//                                     width: "90%",
//                                     alignSelf: "center",
//                                   }}
//                                   className="table table-secondary table-bordered"
//                                 >
//                                   <thead className="">
//                                     <tr className="d-table-row table-light">
//                                       {groupField &&
//                                         groupField.columns.map(
//                                           (col, i) => (
//                                             <th
//                                               key={i}
//                                               scope="col"
//                                               className="d-table-cell"
//                                             >
//                                               {typeof col === "string"
//                                                 ? col
//                                                 : col.title}
//                                             </th>
//                                           )
//                                         )}
//                                     </tr>
//                                   </thead>
//                                   <tbody className="">
//                                     <tr className="d-table-row">
//                                       {groupField.columns.map((_, i) => (
//                                         <td
//                                           className="d-table-cell"
//                                           key={i}
//                                         ></td>
//                                       ))}
//                                     </tr>
//                                   </tbody>
//                                 </table>
//                               </>
//                             ) : groupField.type === "boolean" ? (
//                               // code for field if type is boolean
//                               <>
//                                 <div className="d-flex justify-content-start align-items-center gap-4">
//                                   <label htmlFor={groupField.type}>
//                                     {groupField.title}
//                                     {groupField?.isRequired && "*"}
//                                   </label>
//                                   <input
//                                     type={
//                                       groupField.type === "boolean"
//                                         ? "checkbox"
//                                         : "text"
//                                     }
//                                     placeholder={
//                                       groupField.placeholder ||
//                                       `Enter ${groupField.title}`
//                                     }
//                                     value={groupField.defaultValue}
//                                     required={groupField.isRequired}
//                                     readOnly={groupField.isReadOnly}
//                                   />
//                                 </div>
//                               </>
//                             ) : groupField.type === "html" ? (
//                               // code for field if type is chatter
//                               <>
//                                 <label htmlFor={groupField.type}>
//                                   {groupField.title}
//                                 </label>
//                                 <div style={{ width: "100%" }}>
//                                   <hr
//                                     style={{
//                                       margin: "5px 0",
//                                       border: "none",
//                                       borderTop: "2px solid #333",
//                                     }}
//                                   />
//                                 </div>
//                                 <IoGlobeOutline
//                                   style={{ fontSize: "40px" }}
//                                 />
//                               </>
//                             ) : groupField.type === "static_image" ? (
//                               // code for field if type is label
//                               <>
//                                 {groupField?.defaultValue !== "" ? (
//                                   <img
//                                     src={groupField?.defaultValue || ""}
//                                     className={`${
//                                       groupField?.alignment === "center"
//                                         ? "align-self-center"
//                                         : groupField?.alignment === "left"
//                                         ? "align-self-start"
//                                         : "align-self-end"
//                                     }`}
//                                     width="100px"
//                                     height="100px"
//                                     style={{
//                                       border: "1px solid #383838",
//                                     }}
//                                     alt="static img"
//                                   />
//                                 ) : (
//                                   <div
//                                     style={{
//                                       width: "100px",
//                                       height: "100px",
//                                       border: "1px solid #383838",
//                                       fontSize: "60px",
//                                     }}
//                                     className={`${
//                                       groupField?.alignment === "center"
//                                         ? "align-self-center"
//                                         : groupField?.alignment === "left"
//                                         ? "align-self-start"
//                                         : "align-self-end"
//                                     } d-flex justify-content-center align-items-center`}
//                                   >
//                                     <FaRegImage />
//                                   </div>
//                                 )}
//                               </>
//                             ) : groupField.type === "oe_avatar" ? (
//                               // code for field if type is oe_avatar(form image)
//                               <>
//                                 <div
//                                   className={`${
//                                     groupField?.alignment === "center"
//                                       ? "align-self-center"
//                                       : groupField?.alignment === "left"
//                                       ? "align-self-start"
//                                       : "align-self-end"
//                                   } border d-flex justify-content-center align-items-center`}
//                                   style={{
//                                     width: "70px",
//                                     height: "70px",
//                                     fontSize: `${
//                                       groupField?.fontSize || "24"
//                                     }px`,
//                                     fontWeight: `${
//                                       groupField?.fontWeight || "500"
//                                     }`,
//                                   }}
//                                 >
//                                   {groupField?.defaultValue !== "" ? (
//                                     <img
//                                       src={groupField?.defaultValue || ""}
//                                       alt="form_img"
//                                       width="90%"
//                                     />
//                                   ) : (
//                                     <FaPlus />
//                                   )}
//                                 </div>
//                               </>
//                             ) : groupField.type === "binary" ? (
//                               groupField.widget === "image" ? (
//                                 // code for field if type is image/binary
//                                 <>
//                                   <label htmlFor={groupField.type}>
//                                     {groupField.title}
//                                     {groupField?.isRequired && "*"}
//                                   </label>
//                                   <CiCamera
//                                     style={{
//                                       fontSize: "40px",
//                                       color: "#2e2e2e",
//                                     }}
//                                   />
//                                 </>
//                               ) : (
//                                 // code for field if type is image/binary
//                                 <>
//                                   <label htmlFor={groupField.type}>
//                                     {groupField.title}
//                                     {groupField?.isRequired && "*"}
//                                   </label>
//                                   <input
//                                     type="file"
//                                     placeholder={groupField.placeholder}
//                                     required={groupField.isRequired}
//                                     readOnly={groupField.isReadOnly}
//                                   />
//                                 </>
//                               )
//                             ) : (
//                               <>
//                                 <label>
//                                   {groupField.title}
//                                   {groupField?.isRequired && "*"}
//                                 </label>
//                                 <input
//                                   type={groupField.type}
//                                   placeholder={
//                                     groupField.placeholder ||
//                                     `Enter ${groupField.title}`
//                                   }
//                                   value={groupField.defaultValue}
//                                   required={groupField.isRequired}
//                                   readOnly={groupField.isReadOnly}
//                                 />
//                               </>
//                             )}
//                           </div>
//                         ))}
//                       </div>
//                     ))
//                   ) : (
//                     <span style={{ color: "#bdbaba" }}>Drop Here</span>
//                   )}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       ))}

//     {/* code for placeholder to drop field */}
//     <div
//       className="fb_row placeholder"
//       onDragOver={(e) => handleDragOver(e, formData.length)}
//       onDrop={(e) => handleDrop(e, formData.length)}
//     />
//   </div>
// )
// ================================================================
